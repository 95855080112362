import React from 'react';
import PropTypes from 'prop-types';

const JitsuField = ({ error = '', children, className, id }) => <div className={`mb-2 ${className} w-full`} id={id}>
  {children}
  {error && <p className="text-red-500 text-xs italic">{error}</p>}
</div>;

JitsuField.defaultProps = {
  children: <div>Element Needed</div>,
  error: '',
  className: '',
  id: ''
};

JitsuField.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default JitsuField;