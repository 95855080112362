import React from 'react';
import PropTypes from 'prop-types';

import { StyledLoadingDiv } from './styled';

const Loading = ({ small, white, margin }) => <StyledLoadingDiv
  className={`${small ? 'small' : ''} ${white ? 'white' : ''}`}
  style={{ margin }}
/>

Loading.defaultProps = {
  small: false,
  white: false,
  margin: '0'
}

Loading.propTypes = {
  small: PropTypes.bool.isRequired,
  white: PropTypes.bool.isRequired,
  margin: PropTypes.string.isRequired
};

export default Loading;