import { graphql } from 'react-apollo';
import { collegeQueries } from '../../queries';
import { requestNameMap } from '../../constants/requests';

export default {
  queries: id => [
    graphql(collegeQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(collegeQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  requestName: requestNameMap.DELETE_COLLEGE,
  dataKey: 'getCollege',
  success: (response, history, id) => { history.push(`/colleges/${id}`); }
};
