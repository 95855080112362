import React from 'react';
import { graphql } from 'react-apollo';
import { teacherQueries, studentQueries, parentQueries } from '../queries';
import { getCurrentUser } from '../functions';
import { TEACHER, STUDENT, ADMIN, PARENT, OVERSEER, SUBJECT_ADMIN, SEER } from '../constants/roles';

const getQuery = (userRole, queryName) => {
  switch (userRole) {
  case ADMIN:
    return teacherQueries[queryName];
  case TEACHER:
    return teacherQueries[queryName];
  case SUBJECT_ADMIN:
    return teacherQueries[queryName];
  case OVERSEER:
    return teacherQueries[queryName];
  case SEER:
    return teacherQueries[queryName];
  case STUDENT:
    return studentQueries[queryName];
  case PARENT:
    return parentQueries[queryName];
  default:
    return '';
  }
};

export default (Component, queryName, options) => {
  const user = getCurrentUser();
  const query = getQuery(user.userRole, queryName);
  const ComponentWithQuery = props => <Component {...props} user={user}/>;
  const EmptyComponent = () => <div></div>;
  return query ? graphql(query, options)(ComponentWithQuery) : EmptyComponent;
};
