import React, { lazy } from 'react';
import { parentQueries } from '../../queries';
import { TEXT, LIST } from '../../constants/displayTypes';
import { tpl } from '../../constants/permissions';
import { changeTimestampToDate } from '../../functions';
import { nationsMap } from '../../constants/nations';

const RequestToRemove = lazy(() => import('../../components-graphql/RequestToRemove'));
const UserStatusManagement = lazy(() => import('../../components-graphql/UserStatusManagement'));
const StudentPerformance = lazy(() => import('../../components-graphql/Performances/StudentPerformance'));
const JitsuParentProfile = lazy(() => import('../../components-elements/JitsuParentProfile'));

export default {
  InfoComponent: JitsuParentProfile,
  dataKey: 'getParent',
  query: parentQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'First name', text: data => data.firstName, id: 'firstName' },
    { type: TEXT, label: 'Last Name', text: data => data.lastName, id: 'lastName' },
    { type: TEXT, label: 'Reg Number', text: data => data.regNumber, id: 'regNumber' },
    { type: TEXT, label: 'Date of birth', text: data => changeTimestampToDate(data.dob, 'YYYY-MM-DD'), id: 'dob' },
    { type: TEXT, label: 'Gender', text: data => data.gender, id: 'gender' },
    { type: TEXT, label: 'Email', text: data => data.email, id: 'email' },
    { type: TEXT, label: 'Phone Number', text: data => data.phoneNumber, id: 'phoneNumber' },
    { type: TEXT, label: 'Nationality', text: data => nationsMap[data.nationality].name, id: 'nationality' },
    { type: TEXT, label: 'Address', text: data => data.address, id: 'address' },
    {
      type: LIST,
      label: 'Kids',
      list: data => data.students.map(student => ({ ...student, name: `${student.firstName} ${student.lastName}` })),
      id: 'kids'
    },
  ],
  tabHeaders: ({ data: { getParent: parent } }) => [
    ...(parent.students.map(student => ({
      text: `${student.firstName} ${student.lastName}`,
      value: `performance-${student.id}`,
      props: { userId: student.id },
      Component: StudentPerformance,
      ignorePermission: true
    }))),
    {
      text: 'Remove Students',
      value: tpl.REMOVE_KIDS_FROM_PARENTS,
      props: {
        parent,
        tablename: 'parents',
        relatedRowId: parent.id,
        action: 'REMOVE_KIDS_FROM_PARENT',
        options: parent.students,
        valueKey: 'id',
        textKey: 'firstName',
        refetchItem: {
          query: parentQueries.item,
          variables: {
            id: parent.id
          }
        }
      },
      Component: props => <RequestToRemove {...props}/>
    },
    {
      text: 'Update Status',
      value: tpl.PARENT_UPDATE_STATUS,
      props: {},
      Component: () => <UserStatusManagement userId={parent.id} role={parent.role} currentStatus={parent.status}/>
    },
  ]
};
