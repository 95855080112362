import dateFns from 'date-fns';
import { TEXT_INPUT, SEARCH_MULTISELECT, SELECT_INPUT, DATE_INPUT } from '../../constants/fieldTypes';
import { DATE } from '../../constants/dateInputTypes';
import nations from '../../constants/nations';
import states from '../../constants/states';
import { STUDENT } from '../../constants/roles';
import validateStudent from './validation';
import { textChange } from '../../functions/textChange';
import { TERTIARY, PRIMARY } from '../../constants/schoolTypes';
import { getCurrentUser } from '../../functions';

const firstName = () => ({
  name: 'firstName', type: TEXT_INPUT, label: 'First name', placeholder: 'Gbyon', required: true
});

const lastName = () => ({
  name: 'lastName', type: TEXT_INPUT, label: 'Last name', placeholder: 'Doe', required: true
});

const regNumber = () => ({
  name: 'regNumber', type: TEXT_INPUT, label: 'Reg number', placeholder: 'GB123456', required: true, capitalized: true
});

const email = () => ({
  name: 'email', type: TEXT_INPUT, label: 'Email', placeholder: 'email@mail.com', required: true
});

const programme = () => ({
  name: 'programme', type: TEXT_INPUT, label: 'Programme', placeholder: 'M.Sc. Computer science', required: true
});

const level = (props) => props.user.schoolType === TERTIARY ? {
  name: 'level',
  type: SELECT_INPUT,
  required: true,
  label: 'Current academic year',
  options: [...Array(props.user.settings.maxYears || 5)].map((_, index) => ({
    text: `Year ${index + 1}`,
    value: index + 1
  })),
} : null;

const parents = () => ({
  name: 'parents',
  type: SEARCH_MULTISELECT,
  label: 'Parents',
  optional: true,
  placeholder: 'parents',
  displayTextFcn: selection => selection.text || selection.name,
  valueKey: 'actualId',
  textKey: 'text',
  through: 'parent'
});

const subjects = props => ({
  name: 'subjects',
  type: SEARCH_MULTISELECT,
  textKey: 'text',
  label: textChange('Subjects'),
  maxHeight: '100px',
  optional: true,
  placeholder: 'subjects',
  displayTextFcn: selection => selection.text || selection.code,
  valueKey: 'actualId',
  through: 'subject',
  lockedOptions: props.data
    ? props.data.getStudent.subjects.map(subject => ({ ...subject, actualId: subject.id, text: subject.code }))
    : []
});

const phoneNumber = () => ({
  name: 'phoneNumber', type: TEXT_INPUT, label: 'Phone number', placeholder: '07832393288', required: true
});

const countryOfResidence = () => ({
  name: 'countryOfResidence',
  type: SELECT_INPUT,
  label: 'Country of residence',
  valueKey: 'code',
  required: true,
  options: nations.map(nation => ({ text: nation.name, value: nation.code }))
});

const stateOfResidence = (_, { countryOfResidence }) => {
  return {
    name: 'stateOfResidence',
    type: SELECT_INPUT,
    label: 'State of residence',
    valueKey: 'code',
    required: true,
    options: (states[countryOfResidence] || ['Outside Nigeria']).map(s => ({ text: s, value: s }))
  }
};

const address = () => ({
  name: 'address', type: TEXT_INPUT, label: 'Street address', required: true, placeholder: '18 Adekaa Yaji Street'
});

const previousSchool = () => ({
  name: 'previousSchool', type: TEXT_INPUT, label: 'Previous school', required: true, placeholder: 'Glory Nursery n Primary School'
});

const dob = () => ({
  name: 'dob',
  type: DATE_INPUT,
  label: 'Date of birth',
  required: true,
  placeholder: '1992-05-11',
  dateInputType: DATE
});

const gender = () => ({
  name: 'gender',
  type: SELECT_INPUT,
  label: 'Gender',
  valueKey: 'value',
  options: [{ value: 'Male', text: 'Male' }, { value: 'Female', text: 'Female' }]
});

const clazz = props => ({
  name: 'class',
  type: SELECT_INPUT,
  required: true,
  label: textChange('Class'),
  valueKey: 'id',
  options: props.clazzes.getClasses.map(({ id, name }) => ({ value: id, text: name }))
});

const nationality = () => ({
  name: 'nationality',
  type: SELECT_INPUT,
  label: 'Nationality',
  required: true,
  valueKey: 'code',
  options: nations.map(nation => ({ text: nation.name, value: nation.code }))
})

export const fields = getCurrentUser().schoolType === PRIMARY
? [
  firstName,
  lastName,
  regNumber,
  dob,
  parents,
  clazz,
  email,
  subjects,
  phoneNumber,
  countryOfResidence,
  stateOfResidence,
  address,
  previousSchool,
  gender,
  nationality,
]
: [
  firstName,
  lastName,
  regNumber,
  email,
  programme,
  level,
  parents,
  subjects,
  phoneNumber,
  countryOfResidence,
  stateOfResidence,
  address,
  previousSchool,
  dob,
  gender,
  clazz,
  nationality,
];

export const getRequestData = (props, data, dataAddition) => {
  return {
    requestData: {
      ...data,
      role: STUDENT,
      photoUrl: 'url',
      dob: dateFns.format(new Date(data.dob), 'YYYY-MM-DD'),
      subjectsIds: data.subjects.map(({ actualId }) => parseInt(actualId, 10)),
      classId: parseInt(data.class, 10),
      parentsIds: data.parents.map(({ actualId }) => parseInt(actualId, 10)),
      ...dataAddition
    },
  errors: validateStudent(data, props.user)
  };
};

export const watcher = (value, name, { props }) => {
  if (props.user.schoolType !== PRIMARY) return {};
  if (name === 'parents' && value.length > 0) {
    const [firstParent] = value;
    const { address, phoneNumber, email, nationality } = JSON.parse(firstParent.extraData);
    return { address, email, phoneNumber, nationality };
  }
  if (name === 'class') {
    const selectedClass = (props.clazzes.getClasses || []).find(clazz => clazz.id === parseInt(value, 10));
    if (selectedClass) {
      return {
        subjects: selectedClass.subjects.map((sub) => ({
          id: `subject-${sub.id}`,
          actualId: sub.id,
          text: `${sub.name} ${sub.code} (${sub.creditUnit})`,
        }))
      };
    }
    return {};
  }
  return {};
};

export const fetches = ['clazzes'];
