import { graphql } from 'react-apollo';
import { classQueries, newsQueries, subjectQueries } from '../../queries';
import { fields, fetches, getRequestData } from './helper';

export default {
  queries: (id) => [
    graphql(classQueries.list, { name: 'clazzes' }),
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(newsQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(newsQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData: (props, { fileUrl, ...rest }) => getRequestData(
    props,
    {
      ...rest,
      fileUrl: fileUrl.size === -1
        ? new File([''], 'none.txt')
        : fileUrl
    },
    {}
  ),
  initialValues: ({ data: { getNews = {} } }) => ({
    ...getNews,
    subjects: getNews.subjects.map(subject => ({ ...subject, actualId: subject.id, text: subject.code }))
  }),
  success: (response, history) => { history.push(`/news/${response.data.updateNews.id}`); }
}
