import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Modal extends PureComponent {
  constructor() {
    super();
    this.state = {
      onScreen: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggleModal() {
    if (!this.props.insideControl) {
      this.props.closeModal();
      return;
    }
    this.setState(state => ({ onScreen: !state.onScreen }));
  }
  render() {
    const { children, title } = this.props;
    return (<div
      className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full z-20"
      style={{ background: "#1b1717d1" }}
    >
      <div className="bg-white rounded-lg w-1/2">
        <div className="flex flex-col items-start p-4">
          <div className="flex items-center w-full">
            <div className="text-gray-900 font-medium text-lg">{title}</div>
            <span onClick={this.toggleModal} className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer">
              <svg
                className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/>
              </svg>
            </span>
          </div>
          <div className="w-full">
            {children}
          </div>
          {/* <div className={`${size}ModalBody`}>{children}</div>
          <div className={`${size}ModalClose`} onClick={this.toggleModal}>X</div> */}
        </div>
      </div>
    </div>);
  }
}

Modal.defaultProps = {
  size: 'large',
  title: ''
};

Modal.propTypes = {
  children: PropTypes.object.isRequired,
  insideControl: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Modal;