import { graphql } from 'react-apollo';
import { schoolQueries } from '../../queries';
import { updateSchoolListAfterDelete } from './helper';

export default {
  queries: id => [
    graphql(schoolQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(schoolQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { deleteSchool } }) => {
            updateSchoolListAfterDelete(proxy, deleteSchool);
          }
        })
      })
    })
  ],
  dataKey: 'getSchool',
  success: (_, history) => { history.push('/schools'); }
};