import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dataFetchingComponent from '../../hocs/dataFetchingComponent';
import withUser from '../../hocs/withUser';
import withNotification from '../../hocs/withNotification';
import supplyQueries from './queries';
import { changeTimestampToDate, handleError } from '../../functions';
import { tabPermissions, tpl } from '../../constants/permissions';
import { SUCCESS, CRITICAL } from '../../constants/notificationTypes';
import { JitsuButton, JitsuSwitch, JitsuTable, JitsuTableRow, JitsuTableCell, JitsuAlert, JitsuInput } from '../../components-elements/jistu';
import { textChange } from '../../functions/textChange';

const getInitialState = ({ data: { getStudentsBySubject: list = [] }, period: { students } } ) => {
    const attendanceMap = students.reduce((acc, current) => ({ ...acc, [current.id]: true }), {});
    const attendance = list.reduce((acc, item) => ({
      ...acc,
      [item.id]: attendanceMap[item.id]
    }), {});
    return { attendanceMap, attendance };
};

class AttendanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...getInitialState(props),
      searchValue: '',
      filteredList: [],
    };
    this.onChangeAttendance = this.onChangeAttendance.bind(this);
    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
    this.submitScore = this.submitScore.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(getInitialState(nextProps));
  }
  onChangeAttendance(value, name) {
    this.setState(state => ({
      attendance: { ...state.attendance, [name]: !!value },
    }));
  }
  onChangeSearchValue(value) {
    this.setState({
      searchValue: value,
      filteredList: this.props.data.getStudentsBySubject
        .filter(
          ({ regNumber, lastName, firstName}) =>
          firstName.toUpperCase().includes(value.toUpperCase()) ||
          lastName.toUpperCase().includes(value.toUpperCase()) ||
          `${firstName.toUpperCase()} ${lastName.toUpperCase()}`.includes(value.toUpperCase()) ||
          `${lastName.toUpperCase()} ${firstName.toUpperCase()}`.includes(value.toUpperCase()) ||
          regNumber.toUpperCase().includes(value.toUpperCase()))
    });
  }
  async submitScore() {
    const { updatePeriod, addNotifications, period: { subject, notes, date, ...rest } } = this.props;
    const { attendance } = this.state;
    this.setState(() => ({ isSubmitting: true }))
    const studentsIds = Object.keys(attendance).filter(key => attendance[key]).map(x => parseInt(x, 10));
    try {
      await updatePeriod({
        studentsIds,
        ...rest,
        notesIds: notes.map(n => n.id),
        subjectId: subject.id,
        date: changeTimestampToDate(date)
      });
      this.setState(() => ({ isSubmitting: false }));
      addNotifications({ text: 'Request was successful', type: SUCCESS });
    } catch (e) {
      this.setState(() => ({ isSubmitting: false }));
      handleError(e, () => addNotifications({ text: 'Request was unsuccessful, try again or contact admin', type: CRITICAL }));
    }
  }
  render() {
    const { data: { getStudentsBySubject: list = [] }, user: { userRole: role } } = this.props;
    const { attendance, isSubmitting, searchValue, filteredList } = this.state;
    if (list.length === 0) {
      return <div className="p-8">
        <JitsuAlert>No students have been registered into this {textChange('subject')} for the current semester.</JitsuAlert>
      </div>
    }
    const attendanceList = !!searchValue ? filteredList : list;
    return (<div className="px-1 md:px-16 lg:px-16 xl:px-16 py-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
      <JitsuInput
        value={searchValue}
        name="searchValue"
        onChange={this.onChangeSearchValue}
        placeholder="First name or Last name or Student number"
        label="First name or Last name or Student number"
      />
      <JitsuTable headers={['Name', 'Student number', 'Present?']}>
        {
          attendanceList.map(student => <JitsuTableRow key={student.id}>
            <JitsuTableCell>{`${student.firstName} ${student.lastName}`}</JitsuTableCell>
            <JitsuTableCell>{student.regNumber}</JitsuTableCell>
            <JitsuTableCell>
              <div className="flex justify-center w-full">
                <JitsuSwitch
                  value={attendance[student.id] ? 1 : 0}
                  onChange={this.onChangeAttendance}
                  name={`${student.id}`}
                  disabled={tabPermissions.periods[role].includes(tpl.SUBMIT_ATTENDANCE_SHEET)}
                />
              </div>
            </JitsuTableCell>
          </JitsuTableRow>)
        }
      </JitsuTable>
      {tabPermissions.periods[role].includes(tpl.SUBMIT_ATTENDANCE_SHEET)
      && <div className="mt-4"><JitsuButton
        onClick={this.submitScore}
        value="Submit Attendance"
        loading={isSubmitting}
        name="submitAttendance"
      /></div>}
    </div>);
  }
}

AttendanceSheet.defaultProps = {
  data: { getStudentsBySubject: [] },
  currentAttendance: [],
};

AttendanceSheet.propTypes = {
  data: PropTypes.object.isRequired,
  onScreen: PropTypes.bool.isRequired,
  addNotifications: PropTypes.func.isRequired,
  currentAttendance: PropTypes.array.isRequired,
  period: PropTypes.object.isRequired
};

export default supplyQueries(withNotification(withUser(dataFetchingComponent(AttendanceSheet))));