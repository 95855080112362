import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import 'draft-js-linkify-plugin/lib/plugin.css';
import createVideoPlugin from 'draft-js-video-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import Editor from 'draft-js-plugins-editor';
import {
  EditorState,
  RichUtils,
  DefaultDraftBlockRenderMap,
  ContentState,
  convertFromRaw,
  convertToRaw
} from 'draft-js';
import { TextEditorWrapper } from './styled';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import getBlockStyle from './getBlockStyle';
import CustomComponent from './CustomComponent';
import { isTextJsonParsable } from './helper';
import JitsuLabel from '../JitsuLabel';
import VideoAdd from './VideoAdd';
import JitsuSentryFallbackComponent from '../JitsuSentryFallbackComponent';
import JitsuError from '../JitsuError';

const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
const videoPlugin = createVideoPlugin();

class TextEditor extends Component {
	constructor(props) {
    super(props);
		this.state = {
      editorState: EditorState.createWithContent(
        isTextJsonParsable(props.value)
          ? convertFromRaw(JSON.parse(props.value))
          : ContentState.createFromText(props.value)
      )
    };
		this.focus = () => this.refs.editor.focus();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.readOnly)
      this.setState({
        editorState: EditorState.createWithContent(
          isTextJsonParsable(nextProps.value)
            ? convertFromRaw(JSON.parse(nextProps.value))
            : ContentState.createFromText(nextProps.value)
        )
      });
  }
	onChange = (editorState) => {
    this.setState({ editorState });
    if (!this.props.readOnly) this.props.onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())), this.props.name);
	}
	toggleBlockType = (blockType) => {
		this.onChange(
			RichUtils.toggleBlockType(
				this.state.editorState,
				blockType
			));
	}

	toggleInlineStyle = (inlineStyle) => {
		this.onChange(
			RichUtils.toggleInlineStyle(
				this.state.editorState,
				inlineStyle
			)
		);
  }

	render() {
    const { editorState } = this.state;
    const { wrapperClassname, readOnly, label, error } =  this.props;
    const editorClassname = `RichEditor-root ${readOnly ? 'read-only' : ''}`
		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		let className = 'RichEditor-editor';
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				className += ' RichEditor-hidePlaceholder';
			}
		}

		return (
      <TextEditorWrapper className={`${wrapperClassname} mb-2`}>
        <Sentry.ErrorBoundary fallback={JitsuSentryFallbackComponent} showDialog>
          {label && <JitsuLabel>{label}</JitsuLabel>}
          {!readOnly && <VideoAdd
              editorState={this.state.editorState}
              onChange={this.onChange}
              modifier={videoPlugin.addVideo}
          />}
          <div className={`${editorClassname} rounded`}>
            {!readOnly && <Fragment>
              <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
              />
              <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
              />
            </Fragment>}
            <div className={className} onClick={this.focus}>
              <Editor
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorState}
                onChange={this.onChange}
                readOnly={readOnly}
                ref="editor"
                plugins={[linkifyPlugin, videoPlugin]}
                blockRenderMap={extendedBlockRenderMap}
                />
            </div>
          </div>
          {error && <JitsuError>{error}</JitsuError>}
        </Sentry.ErrorBoundary>
      </TextEditorWrapper>
		);
	}
}

const styleMap = {
	CODE: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2,
	},
};

const blockRenderMap = {
	'new-block-type-name': {
		element: CustomComponent
	}
};

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

TextEditor.defaultProps = {
  classname: '',
  wrapperClassname: '',
  label: '',
  error: '',
  onChange: () => {}
};

TextEditor.propTypes = {
  classname: PropTypes.string.isRequired,
  wrapperClassname: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default TextEditor;