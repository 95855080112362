import { graphql } from 'react-apollo';
import { classQueries, studentQueries } from '../../queries';
import { fields, fetches, getRequestData, watcher } from './helper';
import { changeTimestampToDate } from '../../functions';

export default {
  queries: (id) => [
    graphql(classQueries.list, { name: 'clazzes' }),
    graphql(studentQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(studentQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: ({ data: { getStudent } }) => getStudent
    ? {
      ...getStudent,
      class: getStudent.class ? getStudent.class.id : '',
      dob: changeTimestampToDate(getStudent.dob, 'YYYY-MM-DD'),
      subjects: getStudent.subjects.map(subject => ({ ...subject, text: subject.code, actualId: subject.id })),
      parents: getStudent.parents.map(parent => ({ ...parent, text: `${parent.firstName} ${parent.lastName}`, actualId: parent.id }))
    }
    : {},
  success: (response, history) => { history.push(`/students/${response.data.updateStudent.id}`); },
  watcher,
};