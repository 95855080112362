import React from 'react';
import PropTypes from 'prop-types';
import JitsuField from './JitsuField';
import JitsuLabel from './JitsuLabel';

const JitsuInput = ({
  value,
  onChange,
  label,
  placeholder,
  name,
  error,
  max,
  type,
  onEnter,
  tooltip,
  size,
  id,
  required,
  capitalized,
  optional,
  wrapperClassName,
  className
}) => {
  const onKeyUp = (e) => {
    if (onEnter && e.keyCode === 13) onEnter(value, name);
  }
  const inputClassNames = `
    ${className} 
    ${size ? '' : 'w-full'} 
    appearance-none border rounded py-2 px-3 text-gray-700 h-10 leading-tight focus:outline-none
  `;

  return <JitsuField error={error} className={wrapperClassName}>
    <JitsuLabel forValue="name" optional={optional} required={required} tooltip={tooltip}>
      {label}
    </JitsuLabel>
    <input type="hidden" value="prayer" name={name} />
    <input
      id={id || name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(capitalized ? e.target.value.toUpperCase() : e.target.value, name)}
      name={name}
      maxLength={max}
      size={size}
      autoComplete="off"
      className={inputClassNames}
      onKeyUp={onKeyUp}
    />
    {max && <div className="text-gray-500 text-xs my-1 text-right">{max - value.length}</div>}
  </JitsuField>
};

JitsuInput.defaultProps = {
  type: 'text',
  value: '',
  label: '',
  id: '',
  placeholder: '',
  onChange: () => {},
  searchInput: false,
  className: '',
  wrapperClassName: '',
  required: false,
  optional: false,
  size: '',
};

JitsuInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  max: PropTypes.any,
  size: PropTypes.any,
  type: PropTypes.string.isRequired,
  onEnter: PropTypes.func,
  searchInput: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  optional: PropTypes.bool.isRequired,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.any,
  capitalized: PropTypes.bool
};

export default JitsuInput;