import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

const Wrapper =  styled.div`
  &.empty-page{
    position: absolute;
    left: 300px;
    top: 100px;
    width: 60%;
    @media (max-width: 768px) {
      position: relative;
      width: calc(100% - 16px);
      left: auto;
      top: auto;
    }
    .search-input {
      width: 100%;
      .HORIZONTAL {
        height: 500px;
        overflow: auto;
      }
      li{
        padding: 20px !important;
      }
      input{
        width: 100%;
        padding: 20px;
        font-size: 16px;
      }
      button{
        width: 60px;
        font-size: 18px;
      }
    }
  }
`;

const SideSearch = ({ children, match: { isExact } }) => <Wrapper className={isExact ? 'empty-page' : 'not-empty-page'}>
  {children}
</Wrapper>

SideSearch.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(SideSearch);
