import React from 'react';
import PropTypes from 'prop-types';
import JitsuMultiselectOption from './JitsuMultiselectOption';

const JitsuMutliselectOpenOptions = ({
  options, onClickOption, textKey, lockedMap, valueKey, selectionsMap, children
}) => {
  return <div className="flex items-center relative w-64 h-64 overflow-auto mt-8">
    <div className="w-full p-4">
      <div className={`shadow top-120 bg-white z-40 w-full left-2 rounded max-h-select overflow-y-auto`}>
        <div className="flex flex-col w-full h-full">
          {children}
          {
            options.map((option) => <JitsuMultiselectOption
              key={option[valueKey]}
              locked={!!lockedMap[option[valueKey]]}
              onClickOption={onClickOption}
              selected={!!selectionsMap[option[valueKey]]}
              option={option}
            >
              {option[textKey]}
            </JitsuMultiselectOption>)
          }
        </div>
      </div>
    </div>
  </div>
};

JitsuMutliselectOpenOptions.defaultProps = {
  selectionsMap: {},
  children: ''
};

JitsuMutliselectOpenOptions.propTypes = {
  options: PropTypes.array.isRequired,
  lockedMap: PropTypes.object.isRequired,
  selectionsMap: PropTypes.object.isRequired,
  onClickOption: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default JitsuMutliselectOpenOptions;