import React, { Fragment, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withUser } from '../hocs';
import { SuspenseComponent } from '../components-elements';
import { needsAuthentication } from '../hocs/authenticated';
import PAGES from '../constants/pages';

const InitialScreen = lazy(() => import('../components-elements/InitialScreen'));

const RoutesFromMap = ({ user }) => user.userId
  ? <Fragment>
  <SuspenseComponent>
    <Route
      path={`/${PAGES.assessments}`}
      component={needsAuthentication(() =>
        <InitialScreen subMessage="select assessment from list"/>, PAGES.assessments)}
      exact={true}
    />
    <Route
      path={`/${PAGES.classes}`}
      component={needsAuthentication(() =>
        <InitialScreen subMessage="select class from list"/>, PAGES.classes)}
      exact={true}
    />
    <Route
      path={`/${PAGES.notes}`}
      component={needsAuthentication(() =>
        <InitialScreen subMessage="select note from list"/>, PAGES.notes)}
      exact={true}
    />
    <Route
      path={`/${PAGES.news}`}
      component={needsAuthentication(() =>
        <InitialScreen subMessage="select news from list"/>, PAGES.news)}
      exact={true}
    />
    <Route
      path={`/${PAGES.schools}`}
      component={needsAuthentication(() =>
        <InitialScreen subMessage="click on school from the list menu"/>, PAGES.schools)}
      exact={true}
    />
    <Route
      path={`/${PAGES.grades}`}
      component={needsAuthentication(() =>
        <InitialScreen subMessage="select grade from list"/>, PAGES.grades)} 
      exact={true}
    />

    <Route
      path={`/${PAGES.colleges}`}
      component={needsAuthentication(() =>
        <InitialScreen subMessage="select college from list"/>, PAGES.colleges)} 
      exact={true}
    />
  </SuspenseComponent>
</Fragment>
: <Fragment/>;

RoutesFromMap.propTypes = {
  user: PropTypes.object.isRequired
};

export default withUser(RoutesFromMap);