import dateFns from 'date-fns';
import moment from 'moment-timezone';
import jwtDecode from 'jwt-decode';
import { ADMIN, TEACHER, STUDENT, PARENT, OVERSEER } from '../constants/roles';
import { permissions, permissionLevels, tabPermissions } from '../constants/permissions';
import { LOCAL_HOST } from '../constants/api';
import * as Sentry from '@sentry/react';

export const getMonthData = (date, isFirstMonth, isLastMonth) => ({
  name: dateFns.format(date, 'MMM'),
  startDate: isFirstMonth ? dateFns.format(date, 'YYYY-MM-DD') : dateFns.format(dateFns.startOfMonth(date), 'YYYY-MM-DD'),
  endDate: isLastMonth ? dateFns.format(date, 'YYYY-MM-DD') : dateFns.format(dateFns.endOfMonth(date), 'YYYY-MM-DD')
});

export const getToken = () => localStorage.getItem('token');

export const getCurrentUser = () => getToken() ? jwtDecode(getToken()) : {};

export const getSemesterStartAndEndDates = () => {
  const { semesterEndDate, semesterStartDate } = getCurrentUser();
  return {
    startDate: dateFns.format(semesterStartDate, 'YYYY-MM-DD'),
    endDate: dateFns.format(semesterEndDate, 'YYYY-MM-DD')
  }
}

export const getCurrentSemesterMonths = () => {
  const { semesterEndDate, semesterStartDate } = getCurrentUser();
  const startMonth = getMonthData(semesterStartDate, true);
  const lastMonth = getMonthData(semesterEndDate, false, true);

  let semesterMonths = [startMonth];
  const lastMonthDate = lastMonth.startDate;

  let counter = 0;
  while (dateFns.isBefore(semesterMonths[counter].startDate, lastMonthDate) && counter < 12) {
    semesterMonths = [...semesterMonths, getMonthData(dateFns.addMonths(semesterMonths[counter].startDate , 1))];
    counter++;
  }
  return [{ name: 'All', startDate: startMonth.startDate, endDate: lastMonth.endDate }, ...semesterMonths]; // .slice(0, 5);
};

export const handleRandomDateFormats = (date, preferredFormat) => dateFns.format(date, preferredFormat || 'YYYY-MM-DD');

export const createMap = (arr, key) => arr.reduce((acc, curr) => ({ ...acc, [curr[key]]: curr }), {});

export const relocateUser = (location) => {
  window.location.href = `${window.location.origin}/${location}`;
}

export const logoutUser = () => {
  window.location.href = `${window.location.origin}/`;
}

export const deleteToken = () => {
  localStorage.removeItem('token');// eslint-disable-line
  logoutUser();
};

export const goToWebsite = (website) => {
  window.location.href = website;
};

export const isNumber = val => !isNaN(parseFloat(val)) && isFinite(val); // eslint-disable-line

export const isInteger = val => Number.isInteger(val); // eslint-disable-line

export const sortArray = (array, property, descending) => (property === '' || property === undefined)
  ? array
  : array.concat().sort((a, b) => {
    const currentElement = isNumber(a[property]) ? a[property] : a[property].toUpperCase();
    const nextElement = isNumber(a[property]) ? b[property] : b[property].toUpperCase();
    if (currentElement < nextElement) return descending ? 1 : -1;
    if (currentElement > nextElement) return descending ? -1 : 1;
    return 0;
  });

export const passwordChangeKey = {
  [ADMIN]: 'updateTeacherPassword',
  [TEACHER]: 'updateTeacherPassword',
  [STUDENT]: 'updateStudentPassword',
  [PARENT]: 'updateParentPassword'
};

export const isPassportChanged = (response, role) => response[passwordChangeKey[role]].token !== null;

export const changeTimestampToDate = (timestamp, format = 'YYYY-MM-DD hh:mm a') => {
  // const user = getCurrentUser();
  return moment.utc(timestamp * 1).format(format);
}

export const changeMilitaryTimeToNormalTime = (time) => {
  // const user = getCurrentUser();
  return moment
    .utc(`2020-01-01 ${time}`, 'YYYY-MM-DD hh:mm a')
    // .tz(user.timezone)
    .format('hh:mm a');
};

export const createDownloadLink = url => `${LOCAL_HOST}/files${url}`;

export const getFilename = url => url.split('/').pop()

export const logError = error => console.error(error);

export const pageAuthorized = (name) => {
  const role = getCurrentUser().userRole;
  if (!role) return true;
  return permissions[name] && permissions[name][getCurrentUser().userRole]
    ? permissions[name][getCurrentUser().userRole].includes(permissionLevels.PAGE)
    : false;
};

export const tabAuthorized = (tabName, page, ignorePermission) => {
  const role = getCurrentUser().userRole;
  if (ignorePermission) return true;
  if (!role || !tabPermissions[page]) return true;
  return tabPermissions[page][role].includes(tabName);
}

export const tabsAuthorized = (tabs = [], page) => tabs.filter(tab => tabAuthorized(tab.value, page, tab.ignorePermission))

export const selectSlot = (history, { userRole }) => {
  if ([ADMIN, TEACHER, OVERSEER].includes(userRole)) {
    history.push('/periods/create');
  }
};

export const changePeriodsToEvents = periods => periods.map((period) => ({
  ...period,
  startDate: new Date(`${changeTimestampToDate(period.date, 'YYYY-MM-DD')} ${period.startTime}`),
  endDate: new Date(`${changeTimestampToDate(period.date, 'YYYY-MM-DD')} ${period.endTime}`),
  date: new Date(`${changeTimestampToDate(period.date, 'YYYY-MM-DD')} ${period.startTime}`),
  title: `${period.subject ? `${period.subject.code} - ` : ''}${period.name}`,
}));

export const validateDateFormatAsYYYY_MM_DD = (dob) => {
  const [year, month, day] = dob.split('-');
  return !(year === undefined || month === undefined || day === undefined || !isNumber(year) || !isNumber(month) || !isNumber(day) ||!dateFns.isValid(new Date(dob)));
};

export const forcefullyExtractId = history => parseInt(history.location.pathname.split('/')[2] || 0, 10);

export const captureError = (error) => {
  if (!['production', 'PRODUCTION', 'PROD'].includes(process.env.NODE_ENV)) return;
    Sentry.captureException(error);
}

export const handleError = (error, callback = () => {}) => {
  if (error.status === 401) {
    deleteToken();
    return;
  }
  captureError(error);
  callback();
};
