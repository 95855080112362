import dateFns from 'date-fns';
import { TEXT_INPUT, SEARCH_MULTISELECT, SELECT_INPUT, DATE_INPUT } from '../../constants/fieldTypes';
import validateParent from './validation';
import { PARENT } from '../../constants/roles';
import nations from '../../constants/nations';
import { DATE } from '../../constants/dateInputTypes';

export const fields = [
  () => ({ name: 'firstName', type: TEXT_INPUT, label: 'First name', placeholder: 'Subject', required: true }),
  () => ({ name: 'lastName', type: TEXT_INPUT, label: 'Last Name', placeholder: 'Doe', required: true }),
  () => ({ name: 'regNumber', type: TEXT_INPUT, label: 'Reg number', placeholder: 'GB123456', required: true, capitalized: true }),
  () => ({
    name: 'dob',
    required: true,
    type: DATE_INPUT,
    label: 'Date of Birth',
    placeholder: '1992-05-11',
    dateInputType: DATE
  }),
  () => ({ name: 'email', type: TEXT_INPUT, label: 'email', placeholder: 'email@mail.com', required: true, }),
  () => ({ name: 'phoneNumber', type: TEXT_INPUT, label: 'Phone number', placeholder: '07832393288', required: true, }),
  () => ({ name: 'address', type: TEXT_INPUT, label: 'address', placeholder: 'address', required: true, }),
  () => ({
    name: 'gender',
    type: SELECT_INPUT,
    label: 'Gender',
    valueKey: 'value',
    required: true,
    options: [{ value: 'Male', text: 'Male' }, { value: 'Female', text: 'Female' }]
  }),
  () => ({
    name: 'nationality',
    type: SELECT_INPUT,
    label: 'Nationality',
    required: true,
    valueKey: 'code',
    options: nations.map(nation => ({ text: nation.name, value: nation.code }))
  }),
  props => ({
    name: 'students',
    type: SEARCH_MULTISELECT,
    textKey: 'text',
    label: 'Kids',
    optional: true,
    maxHeight: '100px',
    placeholder: 'kids',
    displayTextFcn: selection => selection.text || `${selection.firstName} ${selection.lastName}`,
    valueKey: 'actualId',
    through: 'student',
    lockedOptions: props.data
      ? props.data.getParent.students.map(student => ({ ...student, actualId: student.id, text: student.code }))
      : []
  }),
];

export const getRequestData = (props, data, dataAddition) => ({
  requestData: {
    ...data,
    role: PARENT,
    dob: dateFns.format(new Date(data.dob), 'YYYY-MM-DD'),
    ...dataAddition
  },
  errors: validateParent(data)
});

export const fetches = [];
