import React from 'react';
import PropTypes from 'prop-types';

const CustomComponent = ({ children }) => {
  return (
    <div>{children}</div>
  );
}

CustomComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default CustomComponent;
