import { graphql } from 'react-apollo';
import teacherQueries from '../../queries/teacher';
import { TEACHER } from '../../constants/roles';
import { fields, fetches, getRequestData } from './helper';

export default {
  queries: () => [
    graphql(teacherQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData: (props, data) => getRequestData(props, data, { password: 'password' }),
  initialValues: () => ({
    firstName: '',
    lastName: '',
    dob: '2000-05-11',
    nationality: 'NG',
    email: '',
    gender: 'Male',
    phoneNumber: '',
    role: TEACHER,
    qualification: '',
    address: '',
    regNumber: '',
    previousWorkPlace: '',
    subjects: []
  }),
  success: (response, history) => { history.push(`/teachers/${response.data.createTeacher.id}`); }
};
