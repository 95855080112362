import React from 'react';
import PropTypes from 'prop-types';
import JitsuField from './JitsuField';
import JitsuLabel from './JitsuLabel';

const JitsuSelect = ({
  label,
  name,
  id,
  options,
  onChange,
  value,
  error,
  defaultOption,
  ignoreFirstOption,
  classname,
  wrapperClassname,
  disabled,
  optional,
  required,
  tooltip,
}) => {

  const selectClassName = `
    ${classname} 
    ${disabled ? 'cursor-not-allowed' : ''} 
    block appearance-none w-full 
    bg-gray-200 border border-gray-200 
    text-gray-700 py-3 px-4 pr-8 
    rounded leading-tight focus:outline-none 
    focus:bg-white focus:border-gray-500
  `;
  return <JitsuField error={error} className={wrapperClassname}>
    {label && <JitsuLabel forValue={name} optional={optional} required={required} tooltip={tooltip}>
      {label}
    </JitsuLabel>}
    <select
      onChange={({ target: { value } }) => onChange(value, name)}
      name={name}
      id={id || name}
      value={value || ''}
      className={selectClassName}
      disabled={disabled}>
      {!ignoreFirstOption && <option value={defaultOption.value}>{defaultOption.text}</option>}
      {
        options.map(({ value, text, disabled }) => <option value={value} key={value} disabled={disabled}>{text}</option>)
      }
    </select>
  </JitsuField>
}

JitsuSelect.defaultProps = {
  value: '',
  defaultOption: { text: 'Select an option', value: '' },
  ignoreFirstOption: false,
  classname: '',
  wrapperClassname: '',
  disabled: false
};

JitsuSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.object.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  valueKey: PropTypes.string,
  wrapperClassname: PropTypes.string,
  classname: PropTypes.string,
  ignoreFirstOption: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any
};


export default JitsuSelect;