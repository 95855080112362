import styled from 'styled-components';
import { WHITE, LIGHTER_GRAY, LIGHT_GRAY, DARK_SUBSTITUTE } from '../../constants/colors';

export const CardWrapper = styled.div`
  background: ${WHITE};
  h3 {
    text-align: left;
    margin: 0;
    padding: 16px;
    color: ${DARK_SUBSTITUTE};
  }
`;

export const CardBody = styled.div`
  overflow: auto;
  height: 85%;
  padding: 0 15px;
  ::-webkit-scrollbar-thumb {
        background-color: ${LIGHTER_GRAY};
        outline: 1px solid ${LIGHT_GRAY};
      }
`;