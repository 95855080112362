import { graphql } from 'react-apollo';
import { collegeQueries } from '../../queries';
import { fields, getRequestData } from './helper';

export default {
  queries: id => [
    graphql(collegeQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(collegeQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  dataKey: 'getCollege',
  initialValues: ({ data: { getCollege = {} } }) => ({
    ...getCollege,
    teacher: getCollege.teacher
      ? [{
        ...getCollege.teacher,
        actualId: getCollege.teacher.id,
        text: `${getCollege.teacher.firstName} ${getCollege.teacher.lastName}`
      }]
      : []
  }),
  success: (response, history) => { history.push(`/colleges/${response.data.updateCollege.id}`); }
};

