import React from 'react';
import PropTypes from 'prop-types';

const JitsuTableCell = ({ children, className }) => <td className={`${className} px-4 py-4`}>
  {children}
</td>

JitsuTableCell.defaultProps = {
  children: '',
  className: ''
};

JitsuTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired
};

export default JitsuTableCell;
