import styled from 'styled-components';

const SchoolSummaryDiv = styled.div`
  
`;

const PieChartWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const UsersSummariesWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
`;

const UsersSummaryWrapper = styled.div`
  width: 200px;
  font-size: 14px;
  padding: 10px;
  background-color: #fff;
`;

const UsersSummaryTitle = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const UsersSummaryDetails = styled.div`
  display: flex;
  justify-content: center;
  >div{
    margin: 10px;
    text-align: center;
  }
`;

export {
  SchoolSummaryDiv,
  UsersSummaryDetails,
  PieChartWrapper,
  UsersSummaryWrapper,
  UsersSummariesWrapperDiv,
  UsersSummaryTitle
};
