import { graphql } from 'react-apollo';
import dateFns from 'date-fns';
import { assessmentQueries, subjectQueries } from '../../queries';
import { fetches, fields, getRequestData } from './helper';
import { afterCreate } from '../../functions/query';

export default {
  queries: () => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(assessmentQueries.create, {
      props: ({ mutate, ownProps: { user } }) => ({
        sendRequest: variables => mutate({
          variables,
          update(cache, { data }) {
            afterCreate(
              cache,
              {
                query: assessmentQueries.listByCreatedAt,
                variables: { endDate: user.semesterEndDate, startDate: user.semesterStartDate }
              },
              'getAssessmentsByDeadline',
              data.createAssessment
            );
          }
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: () => ({
    topic: '',
    description: '',
    fileUrl: '',
    enforceAttendanceRule: 0,
    enforceSubmission: 1,
    overallMark: '10',
    subjects: [],
    type: 'ASSIGNMENT',
    deadline: dateFns.format(new Date(), "YYYY-MM-DD HH:mm"),
  }),
  success: (response, history) => { history.push(`/assessments/${response.data.createAssessment.id}`); }
};