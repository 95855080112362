import { graphql } from 'react-apollo';
import flowRight from "lodash.flowright";

import { subjectQueries, periodQueries } from '../../queries';

export default Component =>
  flowRight(
    graphql(subjectQueries.getSubjectStudents, {
      skip: ({ onScreen, period }) => !period.subject || !period.subject.id || !onScreen,
      options: ownProps =>
        ({
          variables: {
            subjectId: ownProps.period.subject.id,
            status: 'ACCEPTED'
          }
        })
    }),
    graphql(periodQueries.update, {
      props: ({ mutate }) => ({
        updatePeriod: variables => mutate({
          variables,
          update: (proxy, { data: { updatePeriod } }) => {
            const query = {
              query: periodQueries.item,
              variables: {
                id: parseInt(updatePeriod.id, 10),
              }
            };
            const data = proxy.readQuery(query);
            data.getPeriod = updatePeriod;
            proxy.writeQuery({ ...query, data });
          }
        })
      })
    })
  )(Component);
