import styled from 'styled-components';
import { PAD_SCREEN } from '../../constants/screenSizes';
import { SUCCESS, MESSAGE, CRITICAL } from '../../constants/notificationTypes';
import { RED, DARK_GRAY, BLUE, GREEN } from '../../constants/colors';

export const ToastrDiv = styled.div`
  position: fixed;
  height: auto;
  top: 0;
  &.on-screen{
    right: calc(50% - 275px);
    margin-top: 10px;
  }
  &.off-screen{
    top: -100%;
  }

  @media screen and (${PAD_SCREEN}) {
    width: 550px;
    &.on-screen{
    }
    &.off-screen{
      top: -700px;
    }
  }
`;


export const NotificationListItem = styled.li`
 &.${SUCCESS}{
  border-left: solid 10px ${GREEN};
 }
 &.${MESSAGE}{
  border-left: solid 10px ${BLUE};
}
&.${CRITICAL}{
  border-left: solid 10px ${RED};
}
`;

export const CloseSpan = styled.span`
  position: absolute;
  right: 5px;
  top: 0px;
  color: ${DARK_GRAY};
  cursor: pointer;
  font-size: 12px;
  &:hover{
    color: ${RED};
  }
`;