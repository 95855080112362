export const createQueryKey = (property, variables) =>
  variables
    ? `${property}(${JSON.stringify(variables)})`
    : property;

export const isQueryMade = (proxy, property, variables) => {
  return proxy.data.data.ROOT_QUERY[createQueryKey(property, variables)];
}


export const afterCreate = (proxy, query, property, created) => {
  if (isQueryMade(proxy, property, query.variables)) {
    const data = proxy.readQuery(query);
    data[property] = [created, ...data[property]];
    proxy.writeQuery({ ...query, data });
  }
};

export const afterDelete = (proxy, query, property, deleted) => {
  if (isQueryMade(proxy, property, query.variables)) {
    const data = proxy.readQuery(query);
    data[property] = data[property]
      .filter(item => item.id !== deleted.id);
    proxy.writeQuery({ ...query, data });
  }
};
