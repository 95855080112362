import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dataFetchingComponent from '../../hocs/dataFetchingComponent';
import classQueries from '../../queries/clazz';
import JitsuTable from '../JitsuTable';
import JitsuTableRow from '../JitsuTableRow';
import JitsuTableCell from '../JitsuTableCell';
import { TERTIARY } from '../../constants/schoolTypes';
import JitsuUserStatus from '../JitsuUserStatus';
import { textChange } from '../../functions/textChange';

const ClassStudentListWrapperDiv = styled.div`
  table tr td{
    padding: 16px;
  }
`;

const ClassStudentList = ({ data }) => <ClassStudentListWrapperDiv>
  {
    data.getClassStudents.length === 0
      ? <div>No {textChange('students')}</div>
      : <JitsuTable>{data.getClassStudents.map((student, index) => <JitsuTableRow key={student.id}>
        <JitsuTableCell>{index + 1}.</JitsuTableCell>
        <JitsuTableCell>
          <Link key={student.id} to={`/students/${student.id}`}>
            {student.firstName} {student.lastName}
          </Link>
        </JitsuTableCell>
        <JitsuTableCell>
          {student.regNumber}
        </JitsuTableCell>
        <JitsuTableCell>
          <div className="w-32"><JitsuUserStatus status={student.status} /></div>
        </JitsuTableCell>
      </JitsuTableRow>)}</JitsuTable>
  }  
</ClassStudentListWrapperDiv>;

ClassStudentList.defaultProps = {
  data: { getClassStudents: [] }
};

ClassStudentList.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};

export default graphql(classQueries.classStudents, {
  skip: ownProps => {
    if (!ownProps.id) return true;
    if (ownProps.user.schoolType === TERTIARY && !ownProps.year) return true;
    return false; 
  },
  options: ownProps => ({
    variables: {
      classId: ownProps.id,
      year: parseInt(ownProps.year, 10)
    }
  })
})(dataFetchingComponent(ClassStudentList));