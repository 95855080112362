import { minValue, maxValue } from '../commonValidations';

const validateMargin = (marginTop, marginBottom, isTop) => {
  if (isTop && marginTop <= marginBottom) {
    return 'top margin must be more than bottom margin'
  } else if (!isTop && marginBottom >= marginTop) {
    return 'top margin must be more than bottom margin'
  } else if (!marginTop) {
    return 'can not be empty'
  } else if (marginTop > 100) {
    return 'can not be more than 100';
  } else if (marginTop < 0) {
    return 'can not be less than 0'
  }
  return null;
};

const validateCode = (code) => {
  if (code.length > 2) {
    return maxValue(2);
  } else if (code.length < 1) {
    return minValue(1);
  }
  return null;
}

const validatePoints = point => {
  if (!point && point !== '0' && point !== 0) {
    return 'cannot be empty';
  } 
  if (point > 5) {
    return 'cannot be more 5';
  } 
  if (point < 0) {
    return 'cannot be less than 0';
  }
  return null;
};

export default ({ name, code, topMargin, bottomMargin, points }) => ({
  name: name.length < 3 ? minValue(3) : null,
  code: validateCode(code),
  points:  validatePoints(parseInt(points, 10)),
  topMargin: validateMargin(parseFloat(topMargin, 10), parseFloat(bottomMargin, 10), true),
  bottomMargin: validateMargin(parseFloat(topMargin, 10), parseFloat(bottomMargin, 10))
});
