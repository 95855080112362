import { schoolQueries } from '../../queries';
import { afterCreate, afterDelete } from '../../functions/query';
import { SELECT_INPUT, TEXT_INPUT } from '../../constants/fieldTypes';
import validateSchool from './validation';

export const updateSchoolListAfterCreate = (proxy, school) => {
  const query = { query: schoolQueries.list };
  afterCreate(proxy, query, 'getSchool', school);
};

export const updateSchoolListAfterDelete = (proxy, school) => {
  const query = { query: schoolQueries.list };
  afterDelete(proxy, query, 'getSchool', school);
};

export const fields = [
  () => ({ name: 'name', type: TEXT_INPUT, label: 'name', placeholder: 'Glory Nursery and Primary School' }),
  () => ({ name: 'address', type: TEXT_INPUT, label: 'school address', placeholder: 'Address of school' }),
  () => ({ name: 'website', type: TEXT_INPUT, label: 'school website', placeholder: 'https://google.com' }),
  () => ({
    name: 'timezone',
    type: SELECT_INPUT,
    label: 'Timezone',
    ignoreFirstOption: true,
    required: true,
    options: ['Africa/Lagos', 'Europe/London'].map(type => ({ text: type, value: type }))
  }),
  // () => ({ name: 'badgeUrl', type: UPLOAD_INPUT, label: 'school badge', placeholder: '' }),
];

export const getRequestData = (_, requestData) => ({
  requestData,
  errors: validateSchool(requestData)
});
