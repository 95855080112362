import gql from 'graphql-tag';

export default {
  list: gql`query {
    getRequests{
      requestedBy{ id, firstName, lastName },
      answeredBy{ id, firstName, lastName },
      action, tablename, reason, vals, status, createdAt, updatedAt, id, relatedRowId, displayName
    }
  }`,
  listByStatus: gql`query getRequestsQuery($status: String) {
    getRequests(status: $status){
      requestedBy{ id, firstName, lastName },
      answeredBy{ id, firstName, lastName },
      action, tablename, reason, vals, status, createdAt, updatedAt, id, relatedRowId, displayName
    }
  }`,
  listRequestsByRelatedRowIdAndActionType: gql`query getRequestsQuery(
    $status: String, $action: String, $relatedRowId: Int, $tablename: String, $vals: String
  ) {
    getRequests(status: $status, action: $action, relatedRowId: $relatedRowId, tablename: $tablename, vals: $vals){
      requestedBy{ id, firstName, lastName },
      answeredBy{ id, firstName, lastName },
      action, tablename, reason, vals, status, createdAt, updatedAt, id, relatedRowId, displayName
    }
  }`,
  item: gql`query getRequestQuery($id: Int) {
    getRequest(id: $id){
      requestedBy{ id, firstName, lastName },
      answeredBy{ id, firstName, lastName },
      action, tablename, reason, vals, status, createdAt, updatedAt, id, relatedRowId, displayName
    }
  }`,
  create: gql`mutation createRequestQuery(
    $requestedBy: Int,
    $relatedRowId: Int,
    $action: String,
    $tablename: String,
    $vals: String,
    $reason: String) {
      createRequest(
        requestedBy: $requestedBy,
        action: $action,
        vals: $vals,
        tablename: $tablename,
        relatedRowId: $relatedRowId,
        reason: $reason){
          requestedBy{ id, firstName, lastName },
          answeredBy{ id, firstName, lastName },
          action, tablename, reason, vals, status, createdAt, updatedAt, id, relatedRowId, displayName
      }
    }
  `,
  answer: gql`mutation answerRequestQuery(
    $id: Int,
    $status: String,
    $action: String,
    $displayName: String,) {
      answerRequest(
        id: $id,
        status: $status,
        displayName: $displayName,
        action: $action){
          requestedBy{ id, firstName, lastName },
          answeredBy{ id, firstName, lastName },
          action, tablename, reason, vals, status, createdAt, updatedAt, id, relatedRowId, displayName
      }
    }
  `,
  wipe: gql`mutation deleteRequestQuery($id: Int) {
    deleteRequesr(id: $id){
        id
    }
  }`
}