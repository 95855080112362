import { graphql } from 'react-apollo';
import { periodQueries, subjectQueries } from '../../queries';
import { changeTimestampToDate } from '../../functions';
import { getRequestData, fetches, fields } from './helper';
import { SELECT_INPUT } from '../../constants/fieldTypes';

export default {
  queries: (id) => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(periodQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(periodQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          refetchQueries: [{ query: periodQueries.list }]
        })
      })
    })
  ],
  fields: [...fields, () => ({
    name: 'propagate',
    type: SELECT_INPUT,
    label: 'Change periods related to this one?',
    ignoreFirstOption: true,
    valueKey: 'value',
    options: [{ value: 0, text: 'No' }, { value: 1, text: 'Yes' }]
  })],
  fetches,
  getRequestData,
  initialValues: ({ data: { getPeriod = {} } }) =>({
    ...getPeriod,
    propagate: '0',
    date: changeTimestampToDate(getPeriod.date, 'YYYY-MM-DD'),
    subjectId: getPeriod.subject ? getPeriod.subject.id : '',
    notes: getPeriod.notes.map(note => ({ ...note, text: note.topic, actualId: note.id }))
  }),
  success: (response, history) => { history.push(`/periods/${response.data.updatePeriod.id}`); }
}
