export const FIRST = 1;
export const SECOND = 2;
export const THIRD = 3;
export const FOURTH = 4;
export const ANY = 0;

export const numberToTextMap =  {
  [FIRST]: 'First',
  [SECOND]: 'Second',
  [THIRD]: 'Third',
  [FOURTH]: 'Fourth'
}