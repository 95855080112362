import gql from 'graphql-tag';

export default {
  result: gql`query getStudentResultQuery($studentId: Int) {
    getStudentResult(studentId: $studentId){
        studentId, name, regNumber, email, phoneNumber,
        results{
            code,
            subjectId,
            assessments{ score, highestScore, sum, count, percentage, id, overallMark },
            highestScore,
            averageScore,
            creditUnit
        }
    }
  }`,
  list: gql`query {getStudents{firstName, lastName, id}}`,
  item: gql`query getStudentQuery($id: Int) {
    getStudent(id: $id){
        lastName,
        id,
        class{ name, id },
        regNumber,
        firstName, dob,
        gender, address,
        phoneNumber,
        regNumber,
        email,
        nationality,
        subjects{ id, code, name, creditUnit, teachers{ firstName, lastName, id } },
        parents{ id, firstName, lastName },
        previousSchool,
        status,
        role,
        programme,
        level,
        stateOfResidence,
        countryOfResidence
    }
  }`,
  changePassword: gql`mutation updateStudentPasswordQuery($regNumber: String, $password: String, $newPassword: String) {
    updateStudentPassword(regNumber: $regNumber, password: $password, newPassword: $newPassword){
      status
    }
  }`,
  create: gql`mutation createStudentQuery(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $programme: String!,
    $level: String!,
    $stateOfResidence: String!,
    $countryOfResidence: String!,
    $phoneNumber: String!,
    $password: String!,
    $regNumber: String!,
    $dob: String!,
    $gender: String!,
    $address: String!,
    $nationality: String!,
    $photoUrl: String!,
    $role: String!,
    $status: String,
    $previousSchool: String!,
    $classId: Int!,
    $parentsIds: [Int],
    $subjectsIds: [Int]) {
        createStudent(
            lastName: $lastName,
            firstName: $firstName,
            stateOfResidence: $stateOfResidence,
            programme: $programme,
            countryOfResidence: $countryOfResidence,
            level: $level,
            email: $email,
            status: $status,
            phoneNumber: $phoneNumber,
            dob: $dob,
            gender: $gender,
            previousSchool: $previousSchool,
            address: $address,
            nationality: $nationality,
            role: $role,
            photoUrl: $photoUrl,
            password: $password,
            classId: $classId
            regNumber: $regNumber,
            parentsIds: $parentsIds,
            subjectsIds: $subjectsIds){
                lastName,
                id,
                firstName,
                dob, gender,
                address,
                phoneNumber,
                previousSchool,
                email,
                nationality,
                language,
                parents { id, firstName, lastName },
                subjects{ id, code, name, creditUnit, teachers{ firstName, lastName } },
                class{ id },
                status,
                role,
                programme,
                level,
                stateOfResidence,
                countryOfResidence
        }
    }
  `,
  update: gql`mutation updateStudentQuery(
    $id: Int,
    $firstName: String!,
    $programme: String!,
    $level: String!,
    $stateOfResidence: String!,
    $countryOfResidence: String!,
    $lastName: String!,
    $email: String!,
    $phoneNumber: String!,
    $dob: String!,
    $status: String,
    $gender: String!,
    $address: String!,
    $nationality: String!,
    $previousSchool: String!,
    $classId: Int!,
    $regNumber: String!,
    $parentsIds: [Int],
    $subjectsIds: [Int]) {
        updateStudent(
            id: $id,
            lastName: $lastName,
            regNumber: $regNumber,
            status: $status,
            firstName: $firstName,
            email: $email,
            phoneNumber: $phoneNumber,
            stateOfResidence: $stateOfResidence,
            programme: $programme,
            countryOfResidence: $countryOfResidence,
            level: $level,
            dob: $dob,
            gender: $gender,
            address: $address,
            previousSchool: $previousSchool,
            nationality: $nationality,
            classId: $classId,
            parentsIds: $parentsIds,
            subjectsIds: $subjectsIds){
                lastName,
                id,
                firstName,
                dob, gender,
                address,
                regNumber,
                phoneNumber,
                email,
                nationality,
                previousSchool,
                parents { id, firstName, lastName },
                subjects{ id, code, name, creditUnit, teachers{ firstName, lastName } },
                class{ id }
                status,
                role,
                programme,
                level,
                stateOfResidence,
                countryOfResidence
        }
    }
  `,
  wipe: gql`mutation deleteStudentQuery($id: Int) {
    deleteStudent(id: $id){
        id
    }
  }`,
  studentSubjects: gql`query getStudentSubjectsQuery($id: Int){
    getStudentSubjects(id: $id){
        code, id, isMandatory, class { id, name, shortName }, subject{ id, name, shortName }
    }
  }`,
  studentSubjectAssessments: gql`query getStudentAssessmentsQuery($subjectId: Int, $studentId: Int){
    getStudentAssessments(subjectId: $subjectId, studentId: $studentId){
        id, teacher{ id, firstName, lastName }, topic, deadline, overallMark, content, fileUrl, score, type
    }
  }`,
  subjectPerformance: gql`query getStudentSubjectPerformanceQuery($subjectId: Int, $studentId: Int){
    getStudentSubjectPerformance(subjectId: $subjectId, studentId: $studentId){
        code, averageScore, creditUnit, numberOfAssessments
    }
  }`,
  studentSubjectResult: gql`query getStudentSubjectResultQuery($subjectId: Int, $studentId: Int) {
    getStudentSubjectResult(studentId: $studentId, subjectId: $subjectId){
        code,
        subjectId,
        creditUnit,
        assessments{
            score, topic, overallMark, percentage, highestScore, averageScore, id
        }
    }
  }`,
  studentOfferableSubjects: gql`query getStudentOfferableSubjectsQuery($studentId: Int) {
    getStudentOfferableSubjects(studentId: $studentId){
      code, id, status, name, creditUnit, description, yearOffered, semesterOffered, finalScore, actualId
    }
  }`,
  applyToRegisterSubjects: gql`mutation applyToRegisterSubjectsQuery($subjectsIds: [Int]) {
    applyToRegisterSubjects(subjectsIds: $subjectsIds){
        status
    }
  }`,
  acceptStudentApplications: gql`mutation acceptStudentApplicationsQuery($studentsIds: [Int], $subjectId: Int, $action: String) {
    acceptStudentApplications(studentsIds: $studentsIds, subjectId: $subjectId, action: $action){
        status
    }
  }`,
  studentSubjectApplications: gql`query getStudentSubjectApplicationsQuery($subjectId: Int) {
    getStudentSubjectApplications(subjectId: $subjectId){
      id, firstName, lastName, regNumber, class { name }, failedSubjects
    }
  }`,
  submitSemesterScores: gql`mutation submitSemesterScoresQuery($subjectsScores: [SubjectScore], $semesterId: Int, $studentId: Int) {
    submitSemesterScores(subjectsScores: $subjectsScores, semesterId: $semesterId, studentId: $studentId){
        status
    }
  }`,
  withdrawSubjectApplication: gql`mutation withdrawSubjectApplicationQuery($subjectId: Int) {
    withdrawSubjectApplication(subjectId: $subjectId){
        status
    }
  }`,
};