import React from 'react';
import PropTypes from 'prop-types';
import JitsuSpinner from './JitsuSpinner';


const JitsuButton = ({
  loading, onClick, name, value, style, disabled, className, wrapperClassName, bordered, danger
}) => {

  const borderedClassName = bordered
    ? 'border-solid border-black hover:bg-black hover:text-white text-black border-2'
    : `${danger ? 'bg-red-600' : 'bg-black'} ${danger ? 'hover:bg-red-800' : 'hover:bg-black'} hover:bg-black text-white`;
  const btnClassName = `
    flex 
    ${disabled ? 'cursor-not-allowed bg-gray-200 text-gray-500' : borderedClassName}
    font-bold py-2 px-4 rounded 
    ${className}`;

  return <div className={wrapperClassName}>
    <button
      onClick={disabled || loading ? () => {} : onClick}
      name={name}
      style={style}
      disabled={disabled}
      className={btnClassName}>
      {value} {loading && <JitsuSpinner size="xs" className="ml-2"/>}
    </button>
  </div>
};

JitsuButton.defaultProps = {
  style: {},
  className: '',
  wrapperClassName: '',
  bordered: false,
  loading: false,
  danger: false,
  disabled: false,
  name: ''
};

JitsuButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  danger: PropTypes.bool.isRequired,
  bordered: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
};

export default JitsuButton;