import React, { Component } from 'react';
import PropTypes from 'prop-types';
import attachFilter from '../../hocs/attachPerformanceFilter';
import dataFetchingComponent from '../../hocs/dataFetchingComponent';
import withNotification from '../../hocs/withNotification';
import SubjectResult from './SubjectResult';
import { isNumber, handleError } from '../../functions';
import addQueries from './query';
import { SUCCESS, CRITICAL } from '../../constants/notificationTypes';
import SubjectsSearchAndAdd from '../SubjectsSearchAndAdd';
import { JitsuTable, JitsuButton } from '../../components-elements/jistu';

class PastResultManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: this.getInitialSubjectsValue(props),
      selectedSubjects: [],
      submitting: false
    };
    this.onChangeScore = this.onChangeScore.bind(this);
    this.onDeleteSubject = this.onDeleteSubject.bind(this);
    this.addSubjects = this.addSubjects.bind(this);
    this.selectSubject = this.selectSubject.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ subjects: this.getInitialSubjectsValue(nextProps) });
  }

  onDeleteSubject(subjectId) {
    const { subjects: tempSubjects} = this.state;
    delete tempSubjects[subjectId];
    this.setState({
      subjects: { ...tempSubjects }
    });
  }

  onChangeScore(score, subjectId) {
    this.setState((state) => ({
      subjects: {
        ...state.subjects,
        [subjectId]: {
          ...state.subjects[subjectId],
          score: score > 100 ? 100 : (score < 0 ? 0 : score),
          invalid: false
        }
      }
    }));
  }

  addSubjects(subjects) {
    const selectedSubjectsMap = subjects.reduce((acc, curr) => ({
      [curr.actualId]: {
        ...curr,
        name: curr.text,
        id: curr.actualId,
        score: 0,
        canRemove: true
      },
      ...acc,
    }), {});
    this.setState((state) => ({
      subjects: {
        ...state.subjects,
        ...selectedSubjectsMap
      },
      selectedSubjects: []
    }));
  }

  selectSubject(value) {
    this.setState({ selectedSubjects: value });
  }

  getInitialSubjectsValue({ data: { getStudentPerformance : { results = [] } } }) {
    return results.reduce((acc, performance) => ({
      ...acc,
      [performance.subject.id]: {
        score: performance.score.summary ? performance.score.summary.percentageScoreTotal : 0,
        code: performance.subject.code,
        name: performance.subject.name,
        canRemove: false,
        id: performance.subject.id,
        actualId: performance.subject.id,
      }
    }), {});
  }

  async submitChanges() {
    const { subjects } = this.state;
    const { submitScores, semesterId, addNotifications, userId } = this.props;
    let subjectsScores = [];
    let errorExists = false;
    const tempSubjects = Object.values(subjects).reduce((acc, curr) => {
      const invalid = !(curr.score <= 100 && curr.score >= 1 && isNumber(curr.score));
      if (invalid) errorExists = true;
      subjectsScores = [...subjectsScores, { subjectId: curr.id, finalScore: parseFloat(curr.score, 10), status: 'DONE' }];
      return {
        ...acc,
        [curr.id]: {
          ...curr,
          invalid,
        }
      }
    }, {});
    if (errorExists) {
      this.setState({ subjects: tempSubjects });
      return;
    }
    try {
      this.setState({ submitting: true });
      await submitScores({ semesterId: parseInt(semesterId, 10), subjectsScores, studentId: userId })
      addNotifications({ text: 'Your request was successful', type: SUCCESS });
      this.setState({ submitting: false });
    } catch (e) {
      handleError(e, () => addNotifications({ text: 'Your request was not successful', type: CRITICAL }));
      this.setState({ submitting: false });
    }
  }

  render() {
    const { subjects, selectedSubjects } = this.state;
    return (<div>
      <SubjectsSearchAndAdd
        addSubjects={this.addSubjects}
        alreadySelectedSubjects={[...Object.values(subjects).map(subject => ({ ...subject, actualId: subject.id })), ...selectedSubjects]}
      />
      <div className="mb-4">
        <JitsuTable>
          {
            Object.values(subjects).map((subject) => <SubjectResult
              key={subject.id}
              subject={subject}
              onChangeScore={this.onChangeScore}
              clickDelete={this.onDeleteSubject}
            />)
          }
        </JitsuTable>
      </div>
      <JitsuButton
        value="Submit"
        onClick={this.submitChanges}
        name="submitBtn"
        wrapperClassname="submitButtonWrapper"
      />
    </div>);
  }
}

PastResultManager.propTypes = {
  data: PropTypes.object.isRequired,
  userId: PropTypes.any.isRequired,
  semesterId: PropTypes.number.isRequired,
  submitScores: PropTypes.func.isRequired,
  addNotifications: PropTypes.func.isRequired
};


export default attachFilter(
  addQueries(
    dataFetchingComponent(withNotification(PastResultManager))
  ), true, undefined, 'You need to add a previous semester'
);