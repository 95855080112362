import { graphql } from 'react-apollo';
import { questionQueries } from '../../queries';
import { updateQuestionListAfterDelete } from './helper';

export default {
  queries: id => [
    graphql(questionQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(questionQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { deleteQuestion } }) => {
            updateQuestionListAfterDelete(proxy, deleteQuestion);
          }
        })
      })
    })
  ],
  dataKey: 'getQuestion',
  success: (_, history) => { history.push('/questions'); }
};