import { graphql } from 'react-apollo';
import { gradeQueries } from '../../queries';
import { updateGradeListAfterDelete } from './helper';

export default {
  queries: id => [
    graphql(gradeQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(gradeQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { deleteGrade } }) => {
            updateGradeListAfterDelete(proxy, deleteGrade);
          }
        })
      })
    })
  ],
  dataKey: 'getGrade',
  success: (_, history) => { history.push('/grades'); }
};