import DateFns from 'date-fns';
import { ADMIN, OVERSEER } from '../../constants/roles';
import { maxValue, minValue } from '../commonValidations';

export const validateTime = (startTime, endTime, date, isValidatingStartTime) => {
  if (startTime.length === 0) return 'this is required';
  if (!DateFns.isValid(new Date(`1992-05-11 ${startTime}`))) return 'time is invalid';
  if (!DateFns.isValid(new Date(`1992-05-11 ${endTime}`))) return 'time is invalid';
  if (isValidatingStartTime && startTime >= endTime) return 'time is invalid compared to end time';
  if (!isValidatingStartTime && endTime <= startTime) return 'time is invalid compared to start time';
  return null;
};

export default ({ endTime, startTime, subjectId, date, name }, user) => ({
  name: name.length < 3 ? minValue(3) : (name.length > 100 ? maxValue(100) : null),
  endTime: validateTime(startTime, endTime, date),
  startTime: validateTime(startTime, endTime, date, true),
  subjectId: [ADMIN, OVERSEER].includes(user.userRole) ? null : (!subjectId ? 'this is required' : null) 
  // date: DateFns.isBefore(date, new Date()) ? 'date cannot be before today' : null,
});