import {
  MONTH_SELECTED,
  ITEM_SELECTED,
  SEARCH_RESULTED,
  ON_SET_INITIAL_VALUES_FOR_CREATE_FORM,
  GROUP_SELECTED
} from '../constants/actionTypes';
import PAGES from '../constants/pages';

const searchPageInitial = {
  searchText: '',
  initialValues: {}
};

const calendarPageInitials = {
  selectedMonth: undefined,
  initialValues: ''
};

const initialState = {
  [PAGES.students]: searchPageInitial,
  [PAGES.teachers]: searchPageInitial,
  [PAGES.parents]: searchPageInitial,
  [PAGES.subjects]: searchPageInitial,
  [PAGES.assessments]: calendarPageInitials,
  [PAGES.notes]: calendarPageInitials,
  [PAGES.news]: calendarPageInitials,
  [PAGES.questions]: {},
  [PAGES.periods]: {
    selected: undefined
  }
};

const ACTION_HANDLERS = {
  [MONTH_SELECTED]: (state, { page, selectedMonth }) => ({
    ...state,
    [page]: {
      ...state[page],
      selectedMonth
    }
  }),
  [ITEM_SELECTED]: (state, { page, selectedItem }) => ({
    ...state,
    [page]: {
      ...state[page],
      selectedItem
    }
  }),
  [GROUP_SELECTED]: (state, { page, selectedGroup }) => ({
    ...state,
    [page]: {
      ...state[page],
      selectedGroup
    }
  }),
  [SEARCH_RESULTED]: (state, { page, searchText }) => ({
    ...state,
    [page]: {
      ...state[page],
      searchText
    }
  }),
  [ON_SET_INITIAL_VALUES_FOR_CREATE_FORM]: (state, { page, initialValues }) => ({
    ...state,
    [page]: {
      ...state[page],
      initialValues
    }
  })
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action.payload) : state;
};