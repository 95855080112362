import { commonFields } from '../commonHelpers';
import validateAssessment from './validation';
import { TEXT_INPUT, UPLOAD_INPUT, DATE_INPUT, SELECT_INPUT, TEXT_EDITOR } from '../../constants/fieldTypes';
import { getCurrentUser } from '../../functions';
import { DATETIME } from '../../constants/dateInputTypes';
import { ASSESSMENT, QUIZ } from '../../constants/assessmentTypes';

export const fields = [
  () => ({
    name: 'topic',
    type: TEXT_INPUT,
    label: 'Topic',
    placeholder: 'title of assessment',
    max: 100,
    required: true,
    wrapperClassName: 'topic',
  }),
  () => ({
    name: 'description',
    type: TEXT_EDITOR,
    label: 'Content',
    placeholder: 'assessment description',
    textarea: true,
    required: true
  }),
  () => ({
    name: 'overallMark',
    type: TEXT_INPUT,
    label: 'Overall Mark',
    placeholder: 'e.g 10',
    required: true,
    size: 10
  }),
  () => ({
    name: 'deadline',
    type: DATE_INPUT,
    label: 'Deadline',
    placeholder: '1992-05-11 05:00',
    dateInputType: DATETIME,
    required: true
  }),
  props => commonFields.subjectsMultiSelect(props, { required: true }),
  () => ({
    name: 'type',
    type: SELECT_INPUT,
    label: 'Assessment type',
    ignoreFirstOption: true,
    required: true,
    options: [ASSESSMENT, QUIZ].map(type => ({ text: type, value: type }))
  }),
  () => ({
    name: 'enforceAttendanceRule',
    type: SELECT_INPUT,
    required: true,
    label: 'Enforce attendance rule',
    ignoreFirstOption: true,
    options: [{ text: 'OFF', value: 0 }, { text: 'ON', value: 1 }]
  }),
  () => ({
    name: 'enforceSubmission',
    type: SELECT_INPUT,
    required: true,
    label: 'Enforce submission',
    ignoreFirstOption: true,
    options: [{ text: 'OFF', value: 0 }, { text: 'ON', value: 1 }]
  }),
  (props, state) => state.type === QUIZ ? commonFields.quizSearchSingleSelect(props, { required: true }) : null,
  () => ({ name: 'fileUrl', type: UPLOAD_INPUT, label: 'Attachment', optional: true }),
];

export const fetches = [];

export const getRequestData = (props, { errors, subjects, overallMark, enforceAttendanceRule, enforceSubmission, ...rest }) => ({
  requestData: {
    ...rest,
    overallMark: parseFloat(overallMark, 10),
    enforceAttendanceRule: parseInt(enforceAttendanceRule, 10),
    enforceSubmission: parseInt(enforceSubmission, 10),
    quizId: rest.quiz && rest.quiz[0] ? rest.quiz[0].actualId : undefined,
    subjectsIds: subjects.map(subject => subject.actualId),
    teacherId: getCurrentUser().userId,
  },
  errors: validateAssessment(rest, overallMark, subjects)
});
