import { lazy } from 'react';
import { classQueries } from '../../queries';
import { TEXT, LIST } from '../../constants/displayTypes';
import { tpl } from '../../constants/permissions'
import { textChange } from '../../functions/textChange';
import { getCurrentUser } from '../../functions';
import { TERTIARY } from '../../constants/schoolTypes';

const ClassStudentListWrapper = lazy(() => import('../../components-elements/List/ClassStudentListWrapper'));

export default {
  dataKey: 'getClass',
  query: classQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Name', text: data => data.name, id: 'name' },
    { type: TEXT, label: 'Acronym', text: data => data.code, id: 'code' },
    { type: TEXT, label: 'Address', text: data => data.address, id: 'address' },
    { type: TEXT, label: 'College', text: data => data.college ? data.college.name : '', id: 'college' },
    getCurrentUser().schoolType !== TERTIARY
     ? {
        type: TEXT,
        label: 'Next class',
        text: (data) => {
          if (!!data.final) {
            return 'Alumni'
          }
          return data.next ? data.next.name : ''
          },
        id: 'next'
      }
     : {},
    getCurrentUser().schoolType === TERTIARY
     ? { type: TEXT, label: 'Number of years to complete this course', text: data => `${data.highestYear} years` }
     : {},
    getCurrentUser().schoolType !== TERTIARY
     ? { type: TEXT, label: 'Year equivalent', text: data => `Year ${data.level}` }
     : {},
    {
      type: LIST,
      label: `${textChange('Subjects')} offered in ${textChange('class')}`,
      list: data => data.subjects,
      id: 'subjects' },
    { type: TEXT, label: `${textChange('Class')} head`, text: data => `${data.teacher.firstName} ${data.teacher.lastName}`, id: 'teacher' },
    { type: TEXT, label: 'Description', text: data => data.description, id: 'description', editor: true },
  ],
  tabHeaders: ({ data: { getClass: clazz } }) => [
    { text: 'Students', value: tpl.CLASS_STUDENTS, Component: ClassStudentListWrapper },
  ]
};