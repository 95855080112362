import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { pageAuthorized } from '../functions';

const authorized = (Component, pagename, showNothing) => {
  const Comp = (props) => {
    const pgname = pagename || props.match.params.page;
    if (!pageAuthorized(pgname)) {
      return showNothing ? <Fragment/> : <div id="unauthorized">Unauthorized</div>;
    }
    return <Component {...props} />;
  };

  Comp.propTypes = {
    match: PropTypes.object.isRequired
  };

  return Comp;
};

export default authorized;