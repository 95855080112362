import { ADD_NOTIFICATION, CLEAR_NOTIFICATIONS } from '../constants/actionTypes';

const initialState = {
  notifications: []
};

const ACTION_HANDLERS = {
  [ADD_NOTIFICATION]: (state, { notification }) => ({
    ...state,
    notifications: [...state.notifications, notification]
  }),
  [CLEAR_NOTIFICATIONS]: state => ({
    ...state,
    notifications: []
  }),
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action.payload) : state;
};