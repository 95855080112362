import { graphql } from 'react-apollo';
import teacherQueries from '../../queries/teacher';
import { fields, fetches, getRequestData } from './helper';
import { changeTimestampToDate } from '../../functions';

export default {
  queries: (id) => [
    graphql(teacherQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(teacherQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: ({ data: { getTeacher = {} } }) => ({
    ...getTeacher,
    dob: changeTimestampToDate(getTeacher.dob, 'YYYY-MM-DD'),
    subjects: getTeacher.subjects.map(subject => ({ ...subject, actualId: subject.id, text: subject.code }))
  }),
  success: (response, history) => { history.push(`/teachers/${response.data.updateTeacher.id}`); }
};
