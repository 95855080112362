import { graphql } from 'react-apollo';
import periodQueries from '../../queries/period';

export default {
  queries: id => [
    graphql(periodQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(periodQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          refetchQueries: [{ query: periodQueries.list }]
        })
      })
    })
  ],
  dataKey: 'getPeriod',
  success: (response, history) => { history.push('/periods'); },
  checkbox: {
    name: 'propagate',
    initialValue: 0,
    label: 'Delete related periods?',
  }
};