import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Item from './item';

class List extends Component{
  render() {
    const {
      list,
      displayProperty,
      keyProperty,
      linkTo,
      linkToKey,
      itemComponentAndProps: { Component, createProps },
    } = this.props;
    return (<div className="p-2 xl:h-full lg:h-full h-32 overflow-auto mb-4 lg:mb-0 xl:mb-0">
      {
        list.map(item => !Component
          ? <Item
            text={typeof displayProperty === 'string' ? item[displayProperty] : displayProperty(item)}
            key={item[keyProperty]}
            id={item[keyProperty]}
            linkTo={`${linkTo}/${item[linkToKey]}`}
          />
          : <Component
            {...createProps(item)}
            key={item[keyProperty]}
            id={item[keyProperty]}
          />
        )
      }
    </div>)
  }
}

List.defaultProps = {
  list: [],
  linkToKey: 'id',
  keyProperty: 'id',
  itemComponentAndProps: {},
  displayProperty: '',
  linkTo: '',
  useFragment: false
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  displayProperty: PropTypes.any.isRequired,
  keyProperty: PropTypes.string.isRequired,
  linkToKey: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  itemComponentAndProps: PropTypes.object.isRequired,
  useFragment: PropTypes.bool.isRequired
};

export default List;