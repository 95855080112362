import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import { schoolQueries } from '../../queries';
import UsersSummary from './UsersSummary';
import { UsersSummariesWrapperDiv } from './styled';
import dataFetchingComponent from '../../hocs/dataFetchingComponent';
import withUser from '../../hocs/withUser';
import { createSchoolData } from './helper';


const UsersSummariesWrapper = ({ data: { getSchoolSummary }, user }) => <UsersSummariesWrapperDiv className="summaries">
  {createSchoolData(getSchoolSummary)
    .map((data) => <UsersSummary data={data} key={data.name}/>)}
</UsersSummariesWrapperDiv>

UsersSummariesWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

UsersSummariesWrapper.defaultProps = {
  data: {},
  user: {},
};


export default graphql(schoolQueries.schoolSummary, {
  options: ({ id }) => ({ variables: { id } })
})(dataFetchingComponent(withUser(UsersSummariesWrapper), [], { height: 80 }));