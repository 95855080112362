import React from 'react';
import PropTypes from 'prop-types';
import { StyledErrorDiv } from './styled';
import Loading from './Loading';

const ErrorOrLoading = ({ loading, options: { hideSpinner, ...rest }, error }) => {
  return loading
    ? !hideSpinner && <Loading {...rest}/>
    : <StyledErrorDiv>
      error occured, <em>report to admin</em>
    </StyledErrorDiv>
}

ErrorOrLoading.defaultProps = {
  options: {},
  loading: false,
  error: {}
};

ErrorOrLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default ErrorOrLoading;
