import { gradeQueries } from '../../queries';
import { afterCreate, afterDelete } from '../../functions/query';
import { TEXT_INPUT, SELECT_INPUT } from '../../constants/fieldTypes';
import validateGrade from './validation';

export const updateGradeListAfterCreate = (proxy, grade) => {
  const query = { query: gradeQueries.list };
  afterCreate(proxy, query, 'getGrades', grade);
};

export const updateGradeListAfterDelete = (proxy, grade) => {
  const query = { query: gradeQueries.list };
  afterDelete(proxy, query, 'getGrades', grade);
};

export const fields = [
  () => ({ name: 'name', type: TEXT_INPUT, label: 'Name', placeholder: 'Excellent, Good, Fail', required: true }),
  () => ({ name: 'code', type: TEXT_INPUT, label: 'Code', placeholder: 'A, B, C', required: true }),
  () => ({ name: 'points', type: TEXT_INPUT, label: 'Points', placeholder: '1-5', required: true }),
  () => ({ name: 'topMargin', type: TEXT_INPUT, label: 'Top margin', placeholder: '100', required: true }),
  () => ({ name: 'bottomMargin', type: TEXT_INPUT, label: 'Bottom margin', placeholder: '70', required: true }),
  () => ({
    name: 'failed',
    type: SELECT_INPUT,
    label: 'Grade as fail',
    valueKey: 'value',
    options: [{ value: '1', text: 'Yes' }, { value: '0', text: 'No' }],
    ignoreFirstOption: true
  }),
];

export const getRequestData = (_, requestData) => ({
  requestData: {
    ...requestData,
    points: parseInt(requestData.points, 10),
    failed: parseInt(requestData.failed, 10),
    topMargin: parseFloat(requestData.topMargin, 10),
    bottomMargin: parseFloat(requestData.bottomMargin, 10)
  },
  errors: validateGrade(requestData)
});
