import {
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  MONTH_SELECTED,
  ITEM_SELECTED,
  SEARCH_RESULTED,
  ON_SET_INITIAL_VALUES_FOR_CREATE_FORM,
  GROUP_SELECTED
} from '../constants/actionTypes';

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS
});

export const addNotifications = notification => ({
  payload: { notification },
  type: ADD_NOTIFICATION
});

export const onMonthSelected = (page, selectedMonth) => ({
  payload: { page, selectedMonth },
  type: MONTH_SELECTED
});

export const onItemSelected = (page, selectedItem) => ({
  payload: { page, selectedItem },
  type: ITEM_SELECTED
});

export const onGroupSelected = (page, selectedGroup) => ({
  payload: { page, selectedGroup },
  type: GROUP_SELECTED
});

export const onSearchResulted = (page, searchText) => ({
  payload: { page, searchText },
  type: SEARCH_RESULTED
});

export const setInitialValuesForCreateForm = (page, initialValues) => ({
  payload: { page, initialValues },
  type: ON_SET_INITIAL_VALUES_FOR_CREATE_FORM
});