import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { JitsuLabel, JitsuButton } from '../../components-elements/jistu';
import { Modal } from '../../components-elements';
import AnswerForm from './AnswerForm';
import TextEditor from '../TextEditor';
import JitsuError from '../JitsuError';

const AnswersListWrapper = styled.div`
  .dnwRos .RichEditor-editor .public-DraftEditor-content {
    min-height: auto;
    padding: 8px;
  }
`;

class Answers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      index: undefined,
      answerOnScreen: undefined,
    }
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.editAnswer = this.editAnswer.bind(this);
  }
  openPopup() {
    this.setState({
      opened: true
    });
  }
  closePopup() {
    this.setState({
      opened: false,
      answerOnScreen: undefined,
      index: undefined,
    });
  }
  deleteAnswer(answerIndex) {
    const { onChange, value, name } = this.props;
    onChange(value.filter((_, index) => index !== answerIndex), name);
  }
  editAnswer(answer, index) {
    this.setState({
      opened: true,
      answerOnScreen: answer,
      index
    });
  }
  render() {
    const { label, value, error, wrapperClassName, optional, tooltip, required } = this.props;
    const { opened, answerOnScreen, index: indexOfAnswerOnScreen } = this.state;
    return <div className={wrapperClassName}>
      {label && <JitsuLabel required={required} optional={optional} tooltip={tooltip}>{label}</JitsuLabel>}
      <AnswersListWrapper className="">
        {value.map((val, index) => <div
          key={val.id || `${val.id}-${index}`}
          className={`pl-2 
          border-l-4 ${val.correct ? 'border-green-700' : 'border-red-700'} 
          flex space-x-4 mb-4
          `}
        >
          <div className="flex-1">
            <TextEditor value={val.content} readOnly/>
          </div>
          <JitsuButton
            onClick={() => this.editAnswer(val, index)}
            value="Edit"
          />
          <JitsuButton
            onClick={() => this.deleteAnswer(index)}
            value="Delete"
            danger
          />
        </div>)}
      </AnswersListWrapper>
      <JitsuButton
        onClick={this.openPopup}
        value="Add answer"
        bordered
      />
      {
        error && <JitsuError>{error}</JitsuError>
      }
      {
        opened && <Modal closeModal={this.closePopup} title="Create an answer">
          <AnswerForm {...this.props} closeModal={this.closePopup} answer={answerOnScreen} index={indexOfAnswerOnScreen} />
        </Modal>
      }
    </div>
  }
}

Answers.defaultProps = {
  label: '',
  error: '',
  value: [],
  onChange:  () => {},
  wrapperClassName: ''
};

Answers.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
  optional: PropTypes.bool,
};

export default Answers;