import gql from 'graphql-tag';

export default {
  getSubjectStudents: gql`query getSubjectStudentsQuery($subjectId: Int, $status: String) {
    getStudentsBySubject(subjectId: $subjectId, status: $status){
        lastName, id, firstName, regNumber
    }
  }`,
  list: gql`query {
    getSubjects{
        code, id, name
    }
  }`,
  item: gql`query getSubjectQuery($id: Int) {
    getSubject(id: $id){
        code,
        name,
        id,
        description,
        classes { id, name, code },
        teachers{ id, firstName, lastName },
        categories { id, name },
        leadTeacher{ id, firstName, lastName },
        students{ id, firstName, lastName },
        creditUnit
    }
  }`,
  update: gql`mutation updateSubjectQuery(
    $id: Int!,
    $code: String,
    $description: String,
    $creditUnit: Int,
    $teacherId: Int!,
    $categoriesIds: [Int],
    $name: String!,
    $teachersIds: [Int],
    $studentsIds: [Int]) {
        updateSubject(
            id: $id,
            code: $code,
            description: $description,
            categoriesIds: $categoriesIds,
            teacherId: $teacherId,
            name: $name,
            creditUnit: $creditUnit,
            teachersIds: $teachersIds,
            studentsIds: $studentsIds){
                code,
                name,
                id,
                description,
                classes { id, name, code  },
                leadTeacher{ id, firstName, lastName },
                categories { id, name },
                creditUnit,
                students { id },
                teachers { id, firstName },
        }
    }
  `,
  create: gql`mutation createSubjectQuery(
    $code: String!,
    $name: String!,
    $description: String!,
    $classesIds: [Int],
    $creditUnit: Int!,
    $teacherId: Int!,
    $teachersIds: [Int],
    $categoriesIds: [Int],
    $studentsIds: [Int]) {
        createSubject(
            code: $code,
            description: $description,
            classesIds: $classesIds,
            categoriesIds: $categoriesIds,
            teacherId: $teacherId,
            name: $name,
            creditUnit: $creditUnit,
            teachersIds: $teachersIds,
            studentsIds: $studentsIds){
                id,
                code,
                description,
                name,
                creditUnit,
                classes { id, name, code },
                categories { id, name },
                students { id },
                teachers { id, firstName },
                leadTeacher{ id, firstName, lastName },
        }
    }
  `,
  wipe: gql`mutation deleteSubjectQuery($id: Int) {
    deleteSubject(id: $id){
        id
    }
  }`,
  subjectNotes: gql`query getSubjectNotesQuery($subjectId: Int){
    getSubjectNotes(subjectId: $subjectId){
      id, teacher{ id, firstName, lastName }, description, fileUrl, createdAt, topic
    }
  }`,
  subjectAssessments: gql`query getSubjectAssessmentsQuery($subjectId: Int){
    getSubjectAssessments(subjectId: $subjectId){
        id, teacher{ id, firstName, lastName }, topic, deadline, overallMark, description, fileUrl, subjects{ id, code }, percentage, quiz { name, id }
    }
  }`,
  submitSubjectWorkPercentages: gql`mutation submitSubjectWorkPercentagesQueries(
    $portions: [PortionInput]){
        submitSubjectWorkPercentages(portions: $portions) {
        assessmentId, type, percentage, subjectId
    }
  }`,
  subjectResult: gql`query getSubjectResultQuery($subjectId: Int) {
    getSubjectResult(subjectId: $subjectId){
        name,
        studentId,
        regNumber,
        email,
        phoneNumber,
        results{
            code,
            subjectId,
            averageScore,
            highestScore,
            creditUnit,
            assessments{
                score, topic, overallMark, percentage, highestScore, averageScore
            }
        }
    }
  }`,
  subjectNewsList: gql`query getSubjectNewsListQuery($subjectId: Int) {
    getSubjectNewsList(subjectId: $subjectId) {
      topic,
      id,
      description,
      fileUrl,
      teacher { id, firstName, lastName },
      subjects { code, id },
      createdAt
    }
  }`,
  categories: gql`query {
    getCategories{
        id, name
    }
  }`,
};
