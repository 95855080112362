import { graphql } from 'react-apollo';
import { assessmentQueries, subjectQueries } from '../../queries';
import { changeTimestampToDate } from '../../functions';
import { fetches, fields, getRequestData } from './helper';

export default {
  queries: (id) => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(assessmentQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(assessmentQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData: (props, { fileUrl, ...rest }) => getRequestData(
    props,
    {
      ...rest,
      fileUrl: fileUrl.size === -1
        ? new File([''], 'none.txt')
        : fileUrl
    },
    {}
  ),
  initialValues: ({ data: { getAssessment } }) => getAssessment
    ? {
      ...getAssessment,
      enforceAttendanceRule: getAssessment.enforceAttendanceRule.toString(),
      deadline: changeTimestampToDate(getAssessment.deadline, 'YYYY-MM-DD HH:mm'),
      subjects: getAssessment.subjects.map(subject => ({ ...subject, text: subject.code, actualId: subject.id })),
      quiz: getAssessment.quiz
        ? [{
          ...getAssessment.quiz,
          actualId: getAssessment.quiz.id,
          text: `${getAssessment.quiz.name}`
        }]
        : []
    }
    : {},
  success: (response, history) => { history.push(`/assessments/${response.data.updateAssessment.id}`); }
}
