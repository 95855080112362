import React from 'react';
import PropTypes from 'prop-types';
import { JitsuTableRow, JitsuTableCell, JitsuInput, JitsuIcon } from '../../components-elements/jistu';

const SubjectResult = ({ subject, onChangeScore, clickDelete }) => {
  return (<JitsuTableRow>
    <JitsuTableCell>{subject.name} {subject.code}</JitsuTableCell>
    <JitsuTableCell>
      <div className="flex">
        <JitsuInput
          onChange={onChangeScore}
          name={`${subject.id}`}
          id={`resultSubjectInput-${subject.id}`}
          value={subject.score}
          error={subject.invalid ? 'from 0-100 only' : ''}
        />
        {subject.canRemove && <JitsuIcon
          name="close"
          className="h-4 w-4 text-red-400"
          onClick={() => clickDelete(subject.id)}
        />}
      </div>
    </JitsuTableCell>
  </JitsuTableRow>);
}

SubjectResult.propTypes = {
  subject: PropTypes.object.isRequired,
  onChangeScore: PropTypes.func.isRequired,
  clickDelete: PropTypes.func.isRequired,
};

export default SubjectResult;
