import { graphql } from 'react-apollo';
import teacherQueries from '../../queries/teacher';
import { requestNameMap } from '../../constants/requests';

export default {
  queries: id => [
    graphql(teacherQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(teacherQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  dataKey: 'getTeacher',
  requestName: requestNameMap.DELETE_TEACHER,
  success: (response, history) => { history.push('/teachers'); }
};
