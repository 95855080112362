export const selectLastNotification = ({ notification: { notifications } }) => [...notifications].pop();

export const selectNotifications = ({ notification: { notifications } }) => notifications;

export const selectPageSearchText = ({ history }, page) => page && history[page] ? history[page].searchText : {};

export const selectPageSelectedItem = ({ history }, page) => page && history[page] ? history[page].selectedItem : {};

export const selectPageSelectedGroup = ({ history }, page) => page && history[page] ? history[page].selectedGroup : {};

export const selectPageSelectedMonth = ({ history }, page) => page && history[page] ? history[page].selectedMonth : {};

export const selectInitialValues = ({ history }, page) => page && history[page] ? history[page].initialValues : {};
