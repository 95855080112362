import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextEditor from '../TextEditor';

const TableLine = ({ value, label, id, editor, classname }) => value !== 0 && !value
  ? <Fragment/>
  : <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
    <dt className="text-sm leading-5 font-medium text-gray-500">
      {label}
    </dt>
    { editor
      ? <TextEditor value={value} id={id} readOnly/>
      : <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
        {value}
      </dd> }
  </div>;

TableLine.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  classname: PropTypes.string,
  editor: PropTypes.bool
};

export default TableLine;
