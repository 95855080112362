import { graphql } from 'react-apollo';
import subjectQueries from '../../queries/subjects';
import { fields, fetches, getRequestData } from './helper';
import { ANY } from '../../constants/semesterNames';

export default {
  queries: () => [
    graphql(subjectQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    }),
    graphql(subjectQueries.categories, { name: 'categories' })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: () => ({
    semester: ANY,
    name: '',
    year: '',
    creditUnit: 1,
    description: '',
    code: '',
    categories: []
  }),
  success: (response, history) => { history.push(`/subjects/${response.data.createSubject.id}`); }
}