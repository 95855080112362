import { graphql } from 'react-apollo';
import { gradeQueries } from '../../queries';
import { fields, getRequestData, updateGradeListAfterCreate } from './helper';

export default {
  queries: () => [
    graphql(gradeQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { createGrade } }) => {
            updateGradeListAfterCreate(proxy, createGrade);
          }
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  initialValues: () => ({ name: '', code: '', points: 1, topMargin: 100, bottomMargin: 0, failed: 0 }),
  success: (response, history) => { history.push(`/grades/${response.data.createGrade.id}`); }
};

