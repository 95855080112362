import { isTextEditorNotEmpty, minValue } from '../commonValidations';
import { isNumber } from '../../functions';
import { format } from 'date-fns';
import { MAX_FILE_SIZE } from '../../constants';
import { QUIZ } from '../../constants/assessmentTypes';

export default ({ topic, description, deadline, fileUrl, quiz, type }, overallMark, subjects) => ({
  topic: topic.length < 3 ? minValue(3) : null,
  description: isTextEditorNotEmpty(description) ? null : 'description can not be empty',
  overallMark: !isNumber(overallMark) || parseInt(overallMark, 10) < 1 ? 'overall mark should be more than 0' : null,
  subjects: subjects.length === 0 ? 'assessment must be for a subject' : null,
  deadline: deadline <= format(new Date(), 'YYYY-MM-DD HH:mm') ? 'deadline can not be in the past' : null,
  fileUrl: fileUrl.size > MAX_FILE_SIZE ? 'file is too large (max = 2MB)' : null,
  quiz: type === QUIZ && (quiz || []).length === 0 ? 'Quiz needs to be selected' : null
});
