import { graphql } from 'react-apollo';
import { questionQueries } from '../../queries';
import { fields, getRequestData, updateQuestionListAfterCreate } from './helper';

export default {
  queries: () => [
    graphql(questionQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { createQuestion } }) => {
            updateQuestionListAfterCreate(proxy, createQuestion);
          }
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  initialValues: () => ({ name: '', content: '', type: 'SINGLE', answers: [], tag: {} }),
  success: (response, history) => { history.push(`/questions/${response.data.createQuestion.id}`); }
};

