import { graphql } from 'react-apollo';
import { subjectQueries, classQueries, teacherQueries, collegeQueries } from '../../queries';
import { fields, fetches, getRequestData } from './helper';

export default {
  queries: id => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(teacherQueries.list, { name: 'teachers' }),
    graphql(collegeQueries.list, { name: 'colleges' }),
    graphql(classQueries.list, { name: 'classes' }),
    graphql(classQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(classQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          // update: (proxy, { data: { createSubject } }) => {
          //   updateSubjectList(proxy, createSubject);
          // }
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData,
  dataKey: 'getClass',
  initialValues: ({ data: { getClass } }) => getClass ? {
    ...getClass,
    collegeId: getClass.college ? getClass.college.id : '',
    next: !!getClass.final ? 0 : (getClass.next ? getClass.next.id : ''),
    teacher: getClass.teacher
      ? [{
        ...getClass.teacher,
        actualId: getClass.teacher.id,
        text: `${getClass.teacher.firstName} ${getClass.teacher.lastName}`
      }]
      : []
  } : {},
  success: (response, history) => { history.push(`/classes/${response.data.updateClass.id}`); }
};