import { graphql } from 'react-apollo';
import { schoolQueries } from '../../queries';
import { deleteToken, logoutUser } from '../../functions';
import { fields, getRequestData } from './helper';


export default {
  queries: id => [
    graphql(schoolQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(schoolQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  dataKey: 'getSchool',
  initialValues: ({ data: { getSchool = {} } }) => getSchool,
  success: () => {
    deleteToken();
    logoutUser();
  },
  doneButtonText: 'Edit and logout'
};
