import React from 'react';
import PropTypes from 'prop-types';
import { UsersSummaryDetails, UsersSummaryWrapper, UsersSummaryTitle } from './styled';
import { textChange } from '../../functions/textChange';


const UsersSummary = ({ data }) => <UsersSummaryWrapper style={{ borderLeft: `solid 4px ${data.fill}`}}>
  <UsersSummaryTitle>{textChange(data.name)}</UsersSummaryTitle>
  <UsersSummaryDetails>
    {data.females !== undefined && <div>Female: {data.females}</div>}
    {data.males !== undefined && <div>Male: {data.males}</div>}
    {data.females === undefined && <div>{data.value}</div>}
  </UsersSummaryDetails>
</UsersSummaryWrapper>

UsersSummary.defaultProps = {
  data: {},
};

UsersSummary.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UsersSummary;
