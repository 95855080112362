import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchAndSelect from '../SearchAndSelect';
import { SubjectsSearchAndAddDiv } from './styled';
import { JitsuButton } from '../../components-elements/jistu';
import { textChange } from '../../functions/textChange';

class SubjectsSearchAndAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubjects: []
    };
    this.selectSubject = this.selectSubject.bind(this);
    this.addSubjects = this.addSubjects.bind(this);
  }
  addSubjects() {
    this.props.addSubjects(this.state.selectedSubjects);
    this.setState({ selectedSubjects: [] });
  }
  selectSubject(value) {
    this.setState({ selectedSubjects: value });
  }
  render() {
    const { alreadySelectedSubjects, children } = this.props;
    const { selectedSubjects } = this.state;
    return <SubjectsSearchAndAddDiv>
      <SearchAndSelect
        name= "subjects"
        id= "subjects"
        textKey= "text"
        placeholder= "subjects"
        valueKey= "actualId"
        through= "subject"
        lockedOptions={alreadySelectedSubjects}
        onChange={this.selectSubject}
        value={selectedSubjects}
        label={`Select ${textChange('subjects')}`}
      />
      {children}
      <JitsuButton
        value="Add subjects"
        onClick={this.addSubjects}
        name="addSubjectsBtn"
        disabled={selectedSubjects.length === 0}
        wrapperClassname="addSubjectBtnWrapper"
      />
    </SubjectsSearchAndAddDiv>
  }
}

SubjectsSearchAndAdd.defaultProps = {
  alreadySelectedSubjects: []
};

SubjectsSearchAndAdd.propTypes = {
  alreadySelectedSubjects: PropTypes.array.isRequired,
  children: PropTypes.any,
  addSubjects: PropTypes.func.isRequired
};

export default SubjectsSearchAndAdd;