import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createMap } from '../functions';
import {
  JitsuField,
  JitsuLabel,
  JitsuMultiselectSelection,
  JitsuMultiselectToggle,
  JitsuSimpleMultiselectOptions
} from '../components-elements/jistu';
import withSearch from './withSearch';
import { ClickOutside } from '../components-elements';

const createProps = searchResult => ({
  options: searchResult || []
});

class SearchAndSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      selectionsMap: createMap(props.value, props.valueKey),
      lockedOptionsMap: createMap(props.lockedOptions, props.valueKey),
      ModifiedJitsuSimpleMultiselectOptions: withSearch(
        JitsuSimpleMultiselectOptions,
        props.through,
        props.exempt,
        props.id || props.name,
        createProps,
        0
      )
    };
    this.onSelect = this.onSelect.bind(this);
    this.toggleExpansion = this.toggleExpansion.bind(this);
    this.hideExpansion = this.hideExpansion.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(() => ({
      selectionsMap: createMap(nextProps.value, nextProps.valueKey),
      lockedOptionsMap: createMap(nextProps.lockedOptions, nextProps.valueKey)
    }));
  }
  toggleExpansion(e) {
    if (this.props.disabled) return;
    this.setState(state => ({ expanded: !state.expanded }));
  }
  hideExpansion() {
    if (this.props.disabled) return;
    this.setState(() => ({ expanded: false }));
  }
  onSelect(selected) {
    if (this.props.disabled) return;
    const { onChange, value, limit, valueKey, name, isSingleSelect } = this.props;
    const { selectionsMap, lockedOptionsMap } = this.state;
    if (lockedOptionsMap[selected[valueKey]]) {
      return;
    }
    if (isSingleSelect) {
      onChange([selected], name);
      return;
    }
    const newSelections = selectionsMap[selected[valueKey]]
      ? value.filter(selection => selection[valueKey] !== selected[valueKey])
      : value.concat(selected);
    if (newSelections.length > limit) return;
    onChange(newSelections, name);
  }
  render() {
    const { value: selection, textKey, options, valueKey, label, disabled, error, optional, tooltip, required, id } = this.props;
    const { lockedOptionsMap, expanded, selectionsMap, ModifiedJitsuSimpleMultiselectOptions } = this.state;

    return <JitsuField error={error}>
      <JitsuLabel optional={optional} tooltip={tooltip} required={required}>{label}</JitsuLabel>
      <div className="flex w-full items-center relative">
        <div className="flex flex-col items-center relative w-full">
          <ClickOutside onClickOutside={(e) => this.hideExpansion(e)} className="w-full">
            <div className="w-full">
              <div className="mb-2 p-1 flex border border-gray-200 bg-white rounded" style={{ minWidth: '300px' }}>
                <JitsuMultiselectSelection
                  selection={selection}
                  textKey={textKey}
                  valueKey={valueKey}
                  remove={this.onSelect}
                  clickSelectionArea={this.toggleExpansion}
                  id={id}
                />
                <JitsuMultiselectToggle expanded={expanded} onClick={this.toggleExpansion} disabled={disabled} id={id}/>
              </div>
              <ModifiedJitsuSimpleMultiselectOptions
                id={id}
                options={options}
                textKey={textKey}
                valueKey={valueKey}
                onClickOption={this.onSelect}
                expanded={expanded}
                lockedMap={lockedOptionsMap}
                selectionsMap={selectionsMap}
              />
            </div>
          </ClickOutside>
        </div>
      </div>
    </JitsuField>;
  }
};

SearchAndSelect.defaultProps = {
  value: [],
  showChecker: false,
  disabled: false,
  error: '',
  exempt: [],
  through: '',
  lockedOptions: [],
  isSingleSelect: false,
  onChange: () => {},
  options: [],
  classname: 'custom-select',
  wrapperClassname: '',
  id: 'custom-select',
  expanded: false,
  minimum: 3,
};

SearchAndSelect.propTypes ={
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  lockedOptions: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array.isRequired,
  showChecker: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  limit: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  isSingleSelect: PropTypes.bool.isRequired,
  maxHeight: PropTypes.string,
  through: PropTypes.string,
  exempt: PropTypes.array,
  classname: PropTypes.string.isRequired,
  wrapperClassname: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
  minimum: PropTypes.number
};

export default SearchAndSelect;