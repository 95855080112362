import React, { lazy } from 'react';
import teacherQueries from '../../queries/teacher';
import { TEXT, LIST } from '../../constants/displayTypes';
import { tpl } from '../../constants/permissions'
import { changeTimestampToDate } from '../../functions';
import { OVERSEER, SEER } from '../../constants/roles';
import { nationsMap } from '../../constants/nations';
import { textChange } from '../../functions/textChange';
import { graphql } from 'react-apollo';

const RequestToRemove = lazy(() => import('../../components-graphql/RequestToRemove'));
const UserStatusManagement = lazy(() => import('../../components-graphql/UserStatusManagement'));
const TeacherPerformance = lazy(() => import('../../components-graphql/Performances/TeacherPerformance'));
const TeacherProfile = lazy(() => import('../../components-elements/JitsuTeacherProfile'));

export default {
  InfoComponent: TeacherProfile,
  dataKey: 'getTeacher',
  query: teacherQueries.item,
  queries: (id) => [
    graphql(teacherQueries.ledSubjects, {
      name: 'ledSubjects',
      options: () => ({ variables: { teacherId: parseInt(id, 10) } }),
    }),
  ],
  fetches: [{ dataKey: 'getSubjectsLedByTeacher', name: 'ledSubjects' }],
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'First Name', text: data => data.firstName, id: 'firstName' },
    { type: TEXT, label: 'Last Name', text: data => data.lastName, id: 'lastName' },
    { type: TEXT, label: 'Reg Number', text: data => data.regNumber, id: 'regNumber' },
    { type: TEXT, label: 'Gender', text: data => data.gender, id: 'gender' },
    { type: TEXT, label: 'Date of Birth', text: data => changeTimestampToDate(data.dob, 'YYYY-MM-DD'), id: 'dob'},
    { type: TEXT, label: 'Nationality', text: data => nationsMap[data.nationality].name, id: 'nationality' },
    { type: TEXT, label: 'Role', text: data => data.role, id: 'role' },
    { type: TEXT, label: 'Email', text: data => data.email, id: 'email' },
    { type: TEXT, label: 'Phone Number', text: data => data.phoneNumber, id: 'phoneNumber' },
    { type: TEXT, label: 'Highest Qualification', text: data => data.qualification, id: 'qualification' },
    { type: TEXT, label: 'Previous Work Place', text: data => data.previousWorkPlace, id: 'previousWorkPlace' },
    { type: TEXT, label: 'Address', text: data => data.address, id: 'address' },
    { type: LIST, label: textChange('Subjects'), list: data => data.subjects.map(subject => ({ ...subject, name: subject.code })), id: 'subjects' },
  ],
  tabHeaders: ({ data: { getTeacher: teacher }, ledSubjects: { getSubjectsLedByTeacher: ledSubjects } }) => [
    teacher.role === OVERSEER || teacher.role === SEER ? {} : {
      text: 'Performance',
      value: tpl.TEACHER_PERFORMANCE,
      props: { userId: teacher.id },
      Component: TeacherPerformance
    },
    {
      text: `Remove ${textChange('subjects')}`,
      value: tpl.TEACHER_SUBJECTS_REMOVAL,
      props: {
        tablename: 'teachers',
        relatedRowId: teacher.id,
        action: 'REMOVE_SUBJECT_FROM_TEACHER',
        options: teacher.subjects,
        valueKey: 'id',
        textKey: 'code',
        lockedOptions: ledSubjects,
        refetchItem: {
          query: teacherQueries.item,
          variables: {
            id: teacher.id
          }
        }
      },
      Component: props => <RequestToRemove {...props}/>
    },
    {
      text: 'Update status',
      value: tpl.TEACHER_UPDATE_STATUS,
      props: {},
      Component: () => <UserStatusManagement userId={teacher.id} role={teacher.role} currentStatus={teacher.status}/>
    },
  ]
};
