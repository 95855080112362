import validateSubject from './validation';
import { TEXT_INPUT, MULTISELECT, TEXT_EDITOR } from '../../constants/fieldTypes';
import { commonFields } from '../commonHelpers';

export const studentTabProps = {
  dataKey: 'getSubjectResult',
  displayProperty: 'name',
  linkTo: '/students',
  linkToKey: 'studentId',
  keyProperty: 'studentId',
  useFragment: true
};

export const fields = [
  () => ({ name: 'name', type: TEXT_INPUT, label: 'Name', placeholder: 'name of subject', required: true }),
  () => ({ name: 'code', type: TEXT_INPUT, label: 'Code', placeholder: 'subject code', required: true }),
  () => ({
    name: 'description',
    type: TEXT_EDITOR,
    label: 'Brief Summary',
    placeholder: 'What is the subject description',
    required: true,
    textarea: true,
    wrapperClassname: 'full'
  }),
  () => ({ name: 'creditUnit', type: TEXT_INPUT, label: 'Credit unit', placeholder: '1', required: true }),
  commonFields.teacherSearchSingleSelect({ required: true }),
  props => ({
    name: 'categories',
    type: MULTISELECT,
    label: 'Categories',
    required: true,
    valueKey: 'id',
    textKey: 'name',
    options: (props.categories.getCategories || [])
      .map(category => ({ ...category, value: category.id, text: category.name }))
  })
];

export const fetches = [{ dataKey: 'getCategories', name: 'categories' }];

export const getRequestData = (_, { errors, creditUnit, year, teacher, semester, categories, ...rest }) => ({
  requestData: {
    ...rest,
    teacherId: teacher ? teacher[0].actualId : undefined,
    teachersIds: teacher ? [teacher[0].actualId] : [],
    creditUnit: parseInt(creditUnit, 10),
    classesIds: [],
    categoriesIds: categories.map(category => category.value)
  },
  errors: validateSubject({ creditUnit, year, teacher, semester, categories, ...rest })
});
