import { collegeQueries } from '../../queries';
import { afterCreate, afterDelete } from '../../functions/query';
import { TEXT_INPUT } from '../../constants/fieldTypes';
import { commonFields } from '../commonHelpers';
import validateCollege from './validation';
import { textChange } from '../../functions/textChange';

export const updateCollegeListAfterCreate = (proxy, college) => {
  const query = { query: collegeQueries.list };
  afterCreate(proxy, query, 'getColleges', college);
};

export const updateCollegeListAfterDelete = (proxy, college) => {
  const query = { query: collegeQueries.list };
  afterDelete(proxy, query, 'getColleges', college);
};

export const fields = [
  () => ({ name: 'name', type: TEXT_INPUT, label: 'Name', placeholder: 'Law', required: true }),
  commonFields.teacherSearchSingleSelect({ label: `Head of ${textChange('college')}`, required: true }),
];

export const getRequestData = (_, requestData) => ({
  requestData: {
    ...requestData,
    teacherId: requestData.teacher ? requestData.teacher[0].actualId : '',
  },
  errors: validateCollege(requestData)
});
