import React from 'react';
import PropTypes from 'prop-types';

const JitsuTabBody = ({ onScreen, children, className }) => <div
  className={`${onScreen ? 'block' : 'hidden'} ${className} bg-white overflow-auto`} style={{ height: 'calc(100% - 45px)' }}>
  {children}
</div>

JitsuTabBody.defaultProps = {
  className: '',
  children: <div></div>
};

JitsuTabBody.propTypes = {
  onScreen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default JitsuTabBody;