import gql from 'graphql-tag';

export default {
  getTranscript: gql`query getTranscriptQuery($studentId: Int) {
    getTranscript(studentId: $studentId){
      cgpa
      studentData{
        name,
        college,
        programme,
        regNumber,
        gender
      }
      semesterResults{
        subjectResults{
          code,
          name,
          status,
          unit,
          gradePoint,
          grade,
          score
        }
        totalCreditUnit,
        totalGradePoint,
        gpa,
        semesterName,
        semesterStartDate,
        semesterEndDate,
        semesterYear
      }
    }
  }`,
  getStudentPositionInClass: gql`query getStudentPositionInClassQuery($studentId: Int) {
    getStudentPositionInClass(studentId: $studentId){
      position, noOfMates,
    }
  }`, 
  getStudentPerformance: gql`query getStudentPerformanceQuery($studentId: Int, $semesterId: Int) {
    getStudentPerformance(studentId: $studentId, semesterId: $semesterId){
      categoryScores{
        name, score, noOfSubjects
      }
      results{
        averageScore,
        lowestScore,
        highestScore,
        subject{ code, id, name }
        score{
          summary { 
            percentageTotal,
            percentageScoreTotal,
            overallMarkTotal,
            numberOfAssessments,
            totalScore
          },
          details {
            score,
            perentageScore,
            percentage,
            overallMark,
            topic,
            assessmentId,
          }
        }
      }
    }
  }`,
  getTeacherPerformance: gql`query getTeacherPerformanceQuery($teacherId: Int, $semesterId: Int) {
    getTeacherPerformance(teacherId: $teacherId, semesterId: $semesterId){
      categoryScores{
        name, score, noOfSubjects
      }
      results{
        averageScore,
        lowestScore,
        highestScore,
        subject{ code, id, name }
        scores{
          student{
            id, firstName, lastName
          }
          summary { 
            percentageTotal,
            percentageScoreTotal,
            overallMarkTotal,
            numberOfAssessments,
            totalScore
          },
          details {
            score,
            perentageScore,
            percentage,
            overallMark,
            topic,
            assessmentId,
          }
        }
      }
    }
  }`,
  getStudentSubjectPerformance: gql`query getStudentSubjectPerformanceQuery($studentId: Int, $semesterId: Int, $subjectId: Int) {
    getStudentSubjectPerformance(studentId: $studentId, semesterId: $semesterId, subjectId: $subjectId){
      averageScore,
      lowestScore,
      highestScore,
      score{
        summary { 
          percentageTotal,
          percentageScoreTotal,
          overallMarkTotal,
          numberOfAssessments,
          totalScore
        },
        details {
          score,
          perentageScore,
          percentage,
          overallMark,
          topic,
          assessmentId,
        }
      }
    }
  }`,
  getSubjectPerformance: gql`query getSubjectPerformanceQuery($subjectId: Int, $semesterId: Int) {
    getSubjectPerformance(subjectId: $subjectId, semesterId: $semesterId){
      averageScore,
      lowestScore,
      highestScore,
      scores{
        student { firstName, id, lastName },
        summary { 
          percentageTotal,
          percentageScoreTotal,
          overallMarkTotal,
          numberOfAssessments,
          totalScore
        },
        details {
          score,
          perentageScore,
          percentage,
          overallMark,
          topic,
          assessmentId,
        }
      }
    }
  }`,
  classTranscript: gql`query getClassTranscriptsQuery($semesterId: Int, $classId: Int, $level: String) {
    getClassTranscripts(semesterId: $semesterId, classId: $classId, level: $level){
      cgpa,
      cgp,
      cumulativeTotalCreditUnit,
      cumulativeTotalGradePoint
      studentData{
        name,
        college,
        programme,
        regNumber,
        gender
      }
      semesterResults{
        subjectResults{
          code,
          name,
          status,
          unit,
          gradePoint,
          gradeEquivalent,
          grade,
          score
        }
        totalCreditUnit,
        totalGradePoint,
        gpa,
        semesterName,
        semesterStartDate,
        semesterEndDate,
        semesterYear
      }
    }
  }`, 
}
