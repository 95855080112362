import React, { Suspense, Fragment } from 'react';
import PropTypes from 'prop-types';

const SuspenseComponent = ({ children, Fallback })=> <Suspense fallback={<Fallback/>}>
  {children}
</Suspense>

SuspenseComponent.defaultProps = {
  Fallback: () => <Fragment/>
}

SuspenseComponent.propTypes = {
  children: PropTypes.any.isRequired,
  Fallback: PropTypes.func.isRequired
};

export default SuspenseComponent;