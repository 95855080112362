import gql from 'graphql-tag';

const body = `
  id,
  type,
  duration,
  description,
  name,
  questions{ id, name }
`;

const declaration = `
  $name: String!,
  $type: String!,
  $description: String!,
  $duration: Int!,
  $questionsIds: [Int]
`;

const initilisation = `
name: $name,
type: $type,
duration: $duration,
description: $description,
questionsIds: $questionsIds,
`;

export default {
  list: gql`query {
    getQuizzes{
      ${body}
    }
  }`,
  item: gql`query getQuizQuery($id: Int) {
    getQuiz(id: $id){
      ${body}
    }
  }`,
  itemNoQuestions: gql`query getAssessmentQuizQuery($assessmentId: Int, $starting: Int) {
    getAssessmentQuiz(assessmentId: $assessmentId, starting: $starting){
      id,
      type,
      duration,
      description,
      name,
    }
  }`,
  itemWithQuestionsAndAnswers: gql`query getAssessmentQuizQuery($assessmentId: Int, $starting: Int) {
    getAssessmentQuiz(assessmentId: $assessmentId, starting: $starting){
      id,
      duration,
      name,
      questions{
        id,
        name,
        content,
        type,
        answers{
          id, content
        }
      }
    }
  }`,
  update: gql`mutation updateQuizQuery(
    $id: Int,
    ${declaration}) {
        updateQuiz(
            id: $id,
            ${initilisation}){
                ${body}
        }
    }
  `,
  create: gql`mutation createQuizQuery(
    ${declaration}) {
        createQuiz(
            ${initilisation}){
                ${body}
        }
    }
  `,
  wipe: gql`mutation deleteQuizQuery($id: Int) {
    deleteQuiz(id: $id){
        id
    }
  }`,
  markQuiz: gql`mutation markQuizQuery($questionsAndAnswers: [QuestionAndAnswersInput], $assessmentId: Int) {
    markQuiz(questionsAndAnswers: $questionsAndAnswers, assessmentId: $assessmentId){
        status
    }
  }`
}
