import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { selectNotifications } from '../../selectors';
import * as actions from '../../actions';
import { NotificationListItem, ToastrDiv } from './notificationStyles';
import { SUCCESS, CRITICAL } from '../../constants/notificationTypes';
import { JitsuIcon } from '../../components-elements/jistu';

const toastColorsMap = { 
  [SUCCESS]: 'bg-green-500',
  [CRITICAL]: 'bg-red-500'
};

class Notification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      onScreen: false,
      closedBroadcasts: {}
    };
    this.timeout = undefined;
    this.clearNotification = this.clearNotification.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ notifications }) {
    clearTimeout(this.timeout);
    if (notifications.length > 0) {
      this.setState({ onScreen: true });
      this.timeout = setTimeout(this.clearNotification, 3000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  clearNotification() {
    this.setState(() => ({ onScreen: false }));
    this.props.clearNotifications();
  }

  render() {
    const { onScreen } = this.state;
    const { notifications } = this.props;
    return <ToastrDiv role="alert" className={`toastr ${onScreen ? 'on-screen' : 'off-screen'}`}>
      <ul>
        {
          notifications
            .map((notification, index) => {
              const toastColor = toastColorsMap[notification.type] || 'bg-blue-500'
              return( 
                <NotificationListItem key={index} className={`flex items-center ${toastColor} text-white text-sm font-bold px-4 py-3 rounded mb-2`} role="alert">
                  <JitsuIcon name="info-solid" className="fill-current w-4 h-4 mr-2" />    
                  <p>{notification.text}</p>
                </NotificationListItem>
              )}
            )
        }
      </ul>
    </ToastrDiv>
  }
}

Notification.propTypes = {
  notifications: PropTypes.array.isRequired,
  clearNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notifications: selectNotifications(state)
});

const mapActionsToProps = dispatch => ({
  clearNotifications: compose(dispatch, actions.clearNotifications)
});

// export default graphql(newsQueries.broadcasts)(dataFetchingComponent(connect(mapStateToProps, mapActionsToProps)(Notification)));
export default connect(mapStateToProps, mapActionsToProps)(Notification);