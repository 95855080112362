import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import JitsuIcon from './JitsuIcon';

const JitsuMultiselectSelection = ({ selection, textKey, valueKey, remove, clickSelectionArea, id }) => {
  return (
    <div className="flex flex-auto flex-wrap" id={`${id}_selection`}>
      {selection.length === 0
        ? <div className="pt-2 w-full cursor-pointer" onClick={clickSelectionArea} id={`${id}_selectOption`}>
          Select option
        </div>
        : <Fragment>
          {
            selection.map((selected) => <div
              key={selected[valueKey]}
              id={`${id}_${selected[valueKey]}_selection`}
              className="flex justify-center items-center m-1 font-medium py-1 
              px-2 bg-white rounded-full text-blue-700 bg-blue-100 border border-blue-300"
            >
              <div className="text-xs font-normal leading-none max-w-full flex-initial">
                {selected[textKey]}
              </div>
              <div className="flex flex-auto flex-row-reverse ml-1">
                <JitsuIcon
                  name="close"
                  className="h-4 w-4 fill-current text-blue-600 hover:text-blue-700-mb-5 ml-1 z-10 cursor-pointer"
                  onClick={() => remove(selected)}
                  id={`${id}_${selected[valueKey]}_selection_remove`}
                />
              </div>
            </div>)
          }
        </Fragment>
      }
    </div>
  );
};

JitsuMultiselectSelection.defaultProps = {
  selection: [],
  textKey: '',
  id: '',
  valueKey: '',
  remove: () => {},
  clickSelectionArea: () => {}
};

JitsuMultiselectSelection.propTypes = {
  selection: PropTypes.array.isRequired,
  textKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  clickSelectionArea: PropTypes.func.isRequired
};

export default JitsuMultiselectSelection;