import { graphql } from 'react-apollo';
import dateFns from 'date-fns';
import { periodQueries, subjectQueries } from '../../queries';
import { getRequestData, fetches, fields } from './helper';
import { SELECT_INPUT } from '../../constants/fieldTypes';

export default {
  queries: () => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(periodQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          refetchQueries: [{ query: periodQueries.list }]
        })
      })
    })
  ],
  fields: [...fields, () => ({
    name: 'type',
    type: SELECT_INPUT,
    label: 'Type',
    ignoreFirstOption: true,
    valueKey: 'value',
    options: [{ value: 'TIMETABLE', text: 'Timetable' }, { value: 'EVENT', text: 'Event (one off)' }],
    tooltip: '"Timetable" would repeat weekly on the same day until end of the semester'
  })],
  fetches,
  getRequestData,
  initialValues: () => ({
    name: '',
    startTime: dateFns.format(new Date(), 'HH:mm'),
    date: dateFns.format(new Date(), 'YYYY-MM-DD'),
    endTime: dateFns.format(new Date(), 'HH:mm'),
    type: 'EVENT',
    subjectId: '',
    notes: []
  }),
  success: (response, history) => { history.push(`/periods/${response.data.createPeriod.id}`); }
};
