import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import otherQueries from '../queries/others';

class Searcher extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.props.setSearchResults(nextProps.data.search);
    }
  }

  render() {
    return <Fragment/>
  }
}

Searcher.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  data: PropTypes.object,
  setSearchResults: PropTypes.func.isRequired,
}

export default graphql(
  otherQueries.search,
  {
    options: ({ through, text, exempt }) => ({
      variables: { through, text, exempt }
    }),
    skip: ({ minimum, text = '' }) => text.length < minimum
  })(Searcher);