import { minValue } from '../commonValidations';

const validURL = (str = '') => {
  if (!(str.includes('http://') || str.includes('https://'))) {
    return false;
  }
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

const validateWebsite = (website) => {
  if (website === '#' || validURL(website)) {
    return null;
  }
  return 'invalid url';
}

export default ({ name, address, website }) => ({
  name: name.length < 3 ? minValue(3) : null,
  address: address.length < 3 ? minValue(3) : null,
  website: validateWebsite(website)
});
