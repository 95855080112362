import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import flowRight from "lodash.flowright";

import { TEXT_INPUT, SELECT_INPUT, MULTISELECT, SEARCH_MULTISELECT, DATE_INPUT, UPLOAD_INPUT, TEXT_EDITOR } from '../constants/fieldTypes';
import * as controllers from '../controllers';
import { dataFetchingComponent, withNotification, withUser, withPageHistory } from '.';
import { NotFound, TextEditor } from '../components-elements';
import { permissions } from '../constants/permissions';
import { SUCCESS, CRITICAL } from '../constants/notificationTypes';
import { requestQueries } from '../queries'
import { handleError, getCurrentUser } from '../functions';

import {
  JitsuInput,
  JitsuSelect,
  JitsuUpload,
  JitsuButton,
  JitsuDateInput,
  JitsuSimpleMultiselect
} from '../components-elements/jistu';
import SearchAndSelect from '../components-graphql/SearchAndSelect';
import TagInput from '../components-graphql/TagInput';
import Answers from '../components-elements/Answers';
import { FormWrapperDiv } from './formStyles';

const errorExists = errors => Object.values(errors).some(error => error);
const FormField = (props) => {
  switch (props.type) {
  case TEXT_INPUT:
    return <JitsuInput {...props} id={props.name}/>;
  case TEXT_EDITOR:
    return <TextEditor {...props} id={props.name}/>;
  case SELECT_INPUT:
    return <JitsuSelect {...props} id={props.name}/>;
  case MULTISELECT:
    return <JitsuSimpleMultiselect {...props} id={props.name}/>;
  case SEARCH_MULTISELECT:
    return <SearchAndSelect {...props} id={props.name}/>;
  case DATE_INPUT:
    return <JitsuDateInput {...props} id={props.name}/>;
  case UPLOAD_INPUT:
    return <JitsuUpload {...props} id={props.name}/>
  case 'ANSWERS': // special case for question Answers
      return <Answers {...props} id={props.name}/>
  case 'TAG_INPUT':
    return <TagInput {...props} id={props.name}/>
  default:
    return <Fragment/>;
  }
};

const createForm = (page, id, history, isUpdating, permissionLevel) => {
  const permits = permissions[page][getCurrentUser().userRole];
  if (!permits.includes(permissionLevel)) return () => <NotFound/>;
  const {
    fields, initialValues, getRequestData, success, queries, dataKey, fetches, isRequestNeeded, doneButtonText, watcher,
  } = isUpdating ? controllers[page].update : controllers[page].create;
  class Form extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ...initialValues(props),
        isRequesting: false,
        errors: {}
      };
      this.onChange = this.onChange.bind(this);
      this.sendRequest = this.sendRequest.bind(this);
    }
    componentDidMount() {
      const { onItemSelected, data } = this.props;
      if (onItemSelected && data && data[dataKey]) {
        onItemSelected(page, data[dataKey]);
      }
    }
    onChange(value, name) {
      const additionalChange = watcher ? watcher(value, name, this) : {};
      this.setState(state => ({ [name]: value, errors: { ...state.errors, [name]: null }, ...additionalChange }));
    }
    stopRequesting() {
      this.setState(() => ({ isRequesting: false }));
    }
    resetForm() {
      this.setState(() => ({ isRequesting: false, ...initialValues(this.props) }));
    }
    async sendRequest() {
      const { requestData, errors } = getRequestData(this.props, this.state);
      const { sendRequest, addNotifications, request } = this.props;
      if (!errorExists(errors)) {
        this.setState(() => ({ isRequesting: true }));
        try {
          let data = {};
          if (isRequestNeeded) {
            data = await request({ vals: JSON.stringify(requestData) });
          } else {
            data = await sendRequest(requestData);
          }
          addNotifications({ text: 'Request was successful', type: SUCCESS });
          this.resetForm();
          success(data, history, id, isRequestNeeded);
        } catch (e) {
          this.stopRequesting();
          handleError(e, () => addNotifications({ text: 'Request Failed', type: CRITICAL }));
        }
      } else {
        this.setState(() => ({ errors }));
      }
    }
    render() {
      const { data } = this.props;
      return (dataKey && !data[dataKey])
        ? <NotFound/>
        : <FormWrapperDiv className={`
          ${page}FormWrapper ${isUpdating ? 'updatingForm' : 'creatingForm'} 
          w-full h-full overflow-auto p-8 bg-white
        `}>
        {
          fields.map((fieldFcn) => {
            const field = fieldFcn(this.props, this.state, isUpdating);
            if (!field) return null;
            return <FormField
              key={field.name}
              value={this.state[field.name]}
              onChange={this.onChange}
              error={this.state.errors[field.name]}
              wrapperClassname={`${page}--${field.name}`}
              {...field}
            />;
          })
        }
        <div className="form-buttons flex space-x-3 mt-4">
          <JitsuButton
            loading={this.state.isRequesting}
            onClick={this.sendRequest}
            value={doneButtonText || (isRequestNeeded ? 'Request' : 'Done')}
            name={`${page}RequestBtn`}
          />
          <JitsuButton
            disabled={this.state.isRequesting}
            onClick={() => { history.push(`/${page}${isUpdating ? `/${id}` : ''}`); }}
            value="Cancel"
            name={`${page}Cancel`}
          />
        </div>
      </FormWrapperDiv>;
    }
  }
  return withUser(flowRight(...queries(id), graphql(requestQueries.create, {
    props: ({ mutate, ownProps: { user: { userId } } }) => ({
      request: variables => mutate({
        variables: {
          ...variables,
          tablename: page,
          relatedRowId: parseInt(id, 10),
          action: isUpdating ? 'UPDATE' : 'CREATE',
          reason: '',
          requestedBy: userId
        }
      })
    })
  }))(withNotification(dataFetchingComponent(withPageHistory(Form, page), fetches))));
};

export default createForm;