import { textChange } from "../functions/textChange";

const ROUTES = {
  dashboard: 'Dashboard',
  profile: 'Personal details',
  settings: 'Settings',
  students: 'Students',
  teachers: `${textChange('Teacher')}`,
  parents: 'Parents',
  assessments: 'Assessments',
  notes: `${textChange('Notes')}`,
  requests: 'Requests for Approval',
  subjects: `${textChange('Subjects')}`,
  news: 'Notice board',
  classes: `${textChange('Classes')}`,
  periods: 'My calendar',
  performance: 'Performance',
  registration: `${textChange('Subject')} registration`,
  grades: 'Grading System',
  schools: 'Schools',
  transcript: 'Transcripts',
  quizzes: 'Quizzes',
  questions: 'Questions',
  qa: 'Quiz',
  colleges: `${textChange('Colleges')}`
};

export {
  ROUTES
};