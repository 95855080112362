import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import JitsuTooltip from './JitsuTooltip';

const SwitchDiv = styled.div`
/* REF: https://www.w3schools.com/howto/howto_css_switch.asp */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12.5px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #38B2AC;
}

input:focus + .slider {
  box-shadow: 0 0 1px #38B2AC;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
`;

const JitsuSwitch = ({ wrapperClassName, value, name, onChange, label, id, tooltipText, toolTipPosition }) => {
  return (
    <div className={`${wrapperClassName} flex space-between w-full`}>
      {label && <div className="flex items-center w-full">
        <label htmlFor={name}>{label}</label>
        {tooltipText && <JitsuTooltip tooltipText={tooltipText} position={toolTipPosition} />}
      </div>}
      <div className="flex items-center">
        <SwitchDiv className="pt-2">
          <label className="switch">
            <input type="checkbox" defaultChecked={value === 1} onClick={() => { onChange(value === 1 ? 0 : 1, name); }} id={id || name} />
            <span className="slider round"></span>
          </label>
        </SwitchDiv>
      </div>
    </div>
  )
}

JitsuSwitch.defaultProps = {
  wrapperClassName: '',
  label: ''
};

JitsuSwitch.propTypes = {
  wrapperClassName:  PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  toolTipPosition: PropTypes.string,
  id: PropTypes.string,
}

export default JitsuSwitch;
