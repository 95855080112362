import { graphql } from 'react-apollo';
import { noteQueries, subjectQueries } from '../../queries';
import { fields, getRequestData, fetches } from './helper';
import { afterCreate } from '../../functions/query';
import { getCalenderQueries } from '../commonHelpers';

export default {
  queries: () => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(noteQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update(cache, { data }) {
            const { all, month } = getCalenderQueries(noteQueries.listByCreatedAt, data.createNote.createdAt);
            afterCreate(cache, all, 'getNotesByCreatedAt', data.createNote);
            afterCreate(cache, month, 'getNotesByCreatedAt', data.createNote);
          }
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: () => ({
    topic: '',
    type: 'PDF',
    description: '',
    fileUrl: '',
    subjects: []
  }),
  success: (response, history) => { history.push(`/notes/${response.data.createNote.id}`); }
};
