export const requestActions = {
  DELETE: 'delete',
  REMOVE_SUBJECT_FROM_TEACHER: 'remove subjects from teacher\'s taught-subjects',
  REMOVE_SUBJECT_FROM_STUDENT: 'remove subjects from student\'s offered-subjects',
  UPDATE: 'update',
  REMOVE_SUBJECT_FROM_CLASS: 'remove subjects from class',
  REMOVE_CLASS_FROM_SUBJECT: 'remove classes from subject',
  REMOVE_KIDS_FROM_PARENT: 'remove kids from parent'
};

export const requestActionsWithTableName = {
  DELETE_CLASSES: 'DELETE_CLASSES',
  DELETE_COLLEGES: 'DELETE_COLLEGES',
  DELETE_TEACHERS: 'DELETE_TEACHERS',
  DELETE_PARENTS: 'DELETE_PARENTS',
  DELETE_STUDENTS: 'DELETE_STUDENTS',
  DELETE_SUBJECTS: 'DELETE_SUBJECTS',
  REMOVE_SUBJECT_FROM_TEACHER_TEACHERS: 'REMOVE_SUBJECT_FROM_TEACHER_TEACHERS',
  REMOVE_SUBJECT_FROM_STUDENT_STUDENTS: 'REMOVE_SUBJECT_FROM_STUDENT_STUDENTS',
  REMOVE_CLASS_FROM_SUBJECT_SUBJECTS: 'REMOVE_CLASS_FROM_SUBJECT_SUBJECTS',
  REMOVE_SUBJECT_FROM_CLASS_CLASSES: 'REMOVE_SUBJECT_FROM_CLASS_CLASSES',
  REMOVE_KIDS_FROM_PARENT: 'REMOVE_KIDS_FROM_PARENT_PARENTS',
  UPDATE_SCHOOLS: 'UPDATE_SCHOOLS'
}

export const requestNameMap = {
  ALLOW_ADMIN_TO_EDIT_SEMESTER: 'allowAdminToEditSemester',
  DELETE_STUDENT: 'requestToDeleteStudent',
  DELETE_PARENT: 'requestToDeleteParent',
  DELETE_TEACHER: 'requestToDeleteTeacher',
  DELETE_CLASS: 'requestToDeleteClass',
  DELETE_COLLEGE: 'requestToDeleteCollege',
  DELETE_SUBJECT: 'requestToDeleteSubject',
  REMOVE_SUBJECT_FROM_TEACHER: 'requestToRemoveTeacherSubject',
  REMOVE_SUBJECT_FROM_STUDENT: 'requestToRemoveStudentSubject',
  REMOVE_SUBJECT_FROM_CLASS: 'requestToRemoveClassSubject',
  REMOVE_KIDS_FROM_PARENT: 'requestToRemoveParentKids',
};