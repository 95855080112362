const isTextJsonParsable = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return true;
    }
  }
  catch (e) { }
  return false;
};
export {
  isTextJsonParsable,
};
