import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { JitsuSwitch, JitsuButton } from '../jistu';
import TextEditor from '../TextEditor';

const WrapperDiv = styled.div`
  .dnwRos .RichEditor-editor .public-DraftEditor-content{
    min-height: auto !important;
  }
`;

class AnswerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.answer.content || '',
      correct: props.answer.correct || 0,
      answersMap: (props.value || []).reduce((acc, curr, index) => ({
        ...acc,
        [curr.id || `id-${index}`]: curr
      }), {})
    }
    this.onChange = this.onChange.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
  }
  onChange(value, name) {
    this.setState({ [name]: value });
  }
  addAnswer() {
    const { onChange, value, closeModal, answer, index } = this.props;
    if (answer.content === undefined) { 
      onChange([...value, { content: this.state.value, correct: this.state.correct }], 'answers');
    } else {
      const updatedAnswers = Object.values({
        ...this.state.answersMap,
        [answer.id || `id-${index}`]: {
          id: answer.id,
          content: this.state.value,
          correct: this.state.correct,
        }
      });
      onChange(updatedAnswers, 'answers');
    }
    closeModal();
  }
  render() {
    const { value, correct } = this.state;
    const { index, answer } = this.props;
    return <WrapperDiv className="p-8">
      <TextEditor
        label="Content"
        onChange={this.onChange}
        value={value}
        name="value"
      />
      <div>
        <div>Is this the correct answer?</div>
        <JitsuSwitch value={correct} onChange={this.onChange} name="correct" />
      </div>
      <div className="mt-8">
        <JitsuButton
          onClick={this.addAnswer}
          value={(answer.id || index > -1) ? 'Change answer' : 'Add answer'}
        />
      </div>
    </WrapperDiv>;
  }
}

AnswerForm.defaultProps = {
  answer: {},
  onChange: () => {},
  closeModal: () => {},
  value: [],
  index: -1
};

AnswerForm.propTypes = {
  answer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired
}

export default AnswerForm;
