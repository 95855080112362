import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ColumnOneHeader, InfoListWrapper, EmptyInfoListItemLi, NestedInfoListUl, NextInfoListItemLi } from './styled';
import JitsuLabel from '../JitsuLabel';

const InfoList = ({ 
  header, list, displayProperty, keyProperty, label, listMaxHeight, linkTo, id, classname
}) => <InfoListWrapper className={classname}>
  {label && <JitsuLabel>{label}</JitsuLabel>}
  <div id={id}>
    {header && <ColumnOneHeader>{header}</ColumnOneHeader>}
    {list.length === 0
      ? <EmptyInfoListItemLi className="empty">None</EmptyInfoListItemLi>
      : <NestedInfoListUl style={{ maxHeight:  listMaxHeight }}>
        {
          list.map((item, index) => <NextInfoListItemLi key={item[keyProperty]}>
            {index + 1}. {linkTo ? <Link to={`/${linkTo}/${item[keyProperty]}`}>{item[displayProperty]}</Link> : item[displayProperty]}
          </NextInfoListItemLi>)
        }
      </NestedInfoListUl>}
  </div>
</InfoListWrapper>;

InfoList.defaultProps = {
  listMaxHeight: '200px',
};

InfoList.propTypes = {
  header: PropTypes.string,
  classname: PropTypes.string,
  label: PropTypes.string,
  keyProperty: PropTypes.string.isRequired,
  displayProperty: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  linkTo: PropTypes.string,
  listMaxHeight: PropTypes.string
};

export default InfoList;