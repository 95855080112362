import styled from 'styled-components';
import { GOLD, GREEN } from '../../constants/colors'

export const InitialsSpan = styled.span`
  font-size: 25px;
  color: ${GOLD};
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  max-width: 100px;
  display: flex;
  align-items: center;
`;

export const ShortTextDiv = styled.div`
  &.shortened-text--expanded{
    span:last-of-type{
      display: block;
    }
    span:first-of-type{
      display: none;
      color: ${GREEN};
    }
  }
  &.shortened-text--collapsed{
    span:last-of-type{
      display: none;
    }
    span:first-of-type{
      display: block;
    }
  }
`;

export const ShortTextSpan = styled.span`
`;

export const FullTextSpan = styled.span`
`;
