import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ value, name, onChange, label, id }) => (<div>
  <input type="checkbox" defaultChecked={value === 1} onClick={() => { onChange(value === 1 ? 0 : 1, name); }} id={id || name}/>
  {label && <label htmlFor={name}>{label}</label>}
</div>);

Checkbox.defaultProps = {
  value: 0,
};

Checkbox.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string.isRequired
};

export default Checkbox;