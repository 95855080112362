import React, { lazy } from 'react';
import { studentQueries } from '../../queries'
import { tpl } from '../../constants/permissions';

import { TEXT, LIST } from '../../constants/displayTypes';
import { changeTimestampToDate, getCurrentUser } from '../../functions';
import PastResultsManager from '../../components-graphql/PastResultsManager';
import { ADMIN, OVERSEER, PARENT } from '../../constants/roles';
import { nationsMap } from '../../constants/nations';
import { textChange } from '../../functions/textChange';
import { TERTIARY } from '../../constants/schoolTypes';

const RequestToRemove = lazy(() => import('../../components-graphql/RequestToRemove'));
const StudentSubjects = lazy(() => import('../../components-elements/StudentSubjects'));
const AttendanceRecord = lazy(() => import('../../components-graphql/AttendanceRecord'));
const UserStatusManagement = lazy(() => import('../../components-graphql/UserStatusManagement'));
const StudentPerformance = lazy(() => import('../../components-graphql/Performances/StudentPerformance'));
const StudentProfile = lazy(() => import('../../components-elements/JitsuStudentProfile'));

export default {
  InfoComponent: StudentProfile,
  dataKey: 'getStudent',
  query: studentQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'First name', text: data => data.firstName, id: 'firstName' },
    { type: TEXT, label: 'Last name', text: data => data.lastName, id: 'lastName' },
    { type: TEXT, label: 'Reg number', text: data => data.regNumber, id: 'regNumber' },
    { type: TEXT, label: 'Programme', text: data => data.programme, id: 'programme' },
    getCurrentUser().schoolType === TERTIARY
      ? { type: TEXT, label: 'Current academic year', text: data => data.level, id: 'level' }
      : null,
    {
      type: TEXT,
      label: 'Address',
      text: data => `${data.address} ${data.stateOfResidence} ${nationsMap[data.countryOfResidence] ? nationsMap[data.countryOfResidence].name: ''}`,
      id: 'address'
    },
    { type: TEXT, label: 'Gender', text: data => data.gender, id: 'gender' },
    { type: TEXT, label: 'Date of birth', text: data => changeTimestampToDate(data.dob, 'YYYY-MM-DD'), id: 'dob' },
    { type: TEXT, label: 'Nationality', text: data => nationsMap[data.nationality].name, id: 'nationality' },
    { type: TEXT, label: 'E-mail', text: data => data.email, id: 'email' },
    { type: TEXT, label: 'Phone number', text: data => data.phoneNumber, id: 'phoneNumber' },
    { type: TEXT, label: textChange('Class'), text: data => data.class ? data.class.name : 'Unknown', id: 'class' },
    { type: TEXT, label: 'Previous school/uni', text: data => data.previousSchool, id: 'previousSchool' },
    { type: LIST, label: 'Parents', list: data => data.parents.map(parent => ({ ...parent, name: `${parent.firstName} ${parent.lastName}` })), id: 'parents' },
    {
      type: LIST,
      label: textChange('Subjects'),
      list: data => data.subjects.map(subject => ({ ...subject, name: subject.code })),
      id: 'subjects' },
  ],
  tabHeaders: ({ data: { getStudent: student }, user }) => [
    {
      text: 'Performance',
      value: tpl.STUDENT_PERFORMANCE,
      props: { userId: student.id },
      Component: StudentPerformance
    },
    {
      text: `${textChange('Subjects')}`,
      value: tpl.STUDENT_SUBJECTS_REMOVAL,
      props: {
        student,
        tablename: 'students',
        relatedRowId: student.id,
        action: 'REMOVE_SUBJECT_FROM_STUDENT',
        options: student.subjects,
        valueKey: 'id',
        textKey: 'code',
        refetchItem: {
          query: studentQueries.item,
          variables: {
            id: student.id
          }
        }
      },
      Component: props => <div>
        {[ADMIN, OVERSEER].includes(user.userRole) && <RequestToRemove {...props} label={`Select ${textChange('subjects')} to remove`}/>}
          <StudentSubjects studentId={student.id} />
        </div>
    },
    {
      text: 'Update status',
      value: tpl.STUDENT_UPDATE_STATUS,
      props: {},
      Component: () => <UserStatusManagement userId={student.id} role={student.role} currentStatus={student.status}/>
    },

    {
      text: 'Attendance record',
      value: tpl.STUDENT_ATTENDANCE_RECORD,
      props: { studentId: student.id },
      Component: AttendanceRecord
    },
    {
      text: 'Manage past results',
      value: tpl.STUDENT_RESULT_MANAGEMENT,
      props: { userId: student.id },
      Component: PastResultsManager
    },
  ],
  isAuthorized: (user, id) => {
    if (user.userRole === PARENT) return user.kidsIds.includes(parseInt(id, 10));
    return true;
  }
};