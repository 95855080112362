import { graphql } from 'react-apollo';
import { quizQueries } from '../../queries';
import { updateQuizListAfterDelete } from './helper';

export default {
  queries: id => [
    graphql(quizQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(quizQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { deleteQuiz } }) => {
            updateQuizListAfterDelete(proxy, deleteQuiz);
          }
        })
      })
    })
  ],
  dataKey: 'getQuiz',
  success: (_, history) => { history.push('/quizzes'); }
};