// notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

// history
export const ITEM_SELECTED = 'SELECT_ITEM';
export const GROUP_SELECTED = 'GROUP_SELECTED';
export const MONTH_SELECTED = 'SELECT_MONTH';
export const SEARCH_RESULTED = 'SEARCH_RESULTED';
export const TAB_SELECTED = 'SELECT_TAB';
export const ON_SET_INITIAL_VALUES_FOR_CREATE_FORM = 'ON_SET_INITIAL_VALUES_FOR_CREATE_FORM';
