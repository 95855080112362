import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

const Item = ({ text, linkTo, id }) => <div className={`mb-2 text-gray-600 border-l-2 border-gray-500 rounded pl-2 h-12`}>
  <NavLink to={linkTo} activeClassName="text-blue-500">
    <div id={`${linkTo.split('/')[1]}Item-${id}`}>
      {text}
    </div>
  </NavLink>
</div>;

Item.propTypes = {
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(Item);