import { graphql } from 'react-apollo';
import { gradeQueries } from '../../queries';
import { fields, getRequestData } from './helper';

export default {
  queries: id => [
    graphql(gradeQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(gradeQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  dataKey: 'getGrade',
  initialValues: ({ data: { getGrade } }) => getGrade || {},
  success: (response, history) => { history.push(`/grades/${response.data.updateGrade.id}`); }
};

