import { graphql } from 'react-apollo';
import { noteQueries, subjectQueries } from '../../queries';
import { fields, getRequestData, fetches } from './helper';

export default {
  queries: (id) => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(noteQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(noteQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData: (props, { fileUrl, ...rest }) => getRequestData(
    props,
    {
      ...rest,
      fileUrl: !fileUrl || fileUrl.size === -1
        ? new File([''], 'none.txt')
        : fileUrl
    },
    {}
  ),
  initialValues: ({ data: { getNote = {} } }) =>  ({
    ...getNote,
    subjects: getNote.subjects.map(subject => ({ ...subject, actualId: subject.id, text: subject.code }))
  }),
  success: (response, history) => { history.push(`/notes/${response.data.updateNote.id}`); }
}
