import { gradeQueries } from '../../queries';
import { TEXT } from '../../constants/displayTypes';

export default {
  dataKey: 'getGrade',
  query: gradeQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Name', text: data => data.name, id: 'name' },
    { type: TEXT, label: 'Code', text: data => data.code, id: 'code' },
    { type: TEXT, label: 'Points', text: data => data.points, id: 'points' },
    { type: TEXT, label: 'Top margin', text: data => data.topMargin, id: 'topMargin' },
    { type: TEXT, label: 'Bottom margin', text: data => data.bottomMargin, id: 'bottomMargin' },
    { type: TEXT, label: 'Grade as fail', text: data => data.failed ? 'Yes' : 'No', id: 'failed' }
  ]
};
