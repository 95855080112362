import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SpinnerDiv = styled.div`
  &.label {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }

  @-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const sizeMap = {
  xs: 4,
  s: 8,
  n: 16
};

const JitsuSpinner = ({ size, className }) =>
  <SpinnerDiv className={`
    ${className}
    label
    ease-linear 
    rounded-full 
    border-4 
    border-t-4 
    mt-1 
    border-gray-200 
    h-${sizeMap[size] || 8} 
    w-${sizeMap[size] || 8}`}
  />

JitsuSpinner.defaultProps = {
  size: 'n',
  className: ''
};

JitsuSpinner.propTypes = {
  size: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export default JitsuSpinner;