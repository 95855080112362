import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../actions';


const mapActionsToProps = dispatch => ({
  addNotifications: compose(dispatch, actions.addNotifications)
});

export default (Component) => {
  const ComponentWithNotification = props => <Component {...props}/>;
  return connect(() => ({}), mapActionsToProps)(ComponentWithNotification);
};

