import gql from 'graphql-tag';

export default {
  dNotes: gql`query getPersonalNotesQuery($userId: Int, $userRole: String){
    getPersonalNotes(userId: $userId, userRole: $userRole){
      id, teacher{ id, firstName, lastName }, description, fileUrl, createdAt
    }
  }`,
  item: gql`query getNoteQuery($id: Int) {
    getNote(id: $id){
        id, description, teacher { id, firstName, lastName }, subjects{ id, code }, fileUrl, createdAt, topic, type
    }
  }`,
  listByCreatedAt: gql`query getNotesByCreatedAtQuery($startDate: String, $endDate: String) {
    getNotesByCreatedAt(startDate: $startDate, endDate: $endDate){
      id, teacher{ id, firstName, lastName }, description, fileUrl, createdAt, topic
    }
  }`,
  create: gql`mutation createNoteQuery(
    $description: String!,
    $topic: String!,
    $type: String!,
    $fileUrl: Upload!,
    $teacherId: Int!,
    $subjectsIds: [Int]) {
        createNote(
            fileUrl: $fileUrl
            description: $description,
            topic: $topic,
            type: $type,
            teacherId: $teacherId,
            subjectsIds: $subjectsIds){
                id,
                description,
                fileUrl,
                topic,
                type,
                teacher { id, firstName, lastName  },
                subjects { id, code },
                createdAt
        }
    }
  `,
  update: gql`mutation updateNoteQuery(
    $id: Int,
    $description: String!,
    $topic: String!,
    $type: String!,
    $fileUrl: Upload!,
    $teacherId: Int!,
    $subjectsIds: [Int]) {
        updateNote(
            id: $id,
            description: $description,
            topic: $topic,
            type: $type,
            fileUrl: $fileUrl
            teacherId: $teacherId,
            subjectsIds: $subjectsIds){
                id,
                description,
                fileUrl,
                topic,
                type,
                teacher { id, firstName, lastName  },
                subjects { id, code },
                createdAt
        }
    }
  `,
  wipe: gql`mutation deleteNoteQuery($id: Int) {
    deleteNote(id: $id){
        id
    }
  }`,
};