import styled from 'styled-components';
import { RAISIN_BLACK } from '../../constants/colors';

const StyledLoadingDiv = styled.div`
  display: inline-block;
  &::after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${RAISIN_BLACK};
    border-color: ${RAISIN_BLACK} transparent ${RAISIN_BLACK} transparent;
    animation: loading 1.2s linear infinite;
  }
  &.small::after{
    width: 10px;
    height: 10px;
    border: 2px solid white;
  }
  &.white::after{
    width: 10px;
    height: 10px;
    border-color: white transparent white transparent;
    border: 5px solid white;
  }
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledErrorDiv = styled.div``;

export {
  StyledLoadingDiv,
  StyledErrorDiv
};