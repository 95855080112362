import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoLine from '../InfoDisplay/InfoLine';
import InfoList from '../InfoDisplay/InfoList';
import JitsuButton from '../JitsuButton';
import { TEXT, LIST, ANSWERS } from '../../constants/displayTypes';
import { DetailsWrapper } from './styled';
import { permissions, permissionLevels } from '../../constants/permissions';
import withUser from '../../hocs/withUser';
import AnswerList from '../InfoDisplay/AnswerList';

const DetailLine = ({ detailLine: { type, text, list, label, id, editor, link, to = () => '' }, data }) => {
  switch (type) {
  case TEXT:
    return <InfoLine label={label} value={text(data)} id={`${id}Text`} editor={editor} link={link} to={to(data)} classname={id}/>;
  case LIST:
    return <InfoList label={label} list={list(data)} keyProperty="id" displayProperty="name" id={`${id}List`}  classname={id}/>;
  case ANSWERS:
    return <AnswerList label={label} list={list(data)} id={`${id}List`}  className={id}/>;
  default:
    return <Fragment />;
  }
};

DetailLine.propTypes = {
  detailLine: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  classname: PropTypes.string.isRequired
};
 
const DetailsFirstTab = ({
  page,
  id,
  display,
  data,
  dataKey,
  history,
  cantDelete,
  user,
  user: { userRole: role },
  cantUpdate,
  InfoComponent,
  isAuthorizedToDelete,
  isAuthorizedToUpdate
}) => {
  const Tag = InfoComponent || DetailsWrapper;
  const props = InfoComponent ? { data: data[dataKey] } : {};
  return <Fragment>
    <Tag className={`${page}-details`} {...props}>
      {!InfoComponent && <Fragment>
        {
          display.map((detailLine, index) =>
            <DetailLine detailLine={detailLine} key={index} data={data[dataKey]} classname={`${dataKey}-value`}/>)
        }
      </Fragment>}
      <div className="form-buttons flex space-x-3 p-4">
        {
          !cantUpdate(data[dataKey], user)
          && (isAuthorizedToUpdate ? isAuthorizedToUpdate(user, id) : permissions[page][role].includes(permissionLevels.UPDATE))
          && <JitsuButton value="Edit" onClick={() => history.push(`/${page}/${id}/update`)} name="edit" />
        }
        {
          !cantDelete(data[dataKey], user)
          && (isAuthorizedToDelete ? isAuthorizedToDelete(user, id) : permissions[page][role].includes(permissionLevels.DELETE))
          && <JitsuButton classname="danger" value="Delete" onClick={() => history.push(`/${page}/${id}/delete`)} name="delete" />
        }
      </div>
    </Tag>
  </Fragment>
};

DetailsFirstTab.defaultProps = {
  cantDelete: () => false,
  cantUpdate: () => false,
};

DetailsFirstTab.propTypes = {
  page: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  display: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  cantDelete: PropTypes.func.isRequired,
  cantUpdate: PropTypes.func.isRequired,
  isAuthorizedToDelete: PropTypes.func,
  isAuthorizedToUpdate: PropTypes.func,
  InfoComponent: PropTypes.any
};

export default withUser(DetailsFirstTab);