import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EmptyInfoListItemLi } from './styled';

const ulWrapperClass='border border-gray-200 rounded-md';

const TableLineAction = ({ 
  header, list, displayProperty, keyProperty, label, listMaxHeight, linkTo, id, classname 
}) => <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
  {/* {label && <InfoLabel>{label}</InfoLabel>} */}
  <dt className="text-sm leading-5 font-medium text-gray-500">
    {header}
  </dt>

  {list.length === 0
    ? <EmptyInfoListItemLi className="empty">None</EmptyInfoListItemLi>
    : <>
      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
        <ul className={list.length > 1 ? `${ulWrapperClass} overflow-y-auto h-40` : `${ulWrapperClass}`}>
          {
            list.map(item => <li className=" border-b border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5" key={item[keyProperty]}>
              <span className="ml-2 flex-1 w-0 truncate">
                {item[displayProperty]}
              </span>
              {linkTo ? 
                <div className="ml-4 flex-shrink-0">
                  <Link 
                    to={`/${linkTo}/${item[keyProperty]}`} 
                    className="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
                        View
                  </Link>
                </div> : ''}
            </li>)
          }
        </ul></dd></>}
</div>;

TableLineAction.defaultProps = {
  listMaxHeight: '200px',
};

TableLineAction.propTypes = {
  header: PropTypes.string,
  classname: PropTypes.string,
  label: PropTypes.string,
  keyProperty: PropTypes.string.isRequired,
  displayProperty: PropTypes.string.isRequired,
  id: PropTypes.string,
  list: PropTypes.array.isRequired,
  linkTo: PropTypes.string,
  listMaxHeight: PropTypes.string
};

export default TableLineAction;
