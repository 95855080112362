import { GRAPH_COLORS } from "../../constants/colors";

const createSchoolData = ({
  numberOfAdmins,
  numberOfOverseers,
  numberOfFemaleTeachers,
  numberOfMaleParents,
  numberOfFemaleParents,
  numberOfMaleStudents,
  numberOfFemaleStudents,
  numberOfMaleTeachers
}) => [
  {
    name: 'Teachers',
    fill: GRAPH_COLORS.LIGHT_GREEN,
    value: numberOfFemaleTeachers + numberOfMaleTeachers,
    females: numberOfFemaleTeachers,
    males: numberOfMaleTeachers
  },
  {
    name: 'Parents',
    fill: GRAPH_COLORS.RED,
    value: numberOfMaleParents + numberOfFemaleParents,
    males: numberOfMaleParents,
    females: numberOfFemaleParents
  },
  {
    name: 'Admins',
    fill: GRAPH_COLORS.GREEN,
    value: numberOfAdmins,
  },
  {
    name: 'Managers',
    fill: GRAPH_COLORS.BLUE,
    value: numberOfOverseers
  },
  {
    name: 'Students',
    fill: GRAPH_COLORS.PURPLE,
    value: numberOfMaleStudents + numberOfFemaleStudents,
    females: numberOfFemaleStudents,
    males: numberOfMaleStudents
  },
];

const createTeacherData = () => {

};

const createStudentData = () => {

};

const label = entry => `${entry.name} (${entry.value})`;

export { createSchoolData, createStudentData, createTeacherData, label };