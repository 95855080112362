import { graphql } from 'react-apollo';
import subjectQueries from '../../queries/subjects';
import { fields, fetches, getRequestData } from './helper';

export default {
  queries: (id) => [
    graphql(subjectQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(subjectQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    }),
    graphql(subjectQueries.categories, { name: 'categories' })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: ({ data: { getSubject = {} } }) => ({
    ...getSubject,
    categories: getSubject.categories.map(cat => ({ ...cat, value: cat.id })),
    teacher: getSubject.leadTeacher
      ? [{
        ...getSubject.leadTeacher,
        actualId: getSubject.leadTeacher.id,
        text: `${getSubject.leadTeacher.firstName} ${getSubject.leadTeacher.lastName}`
      }]
      : []
  }),
  success: (response, history) => { history.push(`/subjects/${response.data.updateSubject.id}`); }
}