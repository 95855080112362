import { graphql } from 'react-apollo';
import noteQueries from '../../queries/notes';
import { afterDelete } from '../../functions/query';
import { getCalenderQueries } from '../commonHelpers';

export default {
  queries: id => [
    graphql(noteQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(noteQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: ({ fullItemData, ...variables }) => mutate({
          variables,
          update(cache, { data }) {
            const { all, month } = getCalenderQueries(noteQueries.listByCreatedAt, fullItemData.createdAt);
            afterDelete(cache, all, 'getNotesByCreatedAt', data.deleteNote);
            afterDelete(cache, month, 'getNotesByCreatedAt', data.deleteNote);
          }
        })
      })
    })
  ],
  dataKey: 'getNote',
  success: (response, history) => { history.push('/notes'); }
};