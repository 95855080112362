import { commonFields } from '../commonHelpers';
import validateNote from './validation';
import { TEXT_INPUT, UPLOAD_INPUT, TEXT_EDITOR } from '../../constants/fieldTypes';

export const fields = [
  () => ({
    name: 'topic',
    type: TEXT_INPUT,
    label: 'Topic',
    placeholder: 'title of note',
    max: 100,
    wrapperClassName: 'full',
    required: true,
  }),
  () => ({
    name: 'description',
    type: TEXT_EDITOR,
    label: 'Brief Summary',
    placeholder: 'What is the note description',
    textarea: true,
    required: true,
  }),
  () => ({
    name: 'fileUrl',
    type: UPLOAD_INPUT,
    label: 'File',
    optional: true,
  }),
  (props) => commonFields.subjectsMultiSelect(props, { required: true }),
];

export const fetches = [];

export const getRequestData = ({ user }, { errors, subjects, rating, ...rest }) => ({
  requestData: {
    ...rest,
    rating: parseInt(rating, 10),
    subjectsIds: subjects.map(subject => parseInt(subject.actualId, 10)),
    teacherId: user.userId
  },
  errors: validateNote({ ...rest, subjects })
});
