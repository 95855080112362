import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import JitsuMultiselectSelection from './JitsuSimpleMultiselectSelection';
import JitsuMultiselectToggle from './JitsuMultiselectToggle';
import JitsuSimpleMultiselectOptions from './JitsuSimpleMultiselectOptions';
import { createMap } from '../functions';
import JitsuField from './JitsuField';
import JitsuLabel from './JitsuLabel';
import JitsuMutliselectOpenOptions from './JitsuMultiselectOpenOptions';
import ClickOutside from './ClickOutside';

class JitsuSimpleMultiselect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      selectionsMap: createMap(props.value, props.valueKey),
      lockedOptionsMap: createMap(props.lockedOptions, props.valueKey),
    };
    this.onSelect = this.onSelect.bind(this);
    this.toggleExpansion = this.toggleExpansion.bind(this);
    this.hideExpansion = this.hideExpansion.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(() => ({
      selectionsMap: createMap(nextProps.value, nextProps.valueKey),
      lockedOptionsMap: createMap(nextProps.lockedOptions, nextProps.valueKey)
    }));
  }
  toggleExpansion() {
    this.setState(state => ({ expanded: !state.expanded }));
  }
  hideExpansion() {
    this.setState(() => ({ expanded: false }));
  }
  onSelect(selected) {
    const { onChange, value, limit, valueKey, name, isSingleSelect } = this.props;
    const { selectionsMap, lockedOptionsMap } = this.state;
    if (lockedOptionsMap[selected[valueKey]]) {
      return;
    }
    if (isSingleSelect) {
      onChange([selected], name);
      return;
    }
    const newSelections = selectionsMap[selected[valueKey]]
      ? value.filter(selection => selection[valueKey] !== selected[valueKey])
      : value.concat(selected);
    if (newSelections.length > limit) return;
    onChange(newSelections, name);
  }
  render() {
    const { value: selection, textKey, options, valueKey, label, open, error, optional, required, tooltip, id } = this.props;
    const { lockedOptionsMap, expanded, selectionsMap } = this.state;
    return <JitsuField error={error}>
      <JitsuLabel optional={optional} required={required} tooltip={tooltip}>{label}</JitsuLabel>
      <div className="flex w-full items-center relative">
        <div className="flex flex-col w-full items-center relative">
          <ClickOutside onClickOutside={this.hideExpansion} className="w-full">
            <div className="w-full">
              {!open && <Fragment>
                <div className="mb-2 p-1 flex border border-gray-200 bg-white rounded" style={{ minWidth: '300px' }}>
                  <JitsuMultiselectSelection
                    selection={selection}
                    textKey={textKey}
                    valueKey={valueKey}
                    remove={this.onSelect}
                    clickSelectionArea={this.toggleExpansion}
                    id={id}
                  />
                  <JitsuMultiselectToggle expanded={expanded} onClick={this.toggleExpansion} id={id} />
                </div>
                <JitsuSimpleMultiselectOptions
                  options={options}
                  textKey={textKey}
                  valueKey={valueKey}
                  onClickOption={this.onSelect}
                  expanded={expanded}
                  lockedMap={lockedOptionsMap}
                  selectionsMap={selectionsMap}
                  id={id}
                />
              </Fragment>}
              {open && <JitsuMutliselectOpenOptions
                options={options}
                textKey={textKey}
                valueKey={valueKey}
                onClickOption={this.onSelect}
                expanded={expanded}
                lockedMap={lockedOptionsMap}
                selectionsMap={selectionsMap}
                id={id}
              />}
            </div>
          </ClickOutside>
        </div>
      </div>
    </JitsuField>;
  }
};

JitsuSimpleMultiselect.defaultProps = {
  value: [],
  error: '',
  lockedOptions: [],
  isSingleSelect: false,
  onChange: () => {},
  options: [],
  className: 'custom-select',
  wrapperClassName: '',
  id: 'custom-select',
  expanded: false,
  open: false,
};

JitsuSimpleMultiselect.propTypes ={
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  lockedOptions: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array.isRequired,
  limit: PropTypes.number,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  isSingleSelect: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
};

export default JitsuSimpleMultiselect;