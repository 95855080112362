import dateFns from 'date-fns';
import { TEXT_INPUT, SELECT_INPUT, DATE_INPUT, SEARCH_MULTISELECT } from '../../constants/fieldTypes';
import validateTeacher from './validation';
import { TEACHER, OVERSEER, ADMIN, SUBJECT_ADMIN, SEER } from '../../constants/roles';
import nations from '../../constants/nations';
import { DATE } from '../../constants/dateInputTypes';
import { textChange } from '../../functions/textChange';

export const fields = [
  () => ({ name: 'firstName', type: TEXT_INPUT, label: 'First Name', placeholder: 'Gbyon', required: true, }),
  () => ({ name: 'lastName', type: TEXT_INPUT, label: 'Last Name', placeholder: 'Doe', required: true, }),
  () => ({ name: 'regNumber', type: TEXT_INPUT, label: 'Reg Number', placeholder: 'GB123456', required: true, capitalized: true }),
  () => ({
    name: 'dob',
    type: DATE_INPUT,
    required: true,
    label: 'Date of Birth',
    placeholder: '1992-05-11',
    dateInputType: DATE
  }),
  () => ({
    name: 'gender',
    type: SELECT_INPUT,
    required: true,
    label: 'Gender',
    valueKey: 'value',
    options: [{ value: 'Male', text: 'Male' }, { value: 'Female', text: 'Female' }]
  }),
  () => ({ name: 'email', type: TEXT_INPUT, label: 'Email', placeholder: 'email@mail.com', required: true, }),
  () => ({ name: 'phoneNumber', type: TEXT_INPUT, label: 'Phone Number', placeholder: '07832393288', required: true, }),
  () => ({ name: 'address', type: TEXT_INPUT, label: 'Address', placeholder: 'address', required: true, }),
  () => ({ name: 'previousWorkPlace', type: TEXT_INPUT, label: 'Previous Work Place', placeholder: 'Glory Nursery n Primary School', required: true, }),
  () => ({ name: 'qualification', type: TEXT_INPUT, label: 'Highest qaulification', placeholder: 'M.Sc. Mathematics', required: true, }),
  () => ({
    name: 'nationality',
    type: SELECT_INPUT,
    label: 'Nationality',
    required: true,
    valueKey: 'code',
    options: nations.map(nation => ({ text: nation.name, value: nation.code }))
  }),
  ({ user }) => ({
    name: 'role',
    type: SELECT_INPUT,
    label: 'Role',
    required: true,
    valueKey: 'value',
    options: user.userRole === OVERSEER
    ? [
      { text: textChange('Teacher'), value: TEACHER },
      { text: `${textChange('Subject')} admin`, value: SUBJECT_ADMIN },
      { text: 'View only', value: SEER },
      { text: 'Admin', value: ADMIN }
    ]
    : [
      { text: textChange('Teacher'), value: TEACHER },
      { text: `${textChange('Subject')} admin`, value: SUBJECT_ADMIN },
      { text: 'View only', value: SEER },
    ]
  }),
  props => ({
    name: 'subjects',
    type: SEARCH_MULTISELECT,
    textKey: 'text',
    label: 'Subjects',
    optional: true,
    maxHeight: '100px',
    placeholder: 'subjects',
    displayTextFcn: selection => selection.text || selection.code,
    valueKey: 'actualId',
    through: 'subject',
    lockedOptions: props.data
      ? props.data.getTeacher.subjects.map(subject => ({ ...subject, actualId: subject.id, text: subject.code }))
      : []
  }),
];

export const getRequestData = (props, data, dataAddition) => ({
  requestData: {
    ...data,
    photoUrl: 'url',
    dob: dateFns.format(new Date(data.dob), 'YYYY-MM-DD'),
    subjectsIds: data.subjects.map(({ actualId }) => parseInt(actualId, 10)),
    ...dataAddition
  },
  errors: validateTeacher(data)
});

export const fetches = [{ dataKey: 'getClasses', name: 'clazzes' },];
