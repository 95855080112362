import { graphql } from 'react-apollo';
import classQueries from '../../queries/clazz';
import { updateClassListAfterDelete } from './helper';
import { requestNameMap } from '../../constants/requests';

export default {
  queries: id => [
    graphql(classQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(classQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { deleteClass } }) => {
            updateClassListAfterDelete(proxy, deleteClass);
          }
        })
      })
    })
  ],
  dataKey: 'getClass',
  requestName: requestNameMap.DELETE_CLASS,
  success: (response, history) => { history.push('/classes'); }
};
