import React, { Component } from 'react';
import { JitsuInput } from '../components-elements/jistu'
import Searcher from './Searcher';
import JitsuMultiselectOption from '../components-elements/JitsuMultiselectOption';

const withSearch = (Comp, through, exempt, id, createProps = () => ({}), minimum = 3) => {
  class Search extends Component {
    constructor(props) {
      super(props);
      this.state = {
        text: '',
        searchResult: [],
        value: '',
        timeout: null,
        unmounted: false
      };
      this.onChangeValue = this.onChangeValue.bind(this);
      this.setResults = this.setResults.bind(this);
      this.setText = this.setText.bind(this);
    }
    onChangeValue(value) {
      this.setState({
        value,
        timeout: setTimeout(() => {
          this.setText();
        }, 1500)
      });
    }
    setText() {
      this.setState(({ value }) => ({ text: value, unmounted: false }));
    }
    UNSAFE_componentWillMount() {
      this.setState({ timeout: null });
    }
    setResults(searchResult) {
      this.setState({ searchResult, unmounted: true, timeout: null });
    }
    render() {
      const { value, text, unmounted, searchResult } = this.state;
      return <div>
        <Comp {...this.props} {...createProps(searchResult)}>
          <JitsuMultiselectOption searcher>
            <div className="w-full flex justify-center">
              <JitsuInput
                value={value}
                placeholder="Search"
                onChange={this.onChangeValue}
                name={'value'}
                id={`${id}-search-value`}
              />
            </div>
          </JitsuMultiselectOption>
        </Comp>
        {!unmounted && <Searcher
          text={text}
          through={through}
          exempt={exempt}
          setSearchResults={this.setResults}
          minimum={minimum}
        />}
      </div>
    }
  }

  return Search;
};

export default withSearch;