import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextEditor from '../TextEditor';
import JitsuLabel from '../JitsuLabel';

const AnswersListWrapper = styled.div`
  .dnwRos .RichEditor-editor .public-DraftEditor-content {
    min-height: auto;
    padding: 8px;
  }
`;

const AnswerList = ({ list, label, className }) => <AnswersListWrapper className={`px-4 ${className}`}>
  <JitsuLabel>{label}</JitsuLabel>
  {list.map((item) => <div
    className={`border-l-4 ${item.correct ? 'border-green-700' : 'border-red-700'} pl-4`}
    key={item.id}
  >
    <TextEditor
      value={item.content}
      readOnly
    />
  </div>)}
</AnswersListWrapper>;

AnswerList.propTypes = {
  id: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default AnswerList;