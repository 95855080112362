import styled from 'styled-components';
import { WHITE, LIGHT_GRAY } from '../../constants/colors';

export const ColumnOneHeader = styled.h2`
  font-size: 16px;
  text-align: left;
  margin: 0px;
  padding: 5px;
`;

export const InfoListWrapper = styled.li`
  list-style-type: none;
  margin-left: 8px;
`;

export const NestedInfoListUl = styled.ul`
  padding: 0px 0 0 23px;
  overflow: auto;
`;

export const NextInfoListItemLi = styled.li`

`;

export const InfoListUl = styled.ul`
  margin: 0;
  padding: 10px;
  overflow: auto;
  background: ${WHITE};
  list-style-type: none;
  margin-top: 9px;
  margin-right: 11px;
  margin-left: 11px;
`;

export const InfoListItemLi = styled.li`
  margin-bottom: 10px;
  list-style-type: none;
  padding: 7px;
  margin-right: 5px;
  > div {
    padding: 7px;
  }
`;

export const EmptyInfoListItemLi = styled.div`
margin-bottom: 10px;
list-style-type: none;
font-size: 10px;
padding: 7px;
margin-right: 5px;
color: ${LIGHT_GRAY};
div{
  font-size: 14px;
  padding: 7px;
}
`;

export const InfoLabel = styled.label`
  padding: 7px;
  text-transform: capitalize;
`;