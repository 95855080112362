import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { changeTimestampToDate, isNumber } from '../../functions';
import DownloadLink from '../DownloadLink';
import { ShortenedText } from '../TextHelpers';
import JitsuInput from '../JitsuInput';
import { withUser } from '../../hocs';
import { format } from 'date-fns';

const AssessmentRow = ({
  item,
  onClick,
  onChangePercentage,
  percentage,
  error,
  hasPercentage,
  isPercentageChangeable,
  selectedItem,
  className,
  user
}) => {
  const classNames = `${selectedItem && selectedItem.id === item.id ? 'bg-blue-200' : ''} 
  ${item.completed ? 'border-blue-600' : 'border-red-600'}`;
  return (<div
    onClick={() => onClick(item)}
    className={`p-2 text-sm rounded border-l-2 mb-2 ${classNames} ${className}`}
    id={`assessmentsItem-${item.id}`}
  >
    <div className="flex">
      {item.quiz && <div className="bg-yellow-700 text-white px-1 rounded mr-2">Quiz</div>}
      <Link to={`/assessments/${item.id}`} className="underline cursor-pointer" id={`assessmentsItemLink-${item.id}`}>
        <ShortenedText text={item.topic} expansionType={'POP'} classNames="topic"/>
      </Link>
    </div>
    <DownloadLink url={item.fileUrl || ''} className="mt-1 text-xs" prefix="assessment"/>
    <div className="text-xs mt-1">Deadline - {changeTimestampToDate(item.deadline)}</div>
    {
      hasPercentage && isPercentageChangeable && user.scoresSubmissionDeadline >= format(new Date(), 'YYYY-MM-DD')
        ? <div className="flex w-24"><JitsuInput
          onChange={onChangePercentage}
          value={percentage === 'null' ? '' : percentage}
          name={`${item.id}`}
          id={`assessmentRowInput-${item.id}`}
          error={error}
        /><span className="text-lg -ml-8 pt-2">%</span></div>
        : hasPercentage && <div>Makes up {!isNumber(percentage) ? 0 : percentage}%</div>
    }
  </div>);
};

AssessmentRow.defaultProps = {
  onClick: () => {},
  onChangePercentage: () => {},
  percentage: 0,
  hasPercentage: false,
  isPercentageChangeable: false,
  selectedItem: {},
  className: '',
  error: ''
};

AssessmentRow.propTypes = {
  item: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  onChangePercentage: PropTypes.func.isRequired,
  percentage: PropTypes.any.isRequired,
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasPercentage: PropTypes.bool.isRequired,
  isPercentageChangeable: PropTypes.bool.isRequired
};

export default withUser(AssessmentRow);