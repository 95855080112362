import React from 'react';
import PropTypes from 'prop-types';

const colors = {
  ALUMNI: 'yellow',
  SUSPENDED: 'red',
  ACTIVE: 'green',
  PASSWORD_RESET: 'orange',
  NEW: 'blue'
};

const text = {
  ALUMNI: 'Alumni',
  SUSPENDED: 'Suspended',
  ACTIVE: 'Active',
  PASSWORD_RESET: 'Password reset',
  NEW: 'NEW'
}

const JitsuUserStatus = ({ status, id }) =>
  <div id={id} className={`text-black bg-${colors[status]}-300 h-8 text-sm ml-2 px-2 pt-2 rounded text-center text-bold`}>
    {text[status]}
  </div>;

JitsuUserStatus.defaultProps = {
  status: 'ALUMNI'
};

JitsuUserStatus.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default JitsuUserStatus;