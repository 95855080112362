import newsQueries from '../../queries/news';
import { TEXT, LIST } from '../../constants/displayTypes';
import { changeTimestampToDate } from '../../functions';
import { textChange } from '../../functions/textChange';
import { ADMIN, OVERSEER } from '../../constants/roles';

export default {
  dataKey: 'getNews',
  query: newsQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'topic', text: data => data.topic, id: 'topic' },
    { type: TEXT, label: 'Date posted', text: data => changeTimestampToDate(data.createdAt), id: 'datePosted' },
    { type: TEXT, label: 'description', text: data => data.description, id: 'description', editor: true },
    { type: LIST, label: textChange('Subjects'), list: data => data.subjects.map(subject => ({ ...subject, name: subject.code })), id: 'subjects' },
    { type: TEXT, label: 'Posted By', text: ({ teacher }) => `${teacher.firstName} ${teacher.lastName}`, id: 'postedBy' },
  ],
  cantDelete: (news, user) => ((!!news.restricted || news.subjects.length === 0) && ![ADMIN, OVERSEER].includes(user.userRole)),
  cantUpdate: (news, user) => {
    return ((!!news.restricted || news.subjects.length === 0) && ![ADMIN, OVERSEER].includes(user.userRole))
  },
};
