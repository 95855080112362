import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { changeTimestampToDate } from '../../functions';
import { ShortenedText } from '../TextHelpers';
import DownloadLink from '../DownloadLink';

const NewsRow = ({ item, selectedItem }) => {
  const classNames = `
  ${selectedItem && selectedItem.id === item.id ? 'bg-blue-200' : ''} 
  ${item.subjects.length > 0 ? 'border-l-2 border-purple-500' : ''} 
  p-2 text-sm rounded mb-2 
  `;
  return <div
    className={classNames}
    id={`newsItem-${item.id}`}
  >
    <div className="flex">
      {!!item.restricted && <div className="bg-red-700 text-white px-1 rounded mr-2">Staff</div>}
      <Link 
        to={`/news/${item.id}`}
        id={`newsItemLink-${item.id}`}
        className="underline cursor-pointer"
      >
        <ShortenedText text={item.topic} length={40} expansionType={'POP'} classNames="topic"/>
      </Link>
    </div>
    <ShortenedText text={item.excerpt} expansionType={'SCROLL'} classNames="text-xs mt-1"/>
    <DownloadLink url={item.fileUrl || ''} className="mt-1 text-xs" prefix="assessment"/>
    <div className="text-xs mt-1">{changeTimestampToDate(item.createdAt)}</div>
  </div>
};

NewsRow.defaultProps = {
  selectedItem: {}
};

NewsRow.propTypes = {
  item: PropTypes.object.isRequired,
  selectedItem: PropTypes.object.isRequired
};

export default NewsRow;