import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { ErrorOrLoading } from '../components-elements';

const checkErrorInFetches = (props, fetches) =>  {
  return fetches.find((f) => props[f.name || f] && (props[f.name || f].loading || props[f.name || f].error));
}

const checkLoadingAndError = (props, fetches = []) => {
  const fetchError = checkErrorInFetches(props, ['data', ...fetches]);
  if (fetchError) {
    return { loading: props[fetchError.name || fetchError].loading, error: props[fetchError.name || fetchError].error };
  }
  return false;
};

const dataFetchingComponent = (Component, fetches = [], skeletonProps, hideLoader = false) => {
  const DataFetchingComponent = (props) => {
    const errorOrLoading = checkLoadingAndError(props, fetches);
    if (errorOrLoading.loading && hideLoader) return <Fragment />
    return <Fragment>
      {
        errorOrLoading && <Fragment>
          {
            skeletonProps && errorOrLoading.loading
              ? <Skeleton {...skeletonProps}/>
              : <ErrorOrLoading {...errorOrLoading}/>
          }
        </Fragment>
      }
      {!errorOrLoading && <Component {...props} />}
      {/* {!errorOrLoading && <Skeleton {...skeletonProps}/>} */}
    </Fragment>;
  };

  DataFetchingComponent.propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.object
    })
  };

  return DataFetchingComponent;
};

export default dataFetchingComponent;