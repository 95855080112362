import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import * as controllers from '../controllers';
import { DetailsFirstTab, NotFound } from '../components-elements';
import { tabsAuthorized, getCurrentUser } from '../functions';
import { withUser, dataFetchingComponent, withPageHistory } from '.';

import {
  JitsuTab,
  JitsuTabBody,
  JitsuButton
} from '../components-elements/jistu';
import flowRight from 'lodash.flowright';

const createDetails = (page, id, history) => {
  if (controllers[page].details.isAuthorized && !controllers[page].details.isAuthorized(getCurrentUser(), id)) {
    return () => <NotFound/>
  }
  const {
    query,
    options,
    display,
    dataKey,
    tabHeaders = () => [],
    cantDelete,
    cantUpdate,
    goBack,
    queries = () => [],
    goBackBtnValue,
    InfoComponent,
    isAuthorizedToUpdate,
    isAuthorizedToDelete,
    fetches = [],
  } = controllers[page].details;

  class Details extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedTab: 'first'
      };
      this.onClickTabHeader = this.onClickTabHeader.bind(this);
    }
    componentDidMount() {
      const { onItemSelected, data } = this.props;
      if (onItemSelected) {
        onItemSelected(page, data[dataKey]);
      }
    }
    onClickTabHeader(value) {
      this.setState(() => ({ selectedTab: value }));
    }
    render() {
      const { data } = this.props;
      if (!data[dataKey]) {
        return <NotFound />
      }
      const { selectedTab } = this.state;
      const tabs = tabsAuthorized(tabHeaders(this.props), page);
      if (!page) return <Fragment/>;
      return data[dataKey]
        ? <Fragment>
          {goBack && <div className="mb-4 flex justify-end">
            <JitsuButton
              value={goBackBtnValue || 'Back'}
              onClick={() => goBack(this.props.history)}
              name="goBack"
            />
          </div>}
          <JitsuTab selected={selectedTab}
            headers={[{ text: 'Info', value: 'first' }, ...tabs]} onClickTabHeader={this.onClickTabHeader}>
            <JitsuTabBody onScreen={selectedTab === 'first'}>
              <DetailsFirstTab
                InfoComponent={InfoComponent}
                page={page}
                history={history}
                data={data}
                dataKey={dataKey}
                display={display}
                id={id}
                cantDelete={cantDelete}
                cantUpdate={cantUpdate}
                isAuthorizedToDelete={isAuthorizedToDelete}
                isAuthorizedToUpdate={isAuthorizedToUpdate}
              />
            </JitsuTabBody>
            <Fragment>
              { 
                tabs
                  .filter(({ Component }) => !!Component)
                  .map(({ Component, value, props = {} }) => <JitsuTabBody onScreen={value === selectedTab} key={value}>
                    <Component id={parseInt(id, 10)} onScreen={value === selectedTab} {...props}/>
                  </JitsuTabBody>)
              }
            </Fragment>
          </JitsuTab>
        </Fragment>
        : <NotFound />;
    }
  }
  Details.propTypes = {
    data: PropTypes.object.isRequired,
    onItemSelected: PropTypes.func,
    history: PropTypes.object.isRequired
  };
  return flowRight(
    ...queries(id),
    graphql(query, options(id))
  )(dataFetchingComponent(withPageHistory(withUser(Details), page, [{ dataKey, name: 'data', ...fetches }])));
};

export default createDetails;