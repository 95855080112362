import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const JitsuMainWrapper = ({ full, children, className }) => {
  const classes = full
    ? ''
    : 'p-0 md:p-8 lg:p-8 xl:p-8';
  const style = full
    ? { height: 'calc(100% - 100px)'}
    : { height: 'calc(100% - 100px)' }

  return <div
    className={`${classes} ${className}`}
    style={style}
  >
    {children}
  </div>
};

JitsuMainWrapper.defaultProps = {
  full: false,
  className: '',
  children: <Fragment/>
}

JitsuMainWrapper.propTypes = {
  full: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default JitsuMainWrapper;
