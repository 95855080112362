import React from 'react';
import PropTypes from 'prop-types';

const JitsuTab = ({ children, headers, selected, onClickTabHeader, className }) =>
  <div className={`${className} h-full`}>
    { headers.length > 1 && <ul className="flex cursor-pointer overflow-auto">
      {
        headers.map(({ text, value }) =>
          <li
            className={`
              py-0 px-6 bg-white rounded-t-lg 
              md:py-2 lg:py-2 xl:py-2
              ${selected === value ? 'selected' : 'text-gray-500 bg-gray-200'}
            `}
            key={value} onClick={() => onClickTabHeader(value)}
            id={text.split(' ').join('-')}
          >
            {text}
          </li>)
      }
    </ul>}
    {children}
  </div>;

JitsuTab.defaultProps = {
  headers: [],
  selected: '',
  onClickTabHeader: () => {},
  children: <div/>,
  className: ''
};

JitsuTab.propTypes = {
  children: PropTypes.node.isRequired,
  headers: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClickTabHeader: PropTypes.func.isRequired
};

export default JitsuTab;