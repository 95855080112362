import React from 'react';
import PropTypes from 'prop-types';
import JitsuIcon from './JitsuIcon';

const JitsuTable = ({ headers, children, className }) => {
  return <table className={`table-auto border-collapse w-full ${className}`}>
    <thead>
      {headers.length > 0 && <tr className="rounded-lg text-sm font-medium text-gray-700 text-left">
        {headers.map(header => <th className="px-4 py-2 bg-gray-200" key={header.title || header}>
          {header.icon && <JitsuIcon name={header.icon}/>}
          {header.title || header}
        </th>)}
      </tr>}
    </thead>
    <tbody className="text-sm font-normal text-gray-700">
      {children}
    </tbody>
  </table>
}

JitsuTable.defaultProps = {
  headers: [],
  children: <tr><td></td></tr>,
  className: ''
}

JitsuTable.propTypes = {
  headers: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default JitsuTable;