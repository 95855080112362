import { graphql } from 'react-apollo';
import { quizQueries } from '../../queries';
import { fields, getRequestData, updateQuizListAfterCreate } from './helper';

export default {
  queries: () => [
    graphql(quizQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { createQuiz } }) => {
            updateQuizListAfterCreate(proxy, createQuiz);
          }
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  initialValues: () => ({ name: '', description: '', duration: 60, type: 'ALL_QUESTIONS', questions: [] }),
  success: (response, history) => { history.push(`/quizzes/${response.data.createQuiz.id}`); }
};

