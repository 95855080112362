import React from 'react';
import PropTypes from 'prop-types';
import placeholder1 from '../../assets/images/placeholder1.svg';

const InitialScreen = ({ message, subMessage }) => <div className="flex flex-col items-center">
  <img
    src={placeholder1}
    className="placeholder h-64 w-64 my-16"
    style={{ height: '32rem', width: '32rem' }}
    alt="placeholder" />
  {/* <div className="text-gray-500">{subMessage}</div> */}
</div>

InitialScreen.defaultProps = {
  message: 'None Selected',
  subMessage: ''
};

InitialScreen.propTypes = {
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string.isRequired,
};

export default InitialScreen;
