import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JitsuLabel } from './jistu';
import JitsuIcon from './JitsuIcon';
import JitsuField from './JitsuField';


const isFileSet = value => (value && value !== '' && value.size !== -1);

class JitsuUpload extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    const uploadElement = document.getElementById(this.props.name);
    uploadElement.click();
  }
  handleChange(e) {
    const { name, onChange } = this.props;
    onChange(e.target.files[0], name);
  }
  removeFile(e) {
    const { name, onChange } = this.props;
    onChange({ size: -1, path: null }, name);
  }
  render() {
    const { name, fileTypes, value = {}, placeholder, label, wrapperClassname, error, id, optional, required, tooltip } = this.props;
    const val = value || '';
    return (<JitsuField className={`${wrapperClassname}`} error={error} id={id}>
      {label && <JitsuLabel optional={optional} required={required} tooltip={tooltip}>{label}</JitsuLabel>}
      <div className="flex">
        <label className="w-64 flex flex-col z-0
        px-4 py-4 bg-white text-gray-500 rounded-md shadow-sm break-all 
        tracking-wide border border-blue cursor-pointer 
        hover:bg-blue-100" name={`${name}Btn`} id={`${name}Btn`}>
          {/* <JitsuIcon name="upload" className="w-8 h-8 fill-current text-blue-500 hover:text-blue-700"/> */}
          <span className="mt-2 text-base leading-normal">{val.name || (typeof val  === 'string' && val ? val : placeholder)}</span>
          <input
            type="file"
            accept={fileTypes.toString()}
            required
            onChange={this.handleChange}
            name={name}
            id={name}
            className="hidden"
          />
        </label>
        <div className="">
          {isFileSet(val) && <JitsuIcon
            name="close"
            onClick={this.removeFile}
            className="w-8 h-8 fill-current text-red-500 hover:text-red-700-mb-5 ml-1 z-10 cursor-pointer"
          />}
        </div>
      </div>
    </JitsuField>);
  }
}

JitsuUpload.defaultProps = {
  fileTypes: ['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg', '.ppt', '.csv', '.xlsx'],
  placeholder: 'Select a file from desktop',
  value: {},
  classname: '',
  id: '',
  wrapperClassname: ''
};

JitsuUpload.propTypes = {
  value: PropTypes.any,
  id: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  fileTypes: PropTypes.array,
  placeholder: PropTypes.string,
  classname: PropTypes.string,
  wrapperClassname: PropTypes.string,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
};

export default JitsuUpload;