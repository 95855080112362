import { graphql } from 'react-apollo';
import flowRight from "lodash.flowright";
import { studentQueries, performanceQueries } from '../../queries';


export default (Comp) => flowRight(
  graphql(studentQueries.submitSemesterScores, {
    props: ({ mutate }) => ({
      submitScores: variables => mutate({
        variables,
        refetchQueries: [{
          query: performanceQueries.getStudentPerformance,
          variables: {
            studentId: variables.studentId,
            semesterId:  parseInt(variables.semesterId, 10),
          }
        }]
      })
    })
  }),
  graphql(performanceQueries.getStudentPerformance, {
    options: ({ semesterId, userId }) => ({
      variables: {
        semesterId: parseInt(semesterId, 10),
        studentId: userId
      }
    }),
  })
)(Comp);