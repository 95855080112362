import React from 'react';
import PropTypes from 'prop-types';

const JitsuError = ({ children, className }) => <p className={`text-red-500 text-xs italic ${className}`}>
  {children}
</p>;

JitsuError.defaultProps = {
  children: '',
  className: ''
};

JitsuError.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default JitsuError;
