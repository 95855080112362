import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import JitsuTooltip from './JitsuTooltip';

const JitsuLabel = ({ forValue, children, optional, required, tooltip }) =>
  <Fragment>
    <label className="block text-gray-700 text-sm font-bold mb-1 flex" htmlFor={forValue}>
      {children}
      {required && <span className="text-red-500 ml-1">*</span>}
      {optional && !required && <span className="text-gray-500 ml-2">optional</span>}
      {/* {tooltip && <JitsuTooltip tooltipText={tooltip} />} */}
    </label>
    {tooltip && <div className="text-gray-500 text-sm">{tooltip}</div>}
  </Fragment>

JitsuLabel.propTypes = {
  forValue: PropTypes.string,
  children: PropTypes.any,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any
};

export default JitsuLabel;