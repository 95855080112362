import gql from 'graphql-tag';

export default {
  list: gql`query {
    getColleges{
      id,
      name
    }
  }`,
  item: gql`query getCollegeQuery($id: Int) {
    getCollege(id: $id){
      name, id, teacher{ id, firstName, lastName }
    }
  }`,
  update: gql`mutation updateCollegeQuery(
    $id: Int,
    $name: String!,
    $teacherId: Int) {
        updateCollege(
            id: $id,
            name: $name,
            teacherId: $teacherId){
                name,
                id,
                teacher{ firstName, lastName, id },
        }
    }
  `,
  create: gql`mutation createCollegeQuery(
    $name: String!,
    $teacherId: Int) {
        createCollege(
            name: $name,
            teacherId: $teacherId){
                id,
                name,
                teacher{ id, firstName, lastName },
        }
    }
  `,
  wipe: gql`mutation deleteCollegeQuery($id: Int) {
    deleteCollege(id: $id){
        id
    }
  }`
}
