import React from 'react';
import PropTypes from 'prop-types';

const JitsuTableRow = ({ children, onClick, className, ...rest }) =>
  <tr className={`hover:bg-gray-100 border-b border-gray-200 ${className}`} onClick={onClick} {...rest}>
    {children}
  </tr>

JitsuTableRow.defaultProps = {
  onClick: () => {},
  children: <td></td>,
  className: ''
}

JitsuTableRow.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
}

export default JitsuTableRow;
