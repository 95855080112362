import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../actions';
import { withRouter } from 'react-router-dom'
import {
  selectPageSelectedItem,
  selectPageSearchText,
  selectPageSelectedMonth,
  selectInitialValues,
  selectPageSelectedGroup
} from '../selectors';
import { forcefullyExtractId } from '../functions';

const mapActionsToProps = dispatch => ({
  onMonthSelected: compose(dispatch, actions.onMonthSelected),
  onItemSelected: compose(dispatch, actions.onItemSelected),
  onSearchResulted: compose(dispatch, actions.onSearchResulted),
  setInitialValuesForCreateForm: compose(dispatch, actions.setInitialValuesForCreateForm),
  onGroupSelected: compose(dispatch, actions.onGroupSelected)
});

export default (Component, page) => {
  const ComponentWithPageHistory = (props) => {
    const p = { ...props, selectedItem: forcefullyExtractId(props.history) ? props.selectedItem : {}};
    return <Component {...p} page={page}/>
  };

  const mapStateToProps = state => ({
    selectedItem: selectPageSelectedItem(state, page),
    selectedMonth: selectPageSelectedMonth(state, page),
    selectedGroup: selectPageSelectedGroup(state, page),
    initialValues: selectInitialValues(state, page),
    searchText: selectPageSearchText(state, page)
  });
  return connect(mapStateToProps, mapActionsToProps)(withRouter(ComponentWithPageHistory));
};
