import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchAndSelect from './SearchAndSelect'
import { JitsuInput } from '../components-elements/jistu';

const className = 'flex';
const toggleClassNames = 'pt-8 ml-8 cursor-pointer underline';

class TagInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searching: true,
      searchValue: props.value.id ? [props.value] : [],
      textValue: ''
    };
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  toggle() {
    this.setState((state) => ({
      searching: !state.searching,
    }));
    if (!this.state.searching) {
      this.props.onChange({ id: this.state.searchValue[0] ? this.state.searchValue[0].actualId : null }, this.props.name)
      return;
    }
    this.props.onChange({ name: this.state.textValue }, this.props.name)
  }

  onChange(value) {
    this.setState({ searchValue: value })
    this.props.onChange({ id: value[0] ? value[0].actualId : null }, this.props.name);
  }

  onChangeText(value) {
    this.setState({ textValue: value })
    this.props.onChange({ name: value }, this.props.name);
  }

  render() {
    const { label, wrapperClassName, error } = this.props;
    const { textValue, searchValue } = this.state;
    return (<div className={wrapperClassName}>
      {this.state.searching ? <div className={className}>
        <div className="flex-1">
          <SearchAndSelect
            value={searchValue}
            valueKey="actualId"
            textKey="text"
            through="tag"
            label={label}
            onChange={this.onChange}
            isSingleSelect
            error={error}
          />
        </div>
        <div onClick={this.toggle} className={toggleClassNames}>Create new tag</div>
      </div> : <div className={className}>
        <div className="flex-1">
          <JitsuInput
            value={textValue}
            label={label}
            error={error}
            onChange={this.onChangeText}
            name="textValue"
          />
        </div>
        <div onClick={this.toggle} className={toggleClassNames}>Select existing tag</div>
      </div>}
    </div>);
  }
}

TagInput.defaultProps = {
  value: {},
  label: '',
  error: '',
  wrapperClassName: '',
};

TagInput.propTypes = {
  value: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  wrapperClassName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TagInput;