import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ShortTextDiv, ShortTextSpan, FullTextSpan } from './styled';

class ShortenedText extends PureComponent {
  constructor() {
    super();
    this.state = {
      isDisplayingShortText: true
    };
    // this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onClick = this.onClick.bind(this);
    // this.onMouseOut = this.onMouseOut.bind(this);
  }
  // onMouseEnter() {
  //   this.setState(() => ({ isDisplayingShortText: false }));
  // }
  // onMouseOut() {
  //   this.setState(() => ({ isDisplayingShortText: true }));
  // }
  onClick() {
    this.setState(state => ({ isDisplayingShortText: !state.isDisplayingShortText }));
  }
  render() {
    const {
      text, length, expansionType, classNames
    } = this.props;
    const className = `${classNames} ${expansionType} ${this.state.isDisplayingShortText ? 'shortened-text--collapsed' : 'shortened-text--expanded'}`;
    return (<ShortTextDiv className={className}>
      <ShortTextSpan>{(text || '').substr(0, length)}{text.length > length ? '...' : ''}</ShortTextSpan>
      <FullTextSpan>{text}</FullTextSpan>
    </ShortTextDiv>);
  }
}

ShortenedText.defaultProps = {
  expansionType: 'POP',
  text: '',
  classNames: '',
  length: 100
};

ShortenedText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  expansionType: PropTypes.string.isRequired,
  classNames: PropTypes.string.isRequired,
};

export default ShortenedText;