import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import * as Sentry from '@sentry/react';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker';
import { DATE, TIME, DATETIME } from '../constants/dateInputTypes';
import {
  JitsuField,
  JitsuLabel
} from './jistu'
import styled from 'styled-components';
import JitsuSentryFallbackComponent from './JitsuSentryFallbackComponent';

const DateDiv = styled.div`
  .react-date-picker__wrapper, .react-datetime-picker__wrapper, .react-time-picker__wrapper{
    border: none;
  }
  .react-date-picker__inputGroup__divider, .react-datetime-picker__inputGroup__divider, .react-time-picker__inputGroup__divider{
    margin: 4px
  }
`;

const ActualDatePicker = ({ dateInputType, value, onChange, name, className }) => { // eslint-disable-line
  const dateOnChange = val => onChange(val, name);
  const timeOnChange = val => onChange(val, name);
  switch (dateInputType) {
  case TIME:
    return <Sentry.ErrorBoundary fallback={JitsuSentryFallbackComponent} showDialog>
      <TimePicker
        value={value}
        onChange={timeOnChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        format="hh:mm a"
        timeCaption="Time"
        name={name}
        disableClock
        className={className}
        clearIcon={null}
        calendarIcon={null}
      />
    </Sentry.ErrorBoundary>;
  case DATETIME:
    return <Sentry.ErrorBoundary fallback={JitsuSentryFallbackComponent} showDialog>
      <DateTimePicker
        value={new Date(value)}
        onChange={dateOnChange}
        showTimeSelect
        timeFormat="hh:mm a"
        timeIntervals={5}
        disableClock
        format="y-MM-dd hh:mm a"
        timeCaption="time"
        name={name}
        className={className}
        clearIcon={null}
        calendarIcon={null}
      />
    </Sentry.ErrorBoundary>
  default:
    return <Sentry.ErrorBoundary fallback={JitsuSentryFallbackComponent} showDialog>
      <DatePicker
        value={new Date(value)}
        onChange={dateOnChange}
        format="y-MM-dd"
        name={name}
        disableClock
        className={className}
        clearIcon={null}
        calendarIcon={null}
      />
    </Sentry.ErrorBoundary>;
  }
}

class DateInput extends Component {
  render() {
    const {
      value, onChange, label, dateInputType, name, error, id, className, wrapperClassName, optional, required, tooltip
    } =this.props;
    return <JitsuField id={`datePicker-${id || name}`} error={error} className={wrapperClassName}>
      <JitsuLabel id={`${id || name}Label`} optional={optional} required={required} tooltip={tooltip}>
        {label}
      </JitsuLabel>
      <DateDiv className="appearance-none border rounded w-64 py-2 px-3 text-gray-700 h-10 leading-tight focus:outline-none">
        <ActualDatePicker
          value={value}
          onChange={onChange}
          dateInputType={dateInputType}
          name={name}
          className={`${className} adlam-date`}
        />
      </DateDiv>
    </JitsuField>;
  }
}

DateInput.defaultProps = {
  dateInputType: DATE,
  id: '',
  className: '',
  wrapperClassName: ''
};

DateInput.propTypes = {
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  optional: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.any,
  dateInputType: PropTypes.string.isRequired
};

export default DateInput;
