import React from 'react';
import PropTypes from 'prop-types';
import { CardWrapper, CardBody } from './styled';

const Card = ({ children, classname, headerText }) => 
  <CardWrapper className={`${classname}`}> 
    {headerText && <h3>{headerText}</h3>}
    <CardBody>{children}</CardBody>
  </CardWrapper>

Card.propTypes = {
  children: PropTypes.object,
  classname: PropTypes.string,
  headerText: PropTypes.string
}
export default Card;
