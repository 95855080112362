import { TERTIARY, PRIMARY } from '../constants/schoolTypes';
import { getCurrentUser } from '.';

const tertiarySubstitute = {
  classes: 'departments',
  class: 'department',
  Class: 'Department',
  Classes: 'Departments',
  
  teacher: 'lecturer',
  teachers: 'lecturers',
  Teacher: 'Lecturer',
  Teachers: 'Lecturers',

  subject: 'course',
  subjects: 'courses',
  Subject: 'Course',
  Subjects: 'Courses',

  note: 'course content',
  notes: 'course contents',
  Note: 'Course content',
  Notes: 'Course contents',

  School: 'University',
  Schools: 'Universities',
  school: 'university',
  schools: 'universities',

  news: 'notice board',
  News: 'Notice board',
};

const primarySubstitute = {
  colleges: 'class groups',
  college: 'class group',
  College: 'Class group',
  Colleges: 'Class groups',

  note: 'course content',
  notes: 'course contents',
  Note: 'Course content',
  Notes: 'Course contents',

  news: 'notice board',
  News: 'Notice board',

  student: 'pupil',
  students: 'pupils',
  Student: 'Pupil',
  Students: 'Pupils'
};

const textChange = (text) => {
  const { schoolType } = getCurrentUser();
  const substitutes = {
    [TERTIARY]: tertiarySubstitute,
    [PRIMARY]: primarySubstitute,
  }[schoolType];
  if (!substitutes) return text;
  return substitutes[text] || text;
};

export {
  textChange
};
