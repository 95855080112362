import { graphql } from 'react-apollo';
import classQueries from '../../queries/clazz';
import { subjectQueries, teacherQueries, collegeQueries } from '../../queries';
import { fields, fetches, getRequestData, updateClassListAfterCreate } from './helper';

export default {
  queries: () => [
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(teacherQueries.list, { name: 'teachers' }),
    graphql(collegeQueries.list, { name: 'colleges' }),
    graphql(classQueries.list, { name: 'classes' }),
    graphql(classQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { createClass } }) => {
            updateClassListAfterCreate(proxy, createClass);
          }
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: () => ({ name: '', code: '', description: '', subjects: [], address: '' }),
  success: (response, history) => { history.push(`/classes/${response.data.createClass.id}`); }
};
