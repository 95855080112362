import { schoolQueries } from '../../queries';
import { TEXT } from '../../constants/displayTypes';
import { tpl } from '../../constants/permissions';
import SchoolSummary from '../../components-graphql/SchoolSummary';

export default {
  dataKey: 'getSchool',
  query: schoolQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Name', text: data => data.name, id: 'name' },
    { type: TEXT, label: 'Address', text: data => data.address, id: 'address' },
    { type: TEXT, label: 'Website', text: data => data.website, id: 'website' },
    { type: TEXT, label: 'Timezone', text: data => data.timezone, id: 'timezone' },
  ],
  tabHeaders: ({ data: { getSchool: school }}) => [{
    text: 'School summary',
    value: tpl.SCHOOL_SUMMARY,
    props: { id: school.id },
    Component: SchoolSummary
  }],
};

