import { graphql } from 'react-apollo';
import { subjectQueries } from '../../queries';
import { requestNameMap } from '../../constants/requests';

export default {
  queries: id => [
    graphql(subjectQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(subjectQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  requestName: requestNameMap.DELETE_SUBJECT,
  dataKey: 'getSubject',
  success: (response, history, id) => { history.push(`/subjects/${id}`); }
};
