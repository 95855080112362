import { graphql } from 'react-apollo';
import newsQueries from '../../queries/news';
import { afterDelete } from '../../functions/query';
import { getCalenderQueries } from '../commonHelpers';
import { ADMIN, OVERSEER } from '../../constants/roles';

export default {
  queries: id => [
    graphql(newsQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(newsQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: ({ fullItemData, ...variables }) => mutate({
          variables,
          update(cache, { data }) {
            const { all, month } = getCalenderQueries(newsQueries.listByCreatedAt, fullItemData.createdAt);
            afterDelete(cache, all, 'getNewssByCreatedAt', data.deleteNews);
            afterDelete(cache, month, 'getNewssByCreatedAt', data.deleteNews);
          }
        })
      })
    })
  ],
  dataKey: 'getNews',
  success: (response, history) => { history.push('/news'); },
  cantDelete: (news, user) => ((!!news.restricted || news.subjects.length === 0) && ![ADMIN, OVERSEER].includes(user.userRole)),
  cantUpdate: (news, user) => ((!!news.restricted || news.subjects.length === 0) && ![ADMIN, OVERSEER].includes(user.userRole)),
};