import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ClickOutside extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside); // eslint-disable-line
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside); // eslint-disable-line
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickOutside();
    }
  }
  render() {
    const { element: WrapperElement, children, className } = this.props;
    return (<WrapperElement className={className} ref={this.setWrapperRef}>
      {children}
    </WrapperElement>);
  }
}

ClickOutside.defaultProps = {
  onClickOutside: () => {},
  className: '',
  element: 'div'
};

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  element: PropTypes.any,
  children: PropTypes.any.isRequired,
  className: PropTypes.any.isRequired,
};

export default ClickOutside;