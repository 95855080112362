import React, { Fragment, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withUser } from '../hocs';
import { SuspenseComponent } from '../components-elements';
import { needsAuthentication } from '../hocs/authenticated';
import PAGES from '../constants/pages';

const Students = lazy(() => import('../containers/Students'));
const Teachers = lazy(() => import('../containers/Teachers'));
const Parents = lazy(() => import('../containers/Parents'));
const Subjects = lazy(() => import('../containers/Subjects'));
const Assessments = lazy(() => import('../containers/Assessments'));
const Classes = lazy(() => import('../containers/Classes'));
const Notes = lazy(() => import('../containers/Notes'));
const News = lazy(() => import('../containers/News'));
const SchoolSettings = lazy(() => import('../containers/SchoolSettings'));
const GradingSystem = lazy(() => import('../containers/GradingSystem'));
const Colleges = lazy(() => import('../containers/Colleges'));
const Quizzes = lazy(() => import('../containers/Quizzes'));
const Questions = lazy(() => import('../containers/Questions'));

const RoutesFromMap = ({ user }) => user.userId
? <Fragment>
    <SuspenseComponent>
      <Route path={`/${PAGES.students}`} component={needsAuthentication(() => <Students/>, PAGES.students, true)}/>
      <Route path={`/${PAGES.teachers}`} component={needsAuthentication(() => <Teachers/>, PAGES.teachers, true)}/>
      <Route path={`/${PAGES.parents}`} component={needsAuthentication(() => <Parents/>, PAGES.parents, true)}/>
      <Route path={`/${PAGES.subjects}`} component={needsAuthentication(() => <Subjects/>, PAGES.subjects, true)}/>
      <Route path={`/${PAGES.assessments}`} component={needsAuthentication(() => <Assessments/>, PAGES.assessments, true)}/>
      <Route path={`/${PAGES.classes}`} component={needsAuthentication(() => <Classes/>, PAGES.classes, true)}/>
      <Route path={`/${PAGES.notes}`} component={needsAuthentication(() => <Notes/>, PAGES.notes, true)}/>
      <Route path={`/${PAGES.news}`} component={needsAuthentication(() => <News/>, PAGES.news, true)}/>
      <Route path={`/${PAGES.schools}`} component={needsAuthentication(() => <SchoolSettings/>, PAGES.schools, true)}/>
      <Route path={`/${PAGES.grades}`} component={needsAuthentication(() => <GradingSystem/>, PAGES.grades, true)}/>
      <Route path={`/${PAGES.colleges}`} component={needsAuthentication(() => <Colleges/>, PAGES.colleges, true)}/>
      <Route path={`/${PAGES.quizzes}`} component={needsAuthentication(() => <Quizzes/>, PAGES.quizzes, true)}/>
      <Route path={`/${PAGES.questions}`} component={needsAuthentication(() => <Questions/>, PAGES.questions, true)}/>
    </SuspenseComponent>
  </Fragment>
  : <Fragment/>;

RoutesFromMap.propTypes = {
  user: PropTypes.object.isRequired
};

export default withUser(RoutesFromMap);