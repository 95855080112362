import DateFns from 'date-fns';
import { minValue } from '../commonValidations';

export default ({
  firstName,
  lastName,
  phoneNumber,
  address,
  email,
  qualification,
  regNumber,
  nationality,
  dob
}) => ({
  firstName: firstName.length < 3 ? minValue(3) : null,
  lastName: lastName.length < 3 ? minValue(3) : null,
  email: email.length < 3 ? minValue(3) : null,
  address: address.length < 10 ? minValue(10) : null,
  qualification: qualification.length < 3 ? minValue(3) : null,
  phoneNumber: phoneNumber.length < 10 ? minValue(10) : null,
  regNumber: regNumber.length < 3 ? minValue(3) : null,
  nationality: !nationality ? 'Teacher must have a nationality' : null,
  dob: DateFns.format(dob, 'YYYY-MM-DD') > '1950-05-11' && DateFns.format(dob, 'YYYY-MM-DD') < '2009-05-11' ? null : 'Date is invalid'
});