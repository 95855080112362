import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import withUser from './withUser';
import { logoutUser, relocateUser } from '../functions';
import authorized from './authorized';

export const isAlreadyAuthenticated = (Component) => {
  const AuthenticatedComponent = (props) => {
    if (props.user.userId) {
      relocateUser('dashboard');
      return;
    }
    return <Component {...props} />;
  };

  AuthenticatedComponent.propTypes = {
    history: PropTypes.object,
    user: PropTypes.object.isRequired,
  };

  return withUser(AuthenticatedComponent);
};

export const needsAuthentication = (Component, page, showNothing, dontAuthorize) => {
  const AuthenticatedComponent = (props) => {
    if (props.user.userStatus === 'NEW' || props.user.userStatus === 'PASSWORD_RESET') {
      relocateUser('completeLogin');
      return <div/>;
    }
    if (props.user.userStatus === 'SUSPENDED') {
      relocateUser('userSuspended');
      return <div/>;;
    }
    if (!props.user.userId) {
      logoutUser();
      return <div/>;;
    }
    return <Component {...props} />;
  };

  AuthenticatedComponent.propTypes = {
    history: PropTypes.object,
    user: PropTypes.object.isRequired,
  };

  return dontAuthorize
    ? withRouter(withUser(AuthenticatedComponent, page, showNothing))
    : withRouter(withUser(authorized(AuthenticatedComponent, page, showNothing)));
};
