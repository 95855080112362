import { graphql } from 'react-apollo';
import { schoolQueries } from '../../queries';
import { fields, getRequestData, updateSchoolListAfterCreate } from './helper';

export default {
  queries: () => [
    graphql(schoolQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { createSchool } }) => {
            updateSchoolListAfterCreate(proxy, createSchool);
          }
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  initialValues: () => ({
    name: '',
    badgeUrl: '',
    address: ''
  }),
  success: (response, history) => { history.push(`/schools/${response.data.createSchool.id}`); }
};
