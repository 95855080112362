import React from 'react';
import PropTypes from 'prop-types';
import JitsuIcon from './JitsuIcon';

const JitsuMultiselectOption = ({
  option,
  onClickOption,
  selected,
  locked,
  children,
  searcher,
  id
}) => <div
  id={id}
  className={`cursor-pointer 
  w-full border-gray-100 
  rounded-t border-b 
  ${searcher ? '' : 'hover:bg-blue-100'}`}
  onClick={() => {
    if (!locked) {
      onClickOption(option);
    }
  }}
>
  <div className={`flex w-full items-center 
  p-2 pl-2 border-transparent ${selected ? 'border-l-2' : ''} 
  ${locked ? 'text-gray-400' : ''}
  relative border-blue-600`}>
    <div className={`w-full ${searcher ? '' : 'items-center flex'}`}>
      {
        locked && <JitsuIcon name="lock"/>
      }
      <div className="mx-2 leading-6">
        {children}
      </div>
    </div>
  </div>
</div>

JitsuMultiselectOption.defaultProps = {
  selected: false,
  locked: false,
  searcher: false,
  children: '',
  id: '',
  option: {},
  onClickOption: () => {}
};

JitsuMultiselectOption.propTypes = {
  selected: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  searcher: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  onClickOption: PropTypes.func.isRequired
};

export default JitsuMultiselectOption;