import { quizQueries } from '../../queries';
import { afterCreate, afterDelete } from '../../functions/query';
import { TEXT_INPUT, TEXT_EDITOR } from '../../constants/fieldTypes';
import validateQuiz from './validation';
import { commonFields } from '../commonHelpers';

export const updateQuizListAfterCreate = (proxy, quiz) => {
  const query = { query: quizQueries.list };
  afterCreate(proxy, query, 'getQuizzes', quiz);
};

export const updateQuizListAfterDelete = (proxy, quiz) => {
  const query = { query: quizQueries.list };
  afterDelete(proxy, query, 'getQuizzes', quiz);
};

export const fields = [
  () => ({
    name: 'name',
    type: TEXT_INPUT,
    label: 'Title',
    placeholder: 'What is ScreenLine Learn?',
    wrapperClassName: 'full',
    required: true
  }),
  // () => ({
  //   name: 'type',
  //   type: SELECT_INPUT,
  //   label: 'Type',
  //   valueKey: 'value',
  //   options: [{ value: 'RANDOM_PICKS', text: 'Random picks' }, { value: 'ALL_QUESTIONS', text: 'All questions' }]
  // }),
  () => ({
    name: 'description',
    type: TEXT_EDITOR,
    label: 'Description',
    placeholder: '',
    wrapperClassName: 'full',
    required: true
  }),
  props => commonFields.questionsSearchMultiSelect(props, { required: true }),
  () => ({
    name: 'duration',
    type: TEXT_INPUT,
    label: 'Duration (minutes)',
    placeholder: '60',
    size: 4,
    required: true,
    wrapperClassName: 'full'
  })
];

export const getRequestData = (_, requestData) => ({
  requestData: {
    ...requestData,
    questionsIds: requestData.questions.map(question => question.actualId),
    duration: parseInt(requestData.duration, 10),
  },
  errors: validateQuiz(requestData)
});
