import { isNumber } from '../../functions';
import { isTextEditorNotEmpty, minValue } from '../commonValidations';

export default ({ creditUnit, name, code, description, teacher, categories }) => ({
  creditUnit: !isNumber(creditUnit) ? 'credit must be a number' : null,
  name: name.length < 3 ? minValue(3) : null,
  code: code.length < 3 ? minValue(3) : null,
  description: isTextEditorNotEmpty(description) ? null : 'description can not be empty',
  teacher: !(teacher && teacher[0].actualId) ? 'teacher has to be selected' : null,
  categories: categories.length > 0 ? null : 'must belong to atleast one category'
});