import { graphql } from 'react-apollo';
import { classQueries, studentQueries } from '../../queries';
import { fields, fetches, getRequestData, watcher } from './helper';

export default {
  queries: () => [
    graphql(classQueries.list, { name: 'clazzes' }),
    graphql(studentQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData: (props, data) => getRequestData(props, data, { password: 'password' }),
  initialValues: () => ({
    firstName: '',
    lastName: '',
    dob: '2000-05-11',
    nationality: 'NG',
    countryOfResidence: 'NG',
    email: '',
    gender: 'Male',
    phoneNumber: '',
    class: '',
    address: '',
    regNumber: '',
    previousSchool: '',
    level: '',
    programme: '',
    parents: [],
    subjects: []
  }),
  success: (response, history) => { history.push(`/students/${response.data.createStudent.id}`); },
  watcher,
};


