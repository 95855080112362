import { commonFields } from '../commonHelpers';
import validateNews from './validation';
import { TEXT_INPUT, UPLOAD_INPUT, TEXT_EDITOR, SELECT_INPUT } from '../../constants/fieldTypes';
import { ADMIN, OVERSEER, TEACHER } from '../../constants/roles';
import { textChange } from '../../functions/textChange';

export const fields = [
  () => ({ name: 'topic', type: TEXT_INPUT, label: 'Topic', placeholder: 'News Topic', max: 100, required: true }),
  () => ({ name: 'excerpt', type: TEXT_INPUT, label: 'Excerpt', placeholder: 'Excerpt', max: 100, required: true }),
  () => ({ name: 'description', type: TEXT_EDITOR, label: 'Description', placeholder: 'New content', required: true }),
  () => ({ name: 'fileUrl', type: UPLOAD_INPUT, label: 'File', placeholder: 'Attach file', optional: true }),
  props => commonFields.subjectsMultiSelect(props, { required: props.user.userRole === TEACHER }),
  // ({ user: { userRole } }) => userRole === ADMIN ? commonFields.classesMultiSelect : null,
  ({ user: { userRole } }) => [ADMIN, OVERSEER].includes(userRole)
    ? {
      name: 'restricted',
      type: SELECT_INPUT,
      label: `Seen by staff only`,
      valueKey: 'value',
      options: [{ value: '1', text: 'Yes' }, { value: '0', text: 'No' }],
      ignoreFirstOption: true,
      tooltip: `If this is set to yes, the ${textChange('subjects')} selected will be deselected before saving.`
    }
    : null,
];

export const fetches = [{ dataKey: 'getClasses', name: 'clazzes' }, { dataKey: 'getSubjects', name: 'subjects' }];

export const getRequestData = ({ user }, requestData) => ({
  requestData: {
    ...requestData,
    subjectsIds: !!parseInt(requestData.restricted, 10) ? [] : requestData.subjects.map(subject => subject.actualId),
    teacherId: user.userId,
    restricted: parseInt(requestData.restricted, 10)
  },
  errors: validateNews(requestData, user)
});
