import { graphql } from 'react-apollo';
import { fields, fetches, getRequestData } from './helper';
import { subjectQueries, newsQueries, classQueries } from '../../queries';
import { afterCreate } from '../../functions/query';
import { getCalenderQueries } from '../commonHelpers';

export default {
  queries: () => [
    graphql(classQueries.list, { name: 'clazzes' }),
    graphql(subjectQueries.list, { name: 'subjects' }),
    graphql(newsQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update(cache, { data }) {
            const { all, month } = getCalenderQueries(newsQueries.listByCreatedAt, data.createNews.createdAt);
            afterCreate(cache, all, 'getNewssByCreatedAt', data.createNews);
            afterCreate(cache, month, 'getNewssByCreatedAt', data.createNews);
          }
        }),
      })
    })
  ],
  fields,
  fetches,
  getRequestData,
  initialValues: () => ({
    topic: '',
    description: '',
    fileUrl: '',
    classes: [],
    subjects: [],
    excerpt: '',
    restricted: 0
  }),
  success: (response, history) => { history.push(`/news/${response.data.createNews.id}`); }
};
