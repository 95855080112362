import ReactDOM from 'react-dom';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import Routes from './routes';
import client from './setup/client';
import store from './setup/store';
import './assets/main.css';

ReactDOM.render(<ApolloProvider client={client}>
  <Provider store={store}>
    <Routes/>
  </Provider>
</ApolloProvider>, document.getElementById('root')); // eslint-disable-line
