import DateFns from 'date-fns';
import { TERTIARY } from '../../constants/schoolTypes';
import { minValue } from '../commonValidations';

export default ({
  firstName,
  lastName,
  phoneNumber,
  address,
  email,
  regNumber,
  class: clazz,
  nationality,
  previousSchool,
  dob,
  level,
  programme,
  countryOfResidence,
  stateOfResidence
}, user) => {
  return {
    firstName: firstName.length < 3 ? minValue(3) : null,
    lastName: lastName.length < 3 ? minValue(3) : null,
    email: email.length < 3 ? minValue(3) : null,
    countryOfResidence: !countryOfResidence ? 'this is required' : null,
    level: user.schoolType === TERTIARY ? (!level ? 'this is required' : null) : null,
    stateOfResidence: !stateOfResidence ? 'this is required' : null,
    programme: user.schoolType === TERTIARY ? (programme.length < 3 ? minValue(3) : null) : null,
    address: address.length < 10 ? minValue(10) : null,
    phoneNumber: phoneNumber.length < 10 ? minValue(10) : null,
    regNumber: regNumber.length < 3 ? minValue(3) : null,
    class: !clazz ? 'this is required' : null,
    nationality: !nationality ? 'this is required' : null,
    previousSchool: previousSchool.length < 3 ? minValue(3) : null,
    dob: DateFns.format(dob, 'YYYY-MM-DD') > '1950-05-11' && DateFns.format(dob, 'YYYY-MM-DD') < '2018-05-11' ? null : 'Date is invalid'
  };
}