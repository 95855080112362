import { periodQueries } from '../../queries';
import { changeMilitaryTimeToNormalTime, changeTimestampToDate } from '../../functions';
import { TEXT, LIST } from '../../constants/displayTypes';
import { tpl } from '../../constants/permissions';
import AttendanceSheets from '../../components-graphql/AttendanceSheet';
import { textChange } from '../../functions/textChange';
import { ADMIN, OVERSEER } from '../../constants/roles';

export default {
  dataKey: 'getPeriod',
  query: periodQueries.item,
  goBack: (history) => history.push('/periods'),
  goBackBtnValue: 'Back to calendar',
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Description', text: data => data.name, id: 'name' },
    { type: TEXT, label: 'Date', text: data => changeTimestampToDate(data.date, 'YYYY-MM-DD'), id: 'date' },
    { type: TEXT, label: 'Starting at', text: data => changeMilitaryTimeToNormalTime(data.startTime), id: 'startTime' },
    { type: TEXT, label: 'Ending at', text: data => changeMilitaryTimeToNormalTime(data.endTime), id: 'endTime' },
    { type: TEXT, label: textChange('Subject'), text: data => data.subject ? `${data.subject.name} (${data.subject.code})`: '', id: 'subject' },
    { type: LIST, label: textChange('Notes'), list: data => data.notes.map(note => ({ ...note, name: note.topic })), id: 'notes' },
  ],
  tabHeaders: ({ data: { getPeriod }, user }) => {
    if (getPeriod.subject && ([ADMIN, OVERSEER].includes(user.userRole) || (user.subjectsIds || []).includes(getPeriod.subject.id))) {
      return [{
        text: 'Attendance sheets',
        value: tpl.ATTENDANCE_SHEET,
        props: { period: getPeriod },
        Component: AttendanceSheets
      }]
    }
    return [];
  }
};
