import { lazy } from 'react';
import { getTime, format } from 'date-fns';
import { assessmentQueries } from '../../queries';
import { TEXT, LIST } from '../../constants/displayTypes';
import { changeTimestampToDate, getCurrentUser } from '../../functions';
import { tpl, tabPermissions } from '../../constants/permissions';
import { STUDENT } from '../../constants/roles';

const ScoreSheets = lazy(() => import('../../components-graphql/ScoreSheets'));
const SubmitAssessment = lazy(() => import('../../components-graphql/SubmitAssessment'));

export default {
  dataKey: 'getAssessment',
  query: assessmentQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Topic', text: data => data.topic, id: 'topic' },
    { type: TEXT, label: 'Created', text: data => changeTimestampToDate(data.createdAt), id: 'createdAt' },
    { type: TEXT, label: 'Description', text: data => data.description, id: 'description', editor: true },
    { type: LIST, label: 'Subjects', list: data => data.subjects.map(subject => ({ ...subject, name: subject.code })), id: 'subjects' },
    { type: TEXT, label: 'Posted By', text: ({ teacher }) => `${teacher.firstName} ${teacher.lastName}`, id: 'postedBy' },
    { type: TEXT, label: 'Overall Mark', text: data => data.overallMark, id: 'overallMark' },
    { type: TEXT, label: 'Type', text: data => data.quiz ? '' : data.type, id: 'type' },
    {
      type: TEXT,
      label: 'Quiz',
      text: data => data.quiz ? data.quiz.name : '',
      id: 'quiz',
      link: true,
      to: data => getCurrentUser().userRole !== STUDENT
        ? `/quizzes/${data.quiz ? data.quiz.id : ''}`
        : `/qa/${data.id ? data.id : ''}`
    },
    { type: TEXT, label: 'Enforce attendance rule', text: data => data.enforceAttendanceRule ? 'ON' : 'OFF', id: 'enforceAttendanceRule' },
    { type: TEXT, label: 'Enforce submission', text: data => data.enforceSubmission ? 'ON' : 'OFF', id: 'enforceSubmission' },
    { type: TEXT, label: 'Deadline', text: data => changeTimestampToDate(data.deadline), id: 'deadline' },
    { type: TEXT, label: 'Last modified', text: data => changeTimestampToDate(data.updatedAt), id: 'updatedAt' },
  ],
  tabHeaders: props => [
    props.data.getAssessment.subjects.length > 0
      ? {
        text: 'Score Sheets',
        value: tpl.ASSESSMENT_SCORE_SHEETS,
        props: {
          subjects: props.data.getAssessment.subjects,
          overallScore: parseInt(props.data.getAssessment.overallMark, 10),
          allowedToSubmit: tabPermissions.assessments[props.user.userRole].includes(tpl.SUBMIT_ASSESSMENT_SCORE_SHEETS),
          assessment: props.data.getAssessment
        },
        Component: ScoreSheets
      } : {},
    props.user.userRole === STUDENT && props.data.getAssessment.subjects.length > 0
    ? {
      text: 'Start and View Grade',
      value: tpl.SUBMIT_ASSESSMENT,
      props: {
        subjectId: props.data.getAssessment.subjects[0].id,
        overall: props.data.getAssessment.overallMark,
        assessment: props.data.getAssessment
      },
      Component: SubmitAssessment
    } : {}
  ].filter(tab => {
    if (tab.props && tab.props.url === "") {
      return false;
    }
    return tab.props && tab.props.url ? props.data.getAssessment.fileUrl : true
  }),
  cantUpdate: assessment => getTime(format(new Date(), 'YYYY-MM-DD HH:mm')) > assessment.deadline
};
