import CustomComponent from './CustomComponent';

function getBlockStyle(block) {
  switch (block.getType()) {
  case 'blockquote': return 'RichEditor-blockquote';
  case 'new-block-type-name':
    return {
      component: CustomComponent,
      editable: false,
    };
  default: return null;
  }
}

export default getBlockStyle;