import classQueries from '../../queries/clazz';
import { afterCreate, afterDelete } from '../../functions/query';
import { TEXT_INPUT, MULTISELECT, SELECT_INPUT, TEXT_EDITOR } from '../../constants/fieldTypes';
import validateClass from './validation';
import { RED } from '../../constants/colors';
import { commonFields } from '../commonHelpers';
import { textChange } from '../../functions/textChange';
import { TERTIARY } from '../../constants/schoolTypes';

export const updateClassListAfterCreate = (proxy, clazz) => {
  const query = { query: classQueries.list };
  afterCreate(proxy, query, 'getClasses', clazz);
};

export const updateClassListAfterDelete = (proxy, clazz) => {
  const query = { query: classQueries.list };
  afterDelete(proxy, query, 'getClasses', clazz);
};

export const fields = [
  () => ({
    name: 'name',
    type: TEXT_INPUT,
    label: 'Name',
    placeholder: 'Name of class/college',
    required: true
  }),
  () => ({
    name: 'code',
    type: TEXT_INPUT,
    label: 'Code',
    placeholder: 'CSC',
    required: true
  }),
  (props) => ({
    name: 'collegeId',
    type: SELECT_INPUT,
    label: 'College',
    options: props.colleges.getColleges.map((college) => ({
      ...college,
      text: college.name,
      value: college.id
    })),
    required: true,
  }),
  (props) => props.user.schoolType !== TERTIARY ? {
    name: 'next',
    type: SELECT_INPUT,
    label: 'Possible next class',
    optional: true,
    options: props.classes.getClasses
    .filter((clazz) => clazz.id !== (props.data && props.data.getClass ? props.data.getClass.id : ''))
    .map((clazz) => ({
      ...clazz,
      text: clazz.name,
      value: clazz.id
    }))
    .concat({ text: 'ALUMNI', value: 0 }),
  } : null,
  commonFields.teacherSearchSingleSelect({ label: `${textChange('Class')} head`, required: true }),
  (props) => props.user.schoolType === TERTIARY
    ? {
      name: 'highestYear',
      type: SELECT_INPUT,
      label: 'How many years?',
      required: true,
      ignoreFirstOption: true,
      options: [...Array(props.user.settings.maxYears || 5)].map((_, index) => ({
        text: index + 1,
        value: index + 1
      })),
    }
    : {
      name: 'level',
      type: SELECT_INPUT,
      label: 'Year equivalent',
      required: true,
      ignoreFirstOption: true,
      options: [...Array(props.user.settings.maxYears || 5)].map((_, index) => ({
        text: `Year ${index + 1}`,
        value: index + 1
      })),
    },
  () => ({
    name: 'description',
    type: TEXT_EDITOR,
    label: 'Description',
    placeholder: 'What the class is about',
    textarea: true,
    required: true
  }),
  () => ({
    name: 'address',
    type: TEXT_INPUT,
    label: `Location of ${textChange('class')} on campus`,
    placeholder: 'Peel campus',
    optional: true
  }),
  props => ({
    name: 'subjects',
    type: MULTISELECT,
    label: `${textChange('Subjects')} offered in ${textChange('class')}`,
    valueKey: 'id',
    textKey: 'code',
    options: props.subjects.getSubjects || [],
    optional: true
  }),
];

export const getRequestData = (props, data) => ({
  requestData: {
    ...data,
    subjectsIds: data.subjects.map(subject => parseInt(subject.id, 10)),
    teacherId: data.teacher ? data.teacher[0].actualId : undefined,
    collegeId: parseInt(data.collegeId, 10),
    next: parseInt(data.next, 10) === 0 ? null : parseInt(data.next, 10),
    final: parseInt(data.next, 10) === 0 ? 1 : 0, 
    highestYear: parseInt(data.highestYear, 10),
    colour: RED,
    location: 'IN SCHOOL',
    photoUrl: 'NO PHOTO',
    level: parseInt(data.level, 10) || 1,
  },
  errors: validateClass(data)
});

export const fetches = [
  { dataKey: 'getClasses', name: 'clazzes' }, 
  { dataKey: 'getTeachers', name: 'teachers' },
  { dataKey: 'getColleges', name: 'colleges' },
];

