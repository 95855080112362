import { quizQueries } from '../../queries';
import { TEXT, LIST } from '../../constants/displayTypes';

export default {
  dataKey: 'getQuiz',
  query: quizQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Title', text: data => data.name, id: 'name' },
    { type: TEXT, label: 'Duration', text: data => data.duration, id: 'duration' },
    // { type: TEXT, label: 'Type', text: data => data.type, id: 'type' },
    { type: TEXT, label: 'Description', text: data => data.description, id: 'description', editor: true },
    { type: LIST, label: 'Questions', list: data => data.questions, id: 'questions' },
  ]
};
