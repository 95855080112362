import React from 'react';
import PropTypes from 'prop-types';
import { InitialsSpan } from './styled';
import { MdPerson } from 'react-icons/md';

const Initials = ({ firstName = '?', lastName = '?', className }) => <InitialsSpan className={className}>
  <MdPerson/>
</InitialsSpan>;

Initials.defaultProps = {
  className: 'Initials'
};

Initials.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Initials;