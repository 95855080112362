import React from 'react';
import PropTypes from 'prop-types';
import { NOT_FOUND } from '../../constants';
import { LOCAL_HOST } from '../../constants/api';
// import JitsuTooltip from '../JitsuTooltip';

const DownloadLink = ({ url, text, className }) => <div className={className}>
  {
    url !== '' && url !== NOT_FOUND
      ? <a href={`${LOCAL_HOST}/files${url}`} target="_blank" rel="noopener noreferrer">
        <span className="bg-purple-500 text-white text-xs px-1 cursor-pointer rounded">{text}</span>
      </a>
      : <div>{
        url === NOT_FOUND
          // ? <JitsuTooltip tooltipText="contact the person" position="top">
          //   <div>File not reachable</div>
          // </JitsuTooltip>
          ? 'File not reachable'
          : 'No Downloadable Content'
      }</div>
  }
</div>

DownloadLink.defaultProps = {
  url: '',
  text: 'Download',
  className: '',
};

DownloadLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default DownloadLink;