import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { dataFetchingComponent } from '.';
import { List } from '../components-elements';

/**
 * query: graphql query
 * options: graphql query options
 * listProps = {}: props to be passed to list component
 * ItemComponentAndProps: Item component
 * wrapperComponentAndProps: Component wrapper the whole list
 * addLink: A link to where you can add more to the list
 * */
const createList = (
  query,
  options,
  { dataKey, ...rest },
  onlyList = false,
  ItemComponentAndProps = {},
  wrapperComponentAndProps = {},
) => {
  class Lister extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isExpanded: false
      };
      this.toggle = this.toggle.bind(this);
    }
    toggle() {
      this.setState(state => ({ isExpanded: !state.isExpanded }));
    }
    render() {
      const { data } = this.props;
      const { Component: Wrapper = 'div', props: wrapperProps = {} } = wrapperComponentAndProps;
      const list = data[dataKey];
      return (<Wrapper {...wrapperProps} rowsno={`${list.length}`} className="h-full">
        <List list={list} {...rest} itemComponentAndProps={ItemComponentAndProps}/>
      </Wrapper>);
    }
  }

  Lister.defaultProps = {
    data: { [dataKey]: [] }
  };

  Lister.propTypes = {
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };
  return graphql(query, options || {})(dataFetchingComponent(withRouter(Lister)));
};

export default createList;