import { LAPTOP_SCREEN } from './screenSizes';

const mainTray = `
height: auto;
@media screen and (${LAPTOP_SCREEN}) {
  margin: 33px 33px 0 33px;
  height: calc(100% - 66px);
  overflow: auto;
}
`

export {
  mainTray
}
