import styled from 'styled-components';
import { mainTray } from '../constants/styles';

export const FormWrapperDiv = styled.div`
  ${mainTray}
  width: calc(100% - 30px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  grid-column-gap: 20px;
  box-shadow:none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    >div{
      padding: 8px;
    }
  }

  &.collegesFormWrapper{
    display: flex;
    flex-direction: column;
  }

  &.assessmentsFormWrapper,
  &.subjectsFormWrapper,
  &.notesFormWrapper, &.newsFormWrapper, &.classesFormWrapper, &.questionsFormWrapper, &.quizzesFormWrapper{
    .topic, .notes--topic, .news--topic, .questions--content, .full, .quizzes--description{
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .assessments--description, .notes--description, .news--description, .classes--description{
      grid-column-start: 1;
      grid-column-end: 3;
      textarea{
        height: 100px;
      }
    }
  }

  .form-buttons {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 16px;
  }
`;
