import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { changeTimestampToDate } from '../../functions';
import DownloadLink from '../DownloadLink';
import { ShortenedText } from '../TextHelpers';

const NoteRow = ({ item, selectedItem }) => {
  const classNames = `
  ${selectedItem && selectedItem.id === item.id ? 'bg-blue-200' : ''} 
  p-2 text-sm rounded mb-2 
  `;
  return <div
    className={classNames}
    id={`notesItem-${item.id}`}
  >
    <Link to={`/notes/${item.id}`} id={`notesItemLink-${item.id}`} className="underline cursor-pointer">
      <ShortenedText text={item.topic} expansionType={'POP'} classNames="topic"/>
    </Link>
    <DownloadLink url={item.fileUrl || ''} prefix="note" className="mt-1 text-xs" />
    <div className="text-xs mt-1">{changeTimestampToDate(item.createdAt)}</div>
  </div>
};

NoteRow.defaultProps = {
  selectedItem: {}
};

NoteRow.propTypes = {
  item: PropTypes.object.isRequired,
  selectedItem: PropTypes.object.isRequired,
};

export default NoteRow;