import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import { periodQueries } from '../../queries';
import { changeTimestampToDate, changePeriodsToEvents, selectSlot } from '../../functions';
import { withPageHistory, dataFetchingComponent, withUser } from '../../hocs';
import { SuspenseComponent, Scheduler as Calendar } from '../../components-elements';
import PAGES from '../../constants/pages';
import { JitsuMainWrapper } from '../../components-elements/jistu';

const CalendarWrappedWithQueries = ({
  data: { getPeriods },
  history,
  user,
  selectedItem = {},
  setInitialValuesForCreateForm,
  defaultView,
  views
}) => {
  const onSelectSlot = ({ start }) => {
    if (setInitialValuesForCreateForm) {
      setInitialValuesForCreateForm('periods', { date: `${start.getFullYear()}-${start.getMonth()}-${start.getDate()}` })
    }
    selectSlot(history, user);
  }
  return (<JitsuMainWrapper className="timetable">
    <SuspenseComponent>
      <Calendar
        events={changePeriodsToEvents(getPeriods)}
        endAccessor="endDate"
        startAccessor="startDate"
        onSelectEvent={(event) => { history.push(`/periods/${event.id}`); }}
        onSelectSlot={onSelectSlot}
        defaultDate={selectedItem.date ? new Date(changeTimestampToDate(selectedItem.date, 'YYYY-MM-DD HH:mm:ss')) : new Date()}
        views={views}
        defaultView={defaultView}
      />
    </SuspenseComponent>
  </JitsuMainWrapper>);
};

CalendarWrappedWithQueries.defaultProps = {
  periods: { getPeriods: [] },
  selectedItem: {}
};

CalendarWrappedWithQueries.propTypes = {
  periods: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  selectedItem: PropTypes.object.isRequired,
  setInitialValuesForCreateForm: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  views: PropTypes.array,
  defaultView: PropTypes.string,
};

export default graphql(periodQueries.list)(
  withRouter(
    dataFetchingComponent(
      withUser(
        withPageHistory(
          CalendarWrappedWithQueries,
          PAGES.periods
        )
      )
    )
  )
);