import React, { Component } from 'react';
import { Offline, Online } from 'react-detect-offline';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import withUser from '../../hocs/withUser';
import { permissionLevels, permissions } from '../../constants/permissions';
import { deleteToken, logoutUser } from '../../functions';
import { PARENT, OVERSEER, SEER, ADMIN } from '../../constants/roles';
import ClickOutside from '../ClickOutside';
import { ROUTES } from '../../constants/routes';
import { textChange } from '../../functions/textChange';
import JitsuButton from '../JitsuButton';
import JitsuIcon from '../JitsuIcon';
import { Fragment } from 'react';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
    this.toggle = this.toggle.bind(this);
    this.hideNav = this.hideNav.bind(this);
  }
  toggle() {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  }
  hideNav() {
    this.setState(() => ({ isExpanded: false }));
  }
  render() {
    const { user, history, match: { params: { page } } } = this.props;
    const { isExpanded } = this.state;
    const linkClassNames = `block px-4 py-2 text-gray-800 hover:bg-black hover:text-white`;
    if (!user.userRole) {
      logoutUser();
      return <div/>;
    }
    return (<div className="flex pl-4 mt-0 md:mt-0 xl:mt-0 lg:mt-0">
      <div className="flex-none md:flex lg:flex xl:flex">
        <h1 className="text-lg pt-4" style={{ fontSize: '1.875rem' }}>
          {textChange(ROUTES[page]) || ''}
        </h1>
        {permissions[page] && permissions[page][user.userRole].includes(permissionLevels.CREATE)
          && <Fragment>
            <div className="flex-none my-4 md:my-0 md:py-4 lg:my-0 lg:py-4 xl:my-0 xl:py-4 md:pl-4 lg:pl-4 xl:pl-4">
              <JitsuButton
                onClick={() => history.push(`/${page}/create`)}
                bordered
                id="newButton"
                name="newButton"
                value="Add new"
              />
            </div>
          </Fragment>
        }
      </div>
      <ClickOutside
        onClickOutside={this.hideNav}
        className="flex-1 justify-end flex p-4"
      >
        <div className="md:w-64 lg:w-64 xl:w-64 max-h-64 flex flex-col relative z-20">
          <div className="justify-end flex">
            <button
              onClick={this.toggle}
              className="block h-8 w-8 rounded-full overflow-hidden border-2 
              border-gray-600 focus:outline-none focus:border-white">
              <Online>
                <div className="bg-green-600 w-full h-full p-1 rounded-full">
                  <JitsuIcon className="h-4 w-4 object-cover pl-1" name="user" />
                </div>
              </Online>
              <Offline>
                <div className="bg-red-600 w-full h-full p-1 rounded-full">
                  <JitsuIcon className="h-4 w-4 object-cover pl-1" name="user" />
                </div>
              </Offline>
            </button>
          </div>
          <div
            className={`${isExpanded ? 'block' : 'hidden'} mt-8 py-2 w-48 bg-white rounded-lg shadow-xl absolute right-0 top-2`}>
            <NavLink to="/profile" className={linkClassNames}>Profile</NavLink>
            {![PARENT, OVERSEER, ADMIN, SEER].includes(user.userRole)
              && <NavLink to="/performance" className={linkClassNames}>Performance</NavLink>}
            {user.userRole === OVERSEER &&
              <NavLink to="/changeSchool" className={linkClassNames}>Change school</NavLink>}
            <NavLink to="/login" onClick={deleteToken} className={linkClassNames}>Sign Out</NavLink>
          </div>
        </div>
      </ClickOutside>
    </div>);
  }
}

TopBar.propTypes = {
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withUser(withRouter(TopBar));