import { format } from 'date-fns';
import { SEARCH_MULTISELECT, MULTISELECT } from '../constants/fieldTypes';
import createList from '../hocs/createList';
import { LINK } from '../constants/searchResultItemTypes';
import { getCurrentUser, getMonthData } from '../functions';
import { ADMIN } from '../constants/roles';
import { textChange } from '../functions/textChange';

export const commonFields = {
  subjectsSearchSingleSelect: () => ({
    name: 'subject',
    type: SEARCH_MULTISELECT,
    label: textChange('Subject'),
    placeholder: 'subject',
    displayTextFcn: selection => selection.text || selection.code,
    valueKey: 'actualId',
    textKey: 'text',
    through: 'subject',
    exempt: [ADMIN],
    isSingleSelect: true,
  }),
  teacherSearchSingleSelect: (overrides = {}) => () => ({
    name: 'teacher',
    type: SEARCH_MULTISELECT,
    label: `${textChange('Subject')} lead`,
    placeholder: 'lead',
    displayTextFcn: selection => selection.text || selection.firstName,
    valueKey: 'actualId',
    textKey: 'text',
    through: 'teacher',
    exempt: [ADMIN], 
    isSingleSelect: true,
    ...overrides
  }),
  notesSearchMultiSelect: () => ({
    name: 'notes',
    type: SEARCH_MULTISELECT,
    label: textChange('Notes'),
    placeholder: 'notes',
    displayTextFcn: selection => selection.text || selection.name,
    through: 'note',
    valueKey: 'actualId',
    textKey: 'text',
    optional: true
  }),
  subjectsSearchMultiSelect: () => ({
    name: 'subjects',
    type: SEARCH_MULTISELECT,
    label: textChange('Subjects'),
    placeholder: 'subjects',
    displayTextFcn: selection => selection.text || selection.code,
    valueKey: 'actualId',
    textKey: 'text',
    through: 'subject',
  }),
  questionsSearchMultiSelect: (props, fieldProps = {}) => ({
    name: 'questions',
    type: SEARCH_MULTISELECT,
    label: 'Questions',
    placeholder: 'questions',
    displayTextFcn: selection => selection.text || selection.name,
    valueKey: 'actualId',
    textKey: 'text',
    through: 'question',
    ...fieldProps,
  }),
  quizSearchSingleSelect: (props, overrides = {}) => ({
    name: 'quiz',
    type: SEARCH_MULTISELECT,
    label: 'Quiz',
    placeholder: 'quiz',
    displayTextFcn: selection => selection.text || selection.code,
    valueKey: 'actualId',
    textKey: 'text',
    through: 'quiz',
    isSingleSelect: true,
    ...overrides
  }),
  classesMultiSelect: props => ({
    name: 'classes',
    type: MULTISELECT,
    label: textChange('Classes'),
    valueKey: 'id',
    textKey: 'name',
    options: props.clazzes.getClasses || []
  }),
  subjectsMultiSelect: (props, fieldProps = {}) => ({
    name: 'subjects',
    type: MULTISELECT,
    label: textChange('Subjects'),
    valueKey: 'id',
    textKey: 'text',
    ...fieldProps,
    options: (props.subjects.getSubjects || []).map(subject => ({ ...subject, actualId: subject.id, text: `${subject.name} (${subject.code})` }))
  })
};

export const createListInTab = (query, props, Component, link, WrapperComponentAndProps, addLink) => createList(
  query,
  {
    options: ({ id }) => ({ variables: ({ subjectId: id }) }),
    skip: ({ onScreen, id }) => !(onScreen && id)
  },
  props,
  true,
  {
    Component,
    createProps: item => ({
      item,
      resultItemType: LINK,
      getTo: () => `/${link}/${item.id}`
    })
  },
  WrapperComponentAndProps,
  addLink
);

export const getCalenderQueries = (query, itemDate) => {
  const { semesterEndDate, semesterStartDate } = getCurrentUser();
  const all = {
    query,
    variables: { endDate: semesterEndDate, startDate: semesterStartDate }
  };

  const createdAt = new Date(itemDate * 1);
  const createdAtMonth = format(createdAt, 'MM');
  const { startDate: monthStartDate, endDate: monthEndDate } = getMonthData(createdAt, createdAtMonth === semesterStartDate.split('-')[1], createdAtMonth === semesterEndDate.split('-')[1]);
  const month = {
    query,
    variables: { endDate: monthEndDate, startDate: monthStartDate }
  };
  return { all, month };
};
