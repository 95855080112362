import React from 'react';
import PropTypes from 'prop-types';

const StyleButton = ({ style, onToggle, active, label }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  }

  const className = !active ? 'RichEditor-styleButton' : 'RichEditor-styleButton RichEditor-activeButton'

  return (
    <span className={className} onMouseDown={onMouseDown}>
      { label }
    </span>
  );
}

StyleButton.propTypes = {
  style: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export default StyleButton;
