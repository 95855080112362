import { ADMIN, STUDENT, PARENT, TEACHER, SEER, OVERSEER, SUBJECT_ADMIN } from "./roles";

const permissionLevels = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  PAGE: 'PAGE'
};


const { CREATE, DELETE, UPDATE, PAGE } = permissionLevels;

const tpl = {
  ATTENDANCE_SHEET: 'ATTENDANCE_SHEET',
  SUBMIT_ATTENDANCE_SHEET: 'SUBMIT_ATTENDANCE_SHEET',
  ASSESSMENT_SCORE_SHEETS: 'ASSESSMENT_SCORE_SHEETS',
  ASSESSMENT_PREVIEW: 'ASSESSMENT_PREVIEW',
  SUBMIT_ASSESSMENT_SCORE_SHEETS: 'SUBMIT_ASSESSMENT_SCORE_SHEETS',
  SUBMIT_ASSESSMENT: 'SUBMIT_ASSESSMENT',
  CLASS_STUDENTS: 'CLASS_STUDENTS',
  CLASS_NEWS: 'CLASS_NEWS',
  SUBMIT_ASSESSMENT_PERCENTAGE: 'SUBMIT_ASSESSMENT_PERCENTAGE',
  SUBJECT_NOTES: 'SUBJECT_NOTES',
  SUBJECT_NEWS: 'SUBJECT_NEWS',
  SUBJECT_STUDENTS: 'SUBJECT_STUDENTS',
  SUBJECT_ASSESSMENTS: 'SUBJECT_ASSESSMENTS',
  SUBJECT_APPLICATIONS: 'SUBJECT_APPLICATIONS',
  ARCHIVE_DATA: 'ARCHIVE_DATA',
  SCHOOL_SUMMARY: 'SCHOOL_SUMMARY',
  STUDENT_PERFORMANCE: 'STUDENT_PERFORMANCE',
  STUDENT_SUBJECTS_PERFORMANCE: 'STUDENT_SUBJECTS_PERFORMANCE',
  STUDENT_SUBJECTS_REMOVAL: 'STUDENT_SUBJECTS_REMOVAL',
  STUDENT_UPDATE_STATUS: 'STUDENT_UPDATE_STATUS',
  STUDENT_RESULT_MANAGEMENT: 'STUDENT_RESULT_MANAGEMENT',
  STUDENT_ATTENDANCE_RECORD: 'STUDENT_ATTENDANCE_RECORD',
  TEACHER_PERFORMANCE: 'TEACHER_PERFORMANCE',
  TEACHER_SUBJECTS_PERFORMANCE: 'TEACHER_SUBJECTS_PERFORMANCE',
  TEACHER_SUBJECTS_REMOVAL: 'TEACHER_SUBJECTS_REMOVAL',
  TEACHER_UPDATE_STATUS: 'TEACHER_UPDATE_STATUS',
  PARENT_UPDATE_STATUS: 'PARENT_UPDATE_STATUS',
  REMOVE_CLASS_FROM_SUBJECT: 'REMOVE_CLASS_FROM_SUBJECT',
  REMOVE_SUBJECT_FROM_CLASS: 'REMOVE_SUBJECT_FROM_CLASS',
  POST_NEWS: 'POST_NEWS',
  POST_ASSESSMENTS: 'POST_ASSESSMENTS',
  POST_NOTES: 'POST_NOTES',
  REMOVE_KIDS_FROM_PARENTS: 'REMOVE_KIDS_FROM_PARENTS'
};

let tabPermissions = {
  students: {
    [ADMIN]: [
      tpl.STUDENT_PERFORMANCE,
      tpl.STUDENT_SUBJECTS_PERFORMANCE,
      tpl.STUDENT_SUBJECTS_REMOVAL,
      tpl.STUDENT_UPDATE_STATUS,
      tpl.STUDENT_RESULT_MANAGEMENT,
      tpl.STUDENT_ATTENDANCE_RECORD
    ],
    [SEER]: [tpl.STUDENT_PERFORMANCE, tpl.STUDENT_SUBJECTS_PERFORMANCE, tpl.STUDENT_ATTENDANCE_RECORD],
    [OVERSEER]: [
      tpl.STUDENT_PERFORMANCE,
      tpl.STUDENT_SUBJECTS_PERFORMANCE,
      tpl.STUDENT_SUBJECTS_REMOVAL,
      tpl.STUDENT_UPDATE_STATUS,
      tpl.STUDENT_RESULT_MANAGEMENT,
      tpl.STUDENT_ATTENDANCE_RECORD
    ],
    [STUDENT]: [],
    [PARENT]: [tpl.STUDENT_PERFORMANCE, tpl.STUDENT_SUBJECTS_PERFORMANCE, tpl.STUDENT_ATTENDANCE_RECORD],
    [TEACHER]: [tpl.STUDENT_PERFORMANCE, tpl.STUDENT_SUBJECTS_REMOVAL, tpl.STUDENT_SUBJECTS_PERFORMANCE, tpl.STUDENT_ATTENDANCE_RECORD],
    [SUBJECT_ADMIN]: [tpl.STUDENT_PERFORMANCE, tpl.STUDENT_SUBJECTS_PERFORMANCE, tpl.STUDENT_ATTENDANCE_RECORD],
  },
  teachers: {
    [ADMIN]: [tpl.TEACHER_PERFORMANCE, tpl.TEACHER_SUBJECTS_PERFORMANCE, tpl.TEACHER_SUBJECTS_REMOVAL, tpl.TEACHER_UPDATE_STATUS],
    [SEER]: [tpl.TEACHER_PERFORMANCE, tpl.TEACHER_SUBJECTS_PERFORMANCE],
    [OVERSEER]: [tpl.TEACHER_PERFORMANCE, tpl.TEACHER_SUBJECTS_PERFORMANCE, tpl.TEACHER_SUBJECTS_REMOVAL, tpl.TEACHER_UPDATE_STATUS],
    [STUDENT]: [],
    [PARENT]: [tpl.TEACHER_PERFORMANCE, tpl.TEACHER_SUBJECTS_PERFORMANCE],
    [TEACHER]: [tpl.TEACHER_PERFORMANCE, tpl.TEACHER_SUBJECTS_PERFORMANCE],
    [SUBJECT_ADMIN]: [tpl.TEACHER_PERFORMANCE, tpl.TEACHER_SUBJECTS_PERFORMANCE],
  },
  periods: {
    [ADMIN]: [tpl.ATTENDANCE_SHEET, tpl.SUBMIT_ATTENDANCE_SHEET],
    [SEER]: [tpl.ATTENDANCE_SHEET],
    [OVERSEER]: [tpl.ATTENDANCE_SHEET, tpl.SUBMIT_ATTENDANCE_SHEET],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [tpl.ATTENDANCE_SHEET, tpl.SUBMIT_ATTENDANCE_SHEET],
    [SUBJECT_ADMIN]: [tpl.ATTENDANCE_SHEET, tpl.SUBMIT_ATTENDANCE_SHEET],
  },
  parents: {
    [ADMIN]: [tpl.REMOVE_KIDS_FROM_PARENTS, tpl.PARENT_UPDATE_STATUS],
    [SEER]: [],
    [OVERSEER]: [tpl.REMOVE_KIDS_FROM_PARENTS, tpl.PARENT_UPDATE_STATUS],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  assessments: {
    [ADMIN]: [tpl.ASSESSMENT_SCORE_SHEETS, tpl.SUBMIT_ASSESSMENT_SCORE_SHEETS, tpl.ASSESSMENT_PREVIEW],
    [SEER]: [tpl.ASSESSMENT_SCORE_SHEETS, tpl.ASSESSMENT_PREVIEW],
    [OVERSEER]: [tpl.ASSESSMENT_SCORE_SHEETS, tpl.SUBMIT_ASSESSMENT_SCORE_SHEETS, tpl.ASSESSMENT_PREVIEW],
    [STUDENT]: [tpl.SUBMIT_ASSESSMENT, tpl.ASSESSMENT_PREVIEW],
    [PARENT]: [],
    [TEACHER]: [tpl.ASSESSMENT_SCORE_SHEETS, tpl.SUBMIT_ASSESSMENT_SCORE_SHEETS, tpl.ASSESSMENT_PREVIEW],
    [SUBJECT_ADMIN]: [tpl.ASSESSMENT_SCORE_SHEETS, tpl.SUBMIT_ASSESSMENT_SCORE_SHEETS, tpl.ASSESSMENT_PREVIEW],
  },
  classes: {
    [ADMIN]: [tpl.CLASS_NEWS, tpl.CLASS_STUDENTS, tpl.REMOVE_SUBJECT_FROM_CLASS],
    [SEER]: [tpl.CLASS_NEWS, tpl.CLASS_STUDENTS],
    [OVERSEER]: [tpl.CLASS_NEWS, tpl.CLASS_STUDENTS, tpl.REMOVE_SUBJECT_FROM_CLASS],
    [STUDENT]: [tpl.CLASS_STUDENTS],
    [PARENT]: [],
    [TEACHER]: [tpl.CLASS_NEWS, tpl.CLASS_STUDENTS],
    [SUBJECT_ADMIN]: [tpl.CLASS_NEWS, tpl.CLASS_STUDENTS],
  },
  subjects: {
    [ADMIN]: [
      tpl.REMOVE_CLASS_FROM_SUBJECT,
      tpl.SUBJECT_NOTES,
      tpl.SUBJECT_NEWS,
      tpl.SUBJECT_STUDENTS,
      tpl.SUBMIT_ASSESSMENT_PERCENTAGE,
      tpl.SUBJECT_ASSESSMENTS,
      tpl.SUBJECT_APPLICATIONS,
      tpl.POST_ASSESSMENTS,
      tpl.POST_NEWS,
      tpl.POST_NOTES
    ],
    [SEER]: [
      tpl.SUBJECT_NOTES,
      tpl.SUBJECT_NEWS,
      tpl.SUBJECT_STUDENTS,
      tpl.SUBJECT_ASSESSMENTS
    ],
    [OVERSEER]: [
      tpl.REMOVE_CLASS_FROM_SUBJECT,
      tpl.SUBJECT_NOTES,
      tpl.SUBJECT_NEWS,
      tpl.SUBJECT_STUDENTS,
      tpl.SUBMIT_ASSESSMENT_PERCENTAGE,
      tpl.SUBJECT_ASSESSMENTS,
      tpl.SUBJECT_APPLICATIONS,
      tpl.POST_ASSESSMENTS,
      tpl.POST_NEWS,
      tpl.POST_NOTES
    ],
    [STUDENT]: [tpl.SUBJECT_NOTES, tpl.SUBJECT_NEWS, tpl.SUBJECT_ASSESSMENTS],
    [PARENT]: [],
    [TEACHER]: [
      tpl.SUBJECT_NOTES,
      tpl.SUBJECT_NEWS,
      tpl.SUBJECT_STUDENTS,
      tpl.SUBJECT_ASSESSMENTS,
      tpl.SUBMIT_ASSESSMENT_PERCENTAGE,
      tpl.SUBJECT_APPLICATIONS,
      tpl.POST_ASSESSMENTS,
      tpl.POST_NEWS,
      tpl.POST_NOTES
    ],
    [SUBJECT_ADMIN]: [
      tpl.SUBJECT_NOTES,
      tpl.SUBJECT_NEWS,
      tpl.SUBJECT_STUDENTS,
      tpl.SUBJECT_ASSESSMENTS,
      tpl.SUBMIT_ASSESSMENT_PERCENTAGE,
      tpl.SUBJECT_APPLICATIONS,
      tpl.POST_ASSESSMENTS,
      tpl.POST_NEWS,
      tpl.POST_NOTES
    ]
  },
  schools: {
    [ADMIN]: [],
    [SEER]: [],
    [OVERSEER]: [tpl.SCHOOL_SUMMARY],
    [PARENT]: [],
    [STUDENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  }
};

let permissions = {
  periods: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [PAGE],
    [PARENT]: [],
    [TEACHER]: [CREATE, DELETE, UPDATE, PAGE],
    [SUBJECT_ADMIN]: [CREATE, DELETE, UPDATE, PAGE],
  },
  assessments: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [PAGE],
    [PARENT]: [PAGE],
    [TEACHER]: [CREATE, DELETE, UPDATE, PAGE],
    [SUBJECT_ADMIN]: [CREATE, DELETE, UPDATE, PAGE],
  },
  classes: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [PAGE]
  },
  performance: {
    [ADMIN]: [],
    [SEER]: [],
    [OVERSEER]: [],
    [STUDENT]: [PAGE],
    [PARENT]: [],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [PAGE],
  },
  notes: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [PAGE],
    [PARENT]: [PAGE],
    [TEACHER]: [CREATE, DELETE, UPDATE, PAGE],
    [SUBJECT_ADMIN]: [CREATE, DELETE, UPDATE, PAGE],
  },
  subjects: {
    [ADMIN]: [UPDATE, PAGE, DELETE, CREATE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [PAGE],
    [PARENT]: [],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [UPDATE, PAGE, DELETE, CREATE],
  },
  news: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [PAGE],
    [PARENT]: [PAGE],
    [TEACHER]: [CREATE, DELETE, UPDATE, PAGE],
    [SUBJECT_ADMIN]: [CREATE, DELETE, UPDATE, PAGE],
  },
  students: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [PAGE],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [PAGE],
  },
  teachers: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  parents: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  dashboard: {
    [ADMIN]: [PAGE],
    [SEER]: [PAGE],
    [OVERSEER]: [PAGE],
    [STUDENT]: [PAGE],
    [PARENT]: [PAGE],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [PAGE],
  },
  changeSchool: {
    [ADMIN]: [],
    [SEER]: [],
    [OVERSEER]: [PAGE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  '/': {
    [ADMIN]: [PAGE],
    [SEER]: [PAGE],
    [OVERSEER]: [PAGE],
    [STUDENT]: [PAGE],
    [PARENT]: [PAGE],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [PAGE],
  },
  profile: {
    [ADMIN]: [PAGE],
    [SEER]: [PAGE],
    [OVERSEER]: [PAGE],
    [STUDENT]: [PAGE],
    [PARENT]: [PAGE],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [PAGE],
  },
  settings: {
    [ADMIN]: [PAGE],
    [SEER]: [],
    [OVERSEER]: [PAGE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  registration: {
    [ADMIN]: [],
    [SEER]: [],
    [OVERSEER]: [],
    [STUDENT]: [PAGE],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  grades: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  schools: {
    [ADMIN]: [],
    [SEER]: [],
    [OVERSEER]: [UPDATE, PAGE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },

  colleges: {
    [ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
    [SEER]: [PAGE],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  website: {
    [ADMIN]: [PAGE],
    [SEER]: [PAGE],
    [OVERSEER]: [PAGE],
    [STUDENT]: [PAGE],
    [PARENT]: [PAGE],
    [TEACHER]: [PAGE],
    [SUBJECT_ADMIN]: [PAGE]
  },
  requests: {
    [ADMIN]: [],
    [SEER]: [],
    [OVERSEER]: [PAGE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  transcript: {
    [ADMIN]: [PAGE],
    [SEER]: [PAGE],
    [OVERSEER]: [PAGE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
  questions: {
    [ADMIN]: [UPDATE, PAGE, DELETE, CREATE],
    [SEER]: [],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [CREATE, UPDATE, PAGE, DELETE],
    [SUBJECT_ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
  },
  quizzes: {
    [ADMIN]: [UPDATE, PAGE, DELETE, CREATE],
    [SEER]: [],
    [OVERSEER]: [CREATE, UPDATE, PAGE, DELETE],
    [STUDENT]: [],
    [PARENT]: [],
    [TEACHER]: [CREATE, UPDATE, PAGE, DELETE],
    [SUBJECT_ADMIN]: [CREATE, UPDATE, PAGE, DELETE],
  },
  qa: {
    [ADMIN]: [],
    [SEER]: [],
    [OVERSEER]: [],
    [STUDENT]: [PAGE],
    [PARENT]: [],
    [TEACHER]: [],
    [SUBJECT_ADMIN]: [],
  },
};

export {
  permissions,
  tpl,
  tabPermissions,
  permissionLevels
};
