import { graphql } from 'react-apollo';
import parentQueries from '../../queries/parent';
import { fetches, fields, getRequestData } from './helper';
import { changeTimestampToDate } from '../../functions';

export default {
  queries: (id) => [
    graphql(parentQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(parentQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData: (props, data) => getRequestData(
    props,
    {
      ...data,
      studentsIds: data.students.map(({ actualId }) => parseInt(actualId, 10))
    },
    {}
  ),
  initialValues: ({ data: { getParent = {} } }) => ({
    ...getParent,
    dob: changeTimestampToDate(getParent.dob, 'YYYY-MM-DD'),
    students: getParent.students.map(student => ({ actualId: student.id, text: `${student.firstName} ${student.lastName}` }))
  }),
  success: (response, history) => { history.push(`/parents/${response.data.updateParent.id}`); }
};
