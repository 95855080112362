import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import withUser from './withUser';
import dataFetchingComponent from './dataFetchingComponent';
import { semesterQueries } from '../queries';
import { changeTimestampToDate, sortArray } from '../functions'
import { JitsuSelect, JitsuButton } from '../components-elements/jistu';

const attachFilter = (PerformanceComponent, disabledCurrentSemester = false, isTeacher = true, errorMessageForNoSemesterOptions) => {
  class PerformanceWithFilter extends Component {
    constructor(props) {
      super(props);
      const semesterOptions = this.getSemesterOptions();
      const firstOptionValue = (semesterOptions[0] || {}).value
      this.state = {
        semesterId: firstOptionValue,
        searchParams: { semesterId: firstOptionValue },
        semesterOptions
      };
      this.updateState = this.updateState.bind(this);
      this.clickApply = this.clickApply.bind(this);
    }
    updateState(value, name) {
      this.setState({
        [name]: value
      });
    }
    clickApply() {
      const { semesterId } = this.state;
      this.setState({ searchParams: { semesterId } });
    }
    getSemesterOptions() {
      const { data: { getTeacherSemesterOptions, getStudentSemesterOptions }, user } = this.props;
      const options = (isTeacher ? getTeacherSemesterOptions : getStudentSemesterOptions)
        .filter(({ id }) => disabledCurrentSemester ? id !== user.semesterId : true)
        .map(({ name, id, startDate, endDate, year }) => {
          return {
            endDate,
            text: `${name} (${year}) - (${changeTimestampToDate(startDate, 'YYYY-MM-DD')} - ${changeTimestampToDate(endDate, 'YYYY-MM-DD')}) `,
            value: id
          }
        });
      return sortArray(options, 'endDate', true)
    }
    render() {
      const { semesterId, searchParams, semesterOptions } = this.state;
      const { hideFilter } = this.props;
      return (<div className="p-4">
        { errorMessageForNoSemesterOptions && semesterOptions.length === 0
          ? <div>{errorMessageForNoSemesterOptions}</div>
          : <Fragment>{
            !hideFilter && <div className="flex">
              <JitsuSelect
                label="Semester"
                value={semesterId}
                onChange={this.updateState}
                name="semesterId"
                options={semesterOptions}
                ignoreFirstOption
              />
              <JitsuButton name="apply" value="Apply" onClick={this.clickApply} wrapperClassName="pl-4 pt-8"/>
            </div>}
          <PerformanceComponent {...searchParams} userId={this.props.userId} subjectId={this.props.subjectId}/>
          </Fragment>}
      </div>);
    }
  }

  PerformanceWithFilter.defaultProps = {
    hideFilter: false
  };

  PerformanceWithFilter.propTypes = {
    user: PropTypes.object.isRequired,
    hideFilter: PropTypes.bool.isRequired,
    userId: PropTypes.number,
    subjectId: PropTypes.number,
    data: PropTypes.object.isRequired
  };

  return withUser(
    graphql(
      isTeacher ? semesterQueries.teacherSemesterOption : semesterQueries.studentSemesterOption,
      { options:({ userId }) => ({ variables: isTeacher ? { teacherId: userId } : { studentId: userId } }) }
    )(dataFetchingComponent(PerformanceWithFilter)));
};

export default attachFilter;