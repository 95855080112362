import { graphql } from 'react-apollo';
import parentQueries from '../../queries/parent'
import { requestNameMap } from '../../constants/requests';

export default {
  queries: id => [
    graphql(parentQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(parentQueries.wipe, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  requestName: requestNameMap.DELETE_PARENT,
  dataKey: 'getParent',
  success: (response, history, id) => { history.push(`/parents/${id}`); }
};
