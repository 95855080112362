import dateFns from 'date-fns';
import { commonFields } from '../commonHelpers';
import validatePeriod from './validation';
import { TEXT_INPUT, DATE_INPUT, SELECT_INPUT } from '../../constants/fieldTypes';
import { DATE, TIME } from '../../constants/dateInputTypes';
import { textChange } from '../../functions/textChange';
import { ADMIN, OVERSEER } from '../../constants/roles';

export const fields = [
  () => ({ name: 'name', type: TEXT_INPUT, label: 'Title', placeholder: 'Thursday-Maths', required: true, max: 100 }),
  () => ({
    name: 'date',
    type: DATE_INPUT,
    label: 'Starting date',
    placeholder: '1992-05-11',
    dateInputType: DATE,
    required: true
  }),
  () => ({
    name: 'startTime',
    type: DATE_INPUT,
    label: 'Starting at',
    placeholder: dateFns.format(new Date(), 'hh:mm a'),
    dateInputType: TIME,
    required: true
  }),
  () => ({
    name: 'endTime',
    type: DATE_INPUT,
    label: 'Ending at',
    placeholder: dateFns.format(new Date(), 'hh:mm a'),
    dateInputType: TIME,
    required: true
  }),
  props => ({
    name: 'subjectId',
    type: SELECT_INPUT,
    label: textChange('Subject'),
    valueKey: 'value',
    options: [
      ...([ADMIN, OVERSEER].includes(props.user.userRole) ? [{ value: null, text: 'Event' }] : []),
      ...(props.subjects.getSubjects || []).map(({ code, id, name }) => ({ value: id, text: `${name} (${code})` }))
    ],
    ignoreFirstOption: [ADMIN, OVERSEER].includes(props.user.userRole),
  }),
  commonFields.notesSearchMultiSelect
];

export const fetches = [];

export const getRequestData = (props, { errors, subjectId, notes = [], students = [], propagate, date, ...rest }) => ({
  requestData: {
    ...rest,
    propagate: parseInt(propagate || 0, 10),
    subjectId: subjectId ? parseInt(subjectId, 10) : null,
    notesIds: notes.map(note => note.actualId),
    studentsIds: students.map(student => student.id),
    date: dateFns.format(date, 'YYYY-MM-DD'),
  },
  errors: validatePeriod({ ...rest, subjectId }, props.user)
});
