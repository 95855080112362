import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoListItemLi } from './styled';
import TextEditor from '../TextEditor';
import JitsuLabel from '../JitsuLabel';

const InfoLine = ({ value, label, id, editor, classname, to, link }) => value !== 0 && !value
  ? <Fragment/>
  : <InfoListItemLi className={ classname }>
    <JitsuLabel>{label}</JitsuLabel>
    {editor && <TextEditor value={value} id={id} readOnly/>}
    {link && <div><Link to={to}>{value}</Link></div>}
    {!editor && !link && <div id={id}>{value}</div>}
  </InfoListItemLi>;

InfoLine.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  to: PropTypes.string,
  classname: PropTypes.string,
  editor: PropTypes.bool,
  link: PropTypes.bool,
};

export default InfoLine;