import React, { Fragment, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withUser } from '../hocs';
import { SuspenseComponent } from '../components-elements';
import { STUDENT, ADMIN, TEACHER } from '../constants/roles';
import { needsAuthentication } from '../hocs/authenticated';
import PAGES from '../constants/pages';

const Dashboard = lazy(() => import('../containers/DashboardPage'));
const Transcript = lazy(() => import('../containers/Transcript'));
const Profile = lazy(() => import('../containers/UserProfile'));
const SubjectRegistration = lazy(() => import('../components-graphql/Registration'));
const PerformanceAndAttendance = lazy(() => import('../containers/PerformanceAndAttendance'));
const TeacherPerformance = lazy(() => import('../components-graphql/Performances/TeacherPerformance'));
const RequestsHandler = lazy(() => import('../containers/RequestsHandler'));
const Settings = lazy(() => import('../containers/Settings'));

const RoutesFromMap = ({ user }) => user.userId
  ? <Fragment>
    <SuspenseComponent>
      <Fragment>
        <Route path={`/${PAGES.dashboard}`} component={needsAuthentication(() => <Dashboard/>, PAGES.dashboard)}/>
        <Route path={`/${PAGES.profile}`} component={needsAuthentication(() => <Profile/>, PAGES.profile)}/>
        <Route path={`/${PAGES.settings}`} component={needsAuthentication(() => <Settings/>, PAGES.settings)}/>
        <Route path={`/${PAGES.requests}`} component={needsAuthentication(() => <RequestsHandler/>, PAGES.requests)}/>
        <Route path={`/${PAGES.transcript}`} component={needsAuthentication(() => <Transcript/>, PAGES.transcript)}/>
        <Route path={`/${PAGES.registration}`} component={needsAuthentication(() => <SubjectRegistration studentId={user.userId}/>, PAGES.registration)}/>
        {user.userRole === STUDENT
          && <Route
            path={`/${PAGES.performance}`}
            component={needsAuthentication(() => <PerformanceAndAttendance userId={user.userId}/>, PAGES.performance)}/>}

        {(user.userRole === ADMIN || user.userRole === TEACHER)
          && <Route
            path={`/${PAGES.performance}`}
            component={needsAuthentication(() => <TeacherPerformance userId={user.userId}/>, PAGES.performance)}/>}
    </Fragment>
  </SuspenseComponent>
</Fragment>
: <Fragment/>;

RoutesFromMap.propTypes = {
  user: PropTypes.object.isRequired
};

export default withUser(RoutesFromMap);