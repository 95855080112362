import React, { lazy, Fragment } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { SuspenseComponent } from '../components-elements';
import AppRoutes from './AppRoutes';

const Login = lazy(() => import('../containers/Login'));

export default () => <BrowserRouter>
  <Fragment>
    <SuspenseComponent><Route path="/" exact={true} component={() => <Login/>} /></SuspenseComponent>
    <Route path="/:page" component={AppRoutes}/>
  </Fragment>
</BrowserRouter>;