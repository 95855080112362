import React from 'react';
import PropTypes from 'prop-types';
import JitsuMultiselectOption from './JitsuMultiselectOption';

const JitsuMultiselectOptions = ({ options, expanded, onClickOption, textKey, lockedMap, valueKey, selectionsMap, children, id }) => {
  const className = expanded ? 'block' : 'hidden';
  return <div id={`${id}_options`} className="flex items-center relative w-full">
    <div className={`${className} w-full`}>
      <div className={`absolute shadow top-120 bg-white z-40 w-full left-2 rounded max-h-select overflow-y-auto`}>
        <div className="flex flex-col w-full h-48">
          {children}
          {
            options.map((option) => <JitsuMultiselectOption
              key={option[valueKey]}
              id={`${id}_option_${option[valueKey]}`}
              locked={!!lockedMap[option[valueKey]]}
              onClickOption={onClickOption}
              selected={!!selectionsMap[option[valueKey]]}
              option={option}
            >
              {option[textKey]}
            </JitsuMultiselectOption>)
          }
        </div>
      </div>
    </div>
  </div>
};

JitsuMultiselectOptions.defaultProps = {
  selectionsMap: {},
  children: '',
  id: '',
};

JitsuMultiselectOptions.propTypes = {
  options: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  lockedMap: PropTypes.object.isRequired,
  selectionsMap: PropTypes.object.isRequired,
  onClickOption: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default JitsuMultiselectOptions;