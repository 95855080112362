import React, { lazy } from 'react';

import { subjectQueries } from '../../queries';
import { TEXT, LIST } from '../../constants/displayTypes';
import { NoteRow, NewsRow } from '../../components-elements';
import { createListInTab } from '../commonHelpers';
import { tpl, tabPermissions } from '../../constants/permissions';
import { STUDENT, TEACHER, SUBJECT_ADMIN, ADMIN, OVERSEER } from '../../constants/roles';
import { getCurrentUser } from '../../functions';
import { textChange } from '../../functions/textChange';

const SubjectPerformance = lazy(() => import('../../components-graphql/Performances/SubjectPerformance'));
const AssessmentsWithPercentages = lazy(() => import('../../components-graphql/AssessmentsWithPercentages'));
const SubjectApproval = lazy(() => import('../../components-graphql/SubjectApproval'));

export default {
  dataKey: 'getSubject',
  query: subjectQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Name', text: data => data.name, id: 'name' },
    { type: TEXT, label: 'Code', text: data => data.code, id: 'code' },
    { type: TEXT, label: 'Description', text: data => data.description, id: 'description', editor: true },
    { type: TEXT, label: 'Credit unit', text: data => data.creditUnit, id: 'creditUnit' },
    {
      type: TEXT,
      label: `Lead ${textChange('Teacher')}`,
      text: ({ leadTeacher }) => `${leadTeacher ? `${leadTeacher.firstName} ${leadTeacher.lastName}` : 'No one'}`,
      id: 'leadTeacher'
    },
    {
      type: LIST,
      label: `${textChange('Teachers')} teaching this ${textChange('subject')}`,
      list: data => data.teachers.map(teacher => ({
        ...teacher,
        name: `${teacher.firstName} ${teacher.lastName}`
      })),
      id: 'leadTeacher'
    },
    {
      type: LIST,
      label: 'Categories',
      list: data => data.categories,
      id: 'categories'
    },
  ],
  tabHeaders: ({ data: { getSubject: subject }, user: { userRole} }) => [
    {
      text: 'Students',
      value: tpl.SUBJECT_STUDENTS,
      props: {
        subjectId: subject.id,
      },
      Component: SubjectPerformance
    },
    {
      text: textChange('Notes'),
      value: tpl.SUBJECT_NOTES,
      props: {},
      Component: createListInTab(
        subjectQueries.subjectNotes,
        { dataKey: 'getSubjectNotes' },
        NoteRow,
        'notes',
      ({ children }) => <div className="bg-red-300">{children}</div>,
        tabPermissions.subjects[userRole].includes(tpl.POST_NOTES) ? {  link: '/notes/create', text: 'Post Notes' } : undefined
      ),
    },
    {
      text: textChange('News'),
      value: tpl.SUBJECT_NEWS,
      props: {},
      Component: createListInTab(
        subjectQueries.subjectNewsList,
        { dataKey: 'getSubjectNewsList' },
        NewsRow,
        'news',
        undefined,
        tabPermissions.subjects[userRole].includes(tpl.POST_NEWS) ? { link: '/news/create', text: 'Post News' }: undefined
      )
    },
    {
      text: 'Assessments',
      value: tpl.SUBJECT_ASSESSMENTS,
      props: {
        id: subject.id,
        canPostAssessment: tabPermissions.subjects[userRole].includes(tpl.POST_ASSESSMENTS)
          && getCurrentUser().subjectsIds.includes(subject.id)
      },
      Component: AssessmentsWithPercentages
    },
    {
      text: 'Registration',
      value: tpl.SUBJECT_APPLICATIONS,
      props: { subjectId: subject.id },
      Component: SubjectApproval
    }
  ],
  isAuthorized: (user, id) => {
    if (user.userRole === STUDENT) return user.subjectsIds.includes(parseInt(id, 10));
    if (user.userRole === SUBJECT_ADMIN) return user.subjectsIds.includes(parseInt(id, 10));
    if (user.userRole === TEACHER) return user.subjectsIds.includes(parseInt(id, 10));
    return true;
  },
  isAuthorizedToDelete: (user, id) => {
    if ([ADMIN, OVERSEER].includes(user.userRole)) return true
    if (user.userRole === SUBJECT_ADMIN) return user.subjectsIds.includes(parseInt(id, 10));
    return false;
  },
  isAuthorizedToUpdate: (user, id) => {
    if ([ADMIN, OVERSEER].includes(user.userRole)) return true
    if (user.userRole === SUBJECT_ADMIN) return user.subjectsIds.includes(parseInt(id, 10));
    return false;
  },
};

