import React, { lazy, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import RoutesFromMap from './routesFromMap';
import SideRoutesFromMap from './sideRoutesFromMap';
import FullScreenRoutesFromMap from './fullScreenRoutesFromMap';
import { SuspenseComponent } from '../components-elements';
import Notifications from '../containers/Notification';
import TimeTable from '../containers/Timetable';
import authorized from '../hocs/authorized';
import { needsAuthentication } from '../hocs/authenticated';
import PAGES from '../constants/pages';
import { ROUTES } from '../constants/routes';

const Navigator = lazy(() => import('../containers/Nav'));
const TopBar = lazy(() => import('../components-elements/TopBar'));
const Create = lazy(() => import('../containers/CreateItem'));
const Update = lazy(() => import('../containers/UpdateItem'));
const Details = lazy(() => import('../containers/ItemDetails'));
const Delete = lazy(() => import('../containers/DeleteItem'));
const FirstTimeLogin = lazy(() => import('../containers/FirstTimeLogin'));
const ChangeSchool = lazy(() => import('../containers/ChangeSchool'));
const UserSuspendedMessage = lazy(() => import('../containers/UserSuspendedMessage'));
const NotFound = lazy(() => import('../components-elements/NotFound'));
const QuestionsAndAnswers = lazy(() => import('../components-graphql/QuestionsAndAnswers'));

const SideRoutesFromMapWrapper = styled.div`
  max-height: calc(100% - 60px);
`;

const AppRoutes = ({ match: { params: { page } } }) => {
  if (page === 'completeLogin') {
    return <SuspenseComponent><Route path="/completeLogin" exact={true} component={() => <FirstTimeLogin/>} /></SuspenseComponent>;
  }
  if (page === 'userSuspended') {
    return <SuspenseComponent><Route path="/userSuspended" exact={true} component={() => <UserSuspendedMessage/>} /></SuspenseComponent>;
  }
  if (page === 'changeSchool') {
    return <SuspenseComponent><Route path="/changeSchool" exact={true} component={() => <ChangeSchool/>} /></SuspenseComponent>;
  }
  if (!ROUTES[page]) {
    return <SuspenseComponent><Route path="/:page" component={() => <NotFound/>} /></SuspenseComponent>;
  }
  return (<Fragment>
    <Route path="/:page" component={Notifications} />
    <div className="flex flex-col md:flex-row lg-:flex-row xl:flex-row h-screen">
      <div className="flex-none">
        <SuspenseComponent><Route path="/:page" component={() => <Navigator/>} /></SuspenseComponent>
      </div>
      <div className="flex-1">
        <SuspenseComponent><Route path="/:page" component={() => <TopBar/>} /></SuspenseComponent>
        <div className="flex h-full flex-col md:flex-row lg:flex-row xl:flex-row overflow-auto">
          <SideRoutesFromMapWrapper className="flex-none md:h-full lg:h-full xl:h-full"><SideRoutesFromMap/></SideRoutesFromMapWrapper>
          <div className="flex-1 h-full">
            <Route path="/periods" component={needsAuthentication(TimeTable, PAGES.periods)} exact={true} />
            <FullScreenRoutesFromMap />
            <RoutesFromMap />
            <SuspenseComponent>
              <Switch>
                <Route path={`/${PAGES.qa}/:id`} exact component={needsAuthentication(() => <QuestionsAndAnswers/>, PAGES.qa)}/>
                <Route path="/:page/create" component={authorized(() => <Create/>)} exact={true} />
                <Route path="/:page/:id/update" component={authorized(() => <Update/>)} exact={true} />
                <Route path="/:page/:id/delete" component={authorized(() => <Details/>)} exact={true} />
                <Route path="/:page/:id" component={authorized(() => <Details/>)} exact={true} />
              </Switch>
            </SuspenseComponent>
            <SuspenseComponent>
              <Route path="/:page/:id/delete" component={authorized(() => <Delete/>)} exact={true} />
            </SuspenseComponent>
          </div>
        </div>
      </div>
    </div>
  </Fragment>);
};

export default withRouter(AppRoutes);