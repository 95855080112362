import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { PieChart, Pie, Tooltip } from 'recharts';

import { schoolQueries } from '../../queries';
import dataFetchingComponent from '../../hocs/dataFetchingComponent';
import { createSchoolData, label } from './helper';
import { SchoolSummaryDiv, PieChartWrapper } from './styled';
import UsersSummariesWrapper from './UsersSummariesWrapper';

class SchoolSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.onPieEnter = this.onPieEnter.bind(this);
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }

  render () {
    const { data: { getSchoolSummary }, id } = this.props;
    const schoolData = createSchoolData(getSchoolSummary);
  	return (
      <SchoolSummaryDiv>
        <PieChartWrapper>
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={schoolData}
              innerRadius={60}
              outerRadius={100} 
              fill="#8884d8"
              paddingAngle={5}
              label={label}
            />
            <Tooltip />
          </PieChart>
          {/* <PieChart width={800} height={400}>
            <Pie 
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape} 
              data={schoolData} 
              cx={300} 
              cy={200} 
              innerRadius={100}
              outerRadius={120} 
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
              label={true}
              labelLine={true}
            />
          </PieChart> */}
        </PieChartWrapper>
        <UsersSummariesWrapper id={id}/>
      </SchoolSummaryDiv>
    );
  }
}

SchoolSummary.defaultProps = {
  data: {
    getSchoolSummary: {}
  }
};

SchoolSummary.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired
};

export default graphql(schoolQueries.schoolSummary, {
  options: ({ id }) => ({ variables: { id } })
})(dataFetchingComponent(SchoolSummary));