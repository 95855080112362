import { questionQueries } from '../../queries';
import { TEXT, ANSWERS } from '../../constants/displayTypes';

export default {
  dataKey: 'getQuestion',
  query: questionQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Name', text: data => data.name, id: 'name' },
    // { type: TEXT, label: 'Type', text: data => data.type, id: 'type' },
    { type: TEXT, label: 'Content', text: data => data.content, id: 'content', editor: true },
    {
      type: ANSWERS,
      label: 'Answers',
      list: data => data.answers,
      id: 'answers' },
  ]
};
