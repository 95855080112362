import { questionQueries } from '../../queries';
import { afterCreate, afterDelete } from '../../functions/query';
import { TEXT_INPUT, TEXT_EDITOR } from '../../constants/fieldTypes';
import validateQuestion from './validation';

export const updateQuestionListAfterCreate = (proxy, question) => {
  const query = { query: questionQueries.list };
  afterCreate(proxy, query, 'getQuestions', question);
};

export const updateQuestionListAfterDelete = (proxy, question) => {
  const query = { query: questionQueries.list };
  afterDelete(proxy, query, 'getQuestions', question);
};

export const fields = [
  () => ({
    name: 'name',
    type: TEXT_INPUT,
    label: 'Name',
    placeholder: '',
    wrapperClassName: 'topic',
    required: true,
  }),
  // () => ({
  //   name: 'type',
  //   type: SELECT_INPUT,
  //   label: 'Type',
  //   valueKey: 'value',
  //   options: [{ value: 'SINGLE', text: 'Single' }, { value: 'MULTIPLE', text: 'Multiple' }]
  // }),
  () => ({
    name: 'content',
    type: TEXT_EDITOR,
    label: 'Content',
    placeholder: '',
    required: true
  }),
  () => ({
    name: 'answers',
    type: 'ANSWERS',
    label: 'Answers',
    placeholder: '',
    wrapperClassName: 'full',
    tooltip: 'There must be at least one correct answer',
    required: true,
  }),
  () => ({
    name: 'tag',
    type: 'TAG_INPUT',
    label: 'Grouping tag',
    placeholder: '',
    wrapperClassName: 'tag full',
    required: true,
  }),
];

export const getRequestData = (_, requestData) => ({
  requestData: {
    ...requestData,
    tag: { id: requestData.tag.id, name: requestData.tag.name },
    answers: requestData.answers.map(a => ({
      id: a.id,
      content: a.content,
      correct: a.correct
    }))
  },
  errors: validateQuestion(requestData)
});
