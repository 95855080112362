import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/react';
import { onError } from "apollo-link-error";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { getToken, deleteToken } from '../functions';
import * as hosts from '../constants/api';

Sentry.init({
  dsn: 'https://2227d987a0e447948babc3b7bf220b0d@o427218.ingest.sentry.io/5370973',
  defaultIntegrations: [
    new Sentry.Integrations.Breadcrumbs({ fetch: false }),
  ],
});

function getUri() {
  return `${hosts.LOCAL_HOST}/graphql`
  // return `${(process.env.ENV === 'PRODUCTION') ? PROD_HOST :  LOCAL_HOST}/graphql`;
}

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `JWT ${getToken()}` // eslint-disable-line
    }
  });
  return forward(operation);
});

const httpLink = createUploadLink({
  uri: getUri()
});

const newErrorLink = onError((error) => {
  const { networkError, graphQLErrors, operation } = error;
  if (graphQLErrors && graphQLErrors.some(err => err.extensions && err.extensions.code === 'FORBIDDEN')) {
    deleteToken();
  }
  if (networkError && operation.getContext().response && operation.getContext().response.status === 401) {
    deleteToken();
  }
});

const sentryLink = new SentryLink({
  breadcrumb: {
    includeResponse: true,
    includeError: true,
  }
})

const cache = new InMemoryCache();
const client = new ApolloClient({
  link: ApolloLink.from([
    sentryLink,
    newErrorLink,
    middlewareAuthLink,
    httpLink
  ]),
  cache,
});

export default client;
