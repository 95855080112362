import gql from 'graphql-tag';

export default {
  list: gql`query {getClasses{name, id, code, subjects{ id, name, code, creditUnit } }}`,
  item: gql`query getClassQuery($id: Int) {
    getClass(id: $id){
        name,id, description, photoUrl, colour, code,
        teacher { id, firstName, lastName },
        college { id, name },
        address,
        final,
        highestYear,
        next{ id, name }
        subjects{ id, name, code }, level
    }
  }`,
  update: gql`mutation updateClassQuery(
    $id: Int,
    $final: Int,
    $name: String!,
    $code: String!,
    $colour: String!,
    $address: String!,
    $photoUrl: String!,
    $description: String!,
    $teacherId: Int!,
    $highestYear: Int,
    $next: Int,
    $collegeId: Int!,
    $level: Int!,
    $subjectsIds: [Int]) {
        updateClass(
            id: $id,
            final: $final,
            name: $name,
            code: $code,
            highestYear: $highestYear,
            next: $next,
            colour: $colour,
            address: $address,
            collegeId: $collegeId
            photoUrl: $photoUrl,
            level: $level,
            description: $description,
            teacherId: $teacherId,
            subjectsIds: $subjectsIds){
                name,
                id,
                final,
                code,
                colour,
                address,
                photoUrl,
                level,
                highestYear,
                description,
                college { id, name },
                next { id, name },
                teacher { id, firstName, lastName  },
                subjects { id, name, code },
                subjects{ id, code }
        }
    }`,
  create: gql`mutation createClassQuery(
    $name: String!,
    $code: String!,
    $colour: String!,
    $address: String!,
    $photoUrl: String!,
    $description: String!,
    $teacherId: Int!,
    $highestYear: Int,
    $next: Int,
    $final: Int,
    $collegeId: Int!,
    $level: Int!,
    $subjectsIds: [Int]) {
        createClass(
            name: $name,
            code: $code,
            final: $final,
            colour: $colour,
            address: $address,
            level: $level,
            highestYear: $highestYear,
            next: $next,
            photoUrl: $photoUrl,
            description: $description,
            teacherId: $teacherId,
            collegeId: $collegeId,
            subjectsIds: $subjectsIds){
                name,
                id,
                final,
                code,
                colour,
                highestYear,
                college { id, name },
                next { id, name },
                address,
                photoUrl,
                level,
                description,
                teacher { id, firstName, lastName },
                subjects { id, name, code },
                subjects{ id, code }
        }
    }
  `,
  wipe: gql`mutation deleteClassQuery($id: Int) {
    deleteClass(id: $id){
        id
    }
  }`,
  teacherClasses: gql`query getTeacherClassesQuery($id: Int) {
    getTeacherClasses(id: $id) {
      name, id, code
    }
  }`,
  classResult: gql`query getClassResultQuery($id: Int) {
    getClassResult(id: $id){
      name,
      studentId,
      regNumber,
      email,
      phoneNumber,
      results{
          code,
          subjectId,
          creditUnit,
          assessments{
              score, topic, overallMark, percentage
          }
      }
    }
  }`,
  classStudents: gql`query getClassStudentsQuery($classId: Int, $year: Int) {
    getClassStudents(classId: $classId, year: $year) {
        firstName, lastName, id, regNumber, status
    }
  }`,
  classSubjects: gql`query getClassSubjectsQuery($classId: Int) {
    getClassSubjects(classId: $classId) {
        code, id
    }
  }`,
  classNewsList: gql`query getClassNewsListQuery($classId: Int) {
    getClassNewsList(classId: $classId) {
      topic,
      id,
      description,
      fileUrl,
      teacher { id, firstName, lastName },
      createdAt
    }
  }`
};