import { TEACHER } from '../../constants/roles';
import { isTextEditorNotEmpty, minValue } from '../commonValidations';
import { MAX_FILE_SIZE } from '../../constants';
import { textChange } from '../../functions/textChange';


export default ({ topic, description, subjects, fileUrl, excerpt }, user) => ({
  topic: topic.length < 3 ? minValue(3) : null,
  excerpt: excerpt.length < 3 ? minValue(3) : null,
  description: isTextEditorNotEmpty(description) ? null : 'description can not be empty',
  subjects: user.userRole === TEACHER && subjects.length < 1 ? `must select at least one ${textChange('subject')}` : null,
  fileUrl: fileUrl.size > MAX_FILE_SIZE ? 'file is too large (max = 2MB)' : null
});