// major theme
// export const RAISIN_BLACK = '#00897B';
// export const MANDARIN = '#B2DFDB';
// export const DARK_SUBSTITUTE = '#2E7D32';
// export const GOLD = '#1d1e2c';

// // text colors or gray scale colors
// export const DARK_GRAY = '#ebebeb';
// export const LIGHT_GRAY = '#9e9d9e';
// export const GRAY = '#f0f0f0';
// export const LIGHTER_GRAY = '#efefef';
// export const WHITE = '#ffffff';
// export const BLACK = '#000000';

// // action or response interpretation
// export const RED = '#f1172f'; // denied or decline or destructive
// export const GREEN = '#B4DBC0'; // accepted or successful
// export const BLUE = '#3366CC'; // message

// major theme
export const RAISIN_BLACK = '#117bb8';
export const MANDARIN = '#CEECFB';
export const DARK_SUBSTITUTE = 'rgb(14, 22, 92)';
export const GOLD = '#1d1e2c';

// text colors or gray scale colors
export const DARK_GRAY = '#ebebeb';
export const LIGHT_GRAY = '#9e9d9e';
export const GRAY = '#f0f0f0';
export const LIGHTER_GRAY = '#efefef';
export const WHITE = '#ffffff';
export const BLACK = '#000000';

// to be deleted
export const DARK_BLUE = '#1d1e2c';
export const LIGHTER_BLUE = '#328cc112';
export const LIGHT_BG = 'rgba(137, 149, 158, 0.07)';

// action or response interpretation
export const RED = '#f1172f'; // denied or decline or destructive
export const GREEN = '#B4DBC0'; // accepted or successful
export const BLUE = '#3366CC'; // message

export const GRAPH_COLORS = {
  PURPLE: '#8884d8',
  LIGHT_GREEN: '#69711e',
  GREEN: '#82ca9d',
  BLUE: '#36C',
  RED: '#DC143C'
};
