import React, { Component } from 'react';
import './videoAddStyles.css';
import { JitsuButton } from '../jistu';

export default class VideoAdd extends Component {
  // Start the popover closed
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      open: false,
    };
    this.onPopoverClick = this.onPopoverClick.bind(this);
    this.openPopover = this.openPopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.addVideo = this.addVideo.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
  }
  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener('click', this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick() {
    this.preventNextClose = true;
  };

  openPopover() {
    if (!this.state.open) {
      this.preventNextClose = true;
      this.setState({
        open: true,
      });
    }
  };

  closePopover() {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false,
      });
    }

    this.preventNextClose = false;
  };

  addVideo() {
    const { editorState, onChange } = this.props; // eslint-disable-line
    onChange(this.props.modifier(editorState, { src: this.state.url })); // eslint-disable-line
  };

  changeUrl(evt) {
    this.setState({ url: evt.target.value });
  };

  render() {
    const popoverClassName = this.state.open ?
      'addVideoPopover' :
      'addVideoClosedPopover';

    return (
      <div className="addVideo mb-4" >
        <JitsuButton
          // className={buttonClassName}
          onClick={this.openPopover}
          type="button"
          value="Add video"
        />
        <div
          className={popoverClassName}
          onClick={this.onPopoverClick}
        >
          <input
            type="text"
            placeholder="Paste the video url …"
            className={'addVideoInput'}
            onChange={this.changeUrl}
            value={this.state.url}
          />
          <button
            className={'addVideoConfirmButton'}
            type="button"
            onClick={this.addVideo}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}