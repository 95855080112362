import gql from 'graphql-tag';

export default {
  dNews: gql`query getPersonalNewsQuery($userId: Int, $userRole: String){
    getPersonalNews(userId: $userId, userRole: $userRole){
        id, topic, description, teacher{ id, firstName, lastName }, fileUrl, createdAt, excerpt, restricted
    }
  }`,
  listByCreatedAt: gql`query getNewssByCreatedAtQuery($startDate: String, $endDate: String) {
    getNewssByCreatedAt(startDate: $startDate, endDate: $endDate){
      id, topic, description, teacher{ id, firstName, lastName }, fileUrl, createdAt, subjects{ id, code }, excerpt, restricted
    }
  }`,
  item: gql`query getNewsQuery($id: Int) {
    getNews(id: $id){
      topic,
      id,
      description,
      fileUrl,
      teacher { id, firstName, lastName },
      excerpt
      subjects{ id, code },
      createdAt,
      restricted
    }
  }`,
  broadcasts: gql`query {
    getBroadcasts {
      topic,
      id,
      description,
      type
    }
  }`,
  create: gql`mutation createNewsQuery(
    $topic: String!,
    $description: String!,
    $excerpt: String!,
    $fileUrl: Upload,
    $teacherId: Int!,
    $restricted: Int,
    $classesIds: [Int],
    $subjectsIds: [Int]) {
      createNews(
        topic: $topic,
        restricted: $restricted,
        excerpt: $excerpt,
        description: $description,
        fileUrl: $fileUrl,
        teacherId: $teacherId,
        classesIds: $classesIds,
        subjectsIds: $subjectsIds){
          id,
          topic,
          description,
          excerpt,
          fileUrl,
          teacher { id, firstName, lastName  },
          subjects { id, code },
          classes { id, name, code },
          createdAt,
          restricted
    }
  }`,
  update: gql`mutation updateNewsQuery(
    $id: Int,
    $topic: String!,
    $excerpt: String!,
    $description: String!,
    $restricted: Int,
    $fileUrl: Upload,
    $teacherId: Int!,
    $classesIds: [Int],
    $subjectsIds: [Int]) {
        updateNews(
            id: $id,
            topic: $topic,
            excerpt: $excerpt,
            description: $description,
            restricted: $restricted,
            fileUrl: $fileUrl,
            teacherId: $teacherId,
            classesIds: $classesIds,
            subjectsIds: $subjectsIds){
                topic,
                id,
                description,
                excerpt,
                fileUrl,
                teacher { id, firstName, lastName  },
                subjects { id, code },
                classes { id, name, code },
                createdAt,
                restricted
        }
    }
  `,
  wipe: gql`mutation deleteNewsQuery($id: Int) {
    deleteNews(id: $id){
        id
    }
  }`
};