import { minValue, isTextEditorNotEmpty } from '../commonValidations';

const validateDuration = (duration) => {
  if (duration === '') {
    return 'this is required';
  }
  if (!Number.isInteger(parseInt(duration, 10))) {
    return 'invalid value'
  }
  if (parseInt(duration, 10) < 5) {
    return 'cannot be less than 5 minutes'
  }
  return null;
}

export default ({ duration, description, name, questions }) => ({
  duration: validateDuration(duration),
  description: isTextEditorNotEmpty(description) ? null : 'content can not be empty',
  name: name.length < 3 ?  minValue(3) : null,
  questions: questions.length < 1 ? 'must have at least one question' : null
});