import React from 'react';
import PropTypes from 'prop-types';

const JitsuLink = ({ children, onClick, className }) => <p onClick={onClick} className={`${className} underline`}>
  {children}
</p>

JitsuLink.defaultProps = ({
  onClick: () => {},
  className: ''
});

JitsuLink.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
};

export default JitsuLink;