import { graphql } from 'react-apollo';
import parentQueries from '../../queries/parent';
import { fetches, fields, getRequestData } from './helper';

export default {
  queries: () => [
    graphql(parentQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables
        })
      })
    })
  ],
  fields,
  fetches,
  getRequestData: (props, data) => getRequestData(
    props,
    {
      ...data,
      studentsIds: data.students.map(({ actualId }) => parseInt(actualId, 10))
    },
    { password: 'password' }
  ),
  initialValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    nationality: 'NG',
    phoneNumber: '',
    address: '',
    regNumber: '',
    students: [],
    dob: '1992-05-11',
  }),
  success: (response, history) => { history.push(`/parents/${response.data.createParent.id}`); }
};
