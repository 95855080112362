import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar';

class Calendar extends Component {
  render() {
    const { events, startAccessor, endAccessor, defaultView, views, ...rest } = this.props;
    return (<BigCalendar
      localizer={BigCalendar.momentLocalizer(moment)}
      events={events.map(event => ({ ...event }))}
      startAccessor={startAccessor}
      endAccessor={endAccessor}
      views={views}
      selectable
      defaultView={defaultView}
      {...rest}
    />);
  }
}

Calendar.defaultProps = {
  events: [],
  startAccessor: 'startDate',
  endAccessor: 'endDate',
  views:['month', 'week', 'day', 'agenda'],
  defaultView: 'month'
};

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  startAccessor: PropTypes.string.isRequired,
  endAccessor: PropTypes.string.isRequired,
  defaultView: PropTypes.string.isRequired,
  views: PropTypes.array.isRequired
};

export default Calendar;