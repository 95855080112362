import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import classQueries from '../../queries/clazz';
import dataFetchingComponent from '../../hocs/dataFetchingComponent';
import NewsRow from '../InfoRows/NewsRow';
import { LINK } from '../../constants/searchResultItemTypes';

const ClassNewsList = ({ data }) => <ul style={{ margin: 0, padding: 0}}>
  {
    data.getClassNewsList.map(item => <NewsRow
      item={item}
      key={item.id} 
      resultItemType={LINK}
      getTo={item => `../news/${item.id}`}/>)
  }
</ul>

ClassNewsList.defaultProps = {
  data: {
    getClassNewsList: []
  }
};

ClassNewsList.propTypes = {
  data: PropTypes.object.isRequired
};

export default graphql(classQueries.classNewsList, {
  options: ({ id }) => ({
    variables: { classId: parseInt(id, 10) }
  }),
  skip: ({ id, onScreen }) => !(id && onScreen)
})(dataFetchingComponent(ClassNewsList));