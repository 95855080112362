import React from 'react';
import PropTypes from 'prop-types';

const JitsuHeader = ({ size, children, className }) => {
  const Tag = `h${size}`;
  return <Tag className={`${className} font-bold`}>
    {children}
  </Tag>
}

JitsuHeader.defaultProps = {
  icon: '',
  children: 'header',
  className: '',
  size: 3
}

JitsuHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired
};

export default JitsuHeader;
