import { graphql } from 'react-apollo';
import { questionQueries } from '../../queries';
import { fields, getRequestData } from './helper';

export default {
  queries: id => [
    graphql(questionQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(questionQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  dataKey: 'getQuestion',
  initialValues: ({ data: { getQuestion } }) => ({
    ...(getQuestion || {}),
    tag: getQuestion.tag
      ? {
        ...getQuestion.tag,
        actualId: getQuestion.tag.id,
        text: getQuestion.tag.name
      }
      : {}
  }),
  success: (response, history) => { history.push(`/questions/${response.data.updateQuestion.id}`); }
};

