import React from 'react';
import PropTypes from 'prop-types';

const typeColorMap = {
  message: 'blue',
  danger: 'red',
  sucess: 'green',
  warning: 'orange',
  info: 'blue'
};

const getClassName = (type, className) => {
  const color = typeColorMap[type] || 'blue';
  return `${className} bg-${color}-100 border-t-4 border-${color}-500 rounded-b text-${color}-900 px-4 py-3 shadow-md`
}

const JitsuAlert = ({ headerText, children, className, type }) => <div className={getClassName(type, className)} role="alert">
  <div className="flex">
    <div className="py-1">
      <svg className={`fill-current h-6 w-6 text-${typeColorMap[type] || 'blue'}-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20`}>
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
      </svg>
    </div>
    <div>
      {headerText && <p className="font-bold text-lg">{headerText}</p>}
      {children && <div className="text-sm">{children}</div>}
    </div>
  </div>
</div>

JitsuAlert.defaultProps = {
  headerText: '',
  type: 'info',
  className: '',
  children: '',
};

JitsuAlert.propTypes = {
  headerText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default JitsuAlert;