import gql from 'graphql-tag';

export default {
  dAssessments: gql`query getPersonalAssessmentsQuery($userId: Int, $userRole: String){
    getPersonalAssessments(userId: $userId, userRole: $userRole){
      id, teacher{ id, firstName, lastName }, topic, deadline, overallMark, fileUrl, subjects { id, code }
    }
  }`,
  item: gql`query getAssessmentQuery($id: Int) {
    getAssessment(id: $id){
      topic, id, description, fileUrl, teacher { id, firstName, lastName }, subjects{ id, code },
      overallMark, deadline, type, createdAt, updatedAt, quiz { id, name }, enforceAttendanceRule, enforceSubmission
    }
  }`,
  submission: gql`query getSubmission($assessmentId: Int, $subjectId: Int, $studentId: Int) {
    getSubmission(studentId: $studentId, assessmentId: $assessmentId, subjectId: $subjectId){
      fileUrl, score
    }
  }`,
  list: gql`query {getAssessments{ topic, id }}`,
  assessmentCompletionPercentage: gql`query {getAssessmentCompletionPercentage{ completed, uncompleted }}`,
  listByCreatedAt: gql`query getAssessmentsByDeadlineQuery($startDate: String, $endDate: String) {
    getAssessmentsByDeadline(startDate: $startDate, endDate: $endDate){
      topic,
      id, description, fileUrl,
      teacher { id, firstName, lastName },
      subjects{ id, code }, overallMark, deadline, completed,
      quiz { name, id }
    }
  }`,
  create: gql`mutation createAssessmentQuery(
    $topic: String!,
    $type: String!,
    $description: String!,
    $deadline: String!,
    $fileUrl: Upload!,
    $teacherId: Int!,
    $quizId: Int,
    $enforceAttendanceRule: Int,
    $enforceSubmission: Int,
    $overallMark: Int!,
    $subjectsIds: [Int]) {
        createAssessment(
            topic: $topic,
            type: $type,
            description: $description,
            fileUrl: $fileUrl,
            deadline: $deadline,
            teacherId: $teacherId,
            quizId: $quizId,
            overallMark: $overallMark,
            enforceAttendanceRule: $enforceAttendanceRule,
            enforceSubmission: $enforceSubmission
            subjectsIds: $subjectsIds){
                id,
                topic,
                description,
                fileUrl,
                type,
                deadline,
                enforceAttendanceRule,
                enforceSubmission,
                overallMark,
                teacher { id, firstName, lastName  },
                quiz { id, name, description  },
                subjects { id, code },
                createdAt, updatedAt
        }
    }
  `,
  update: gql`mutation updateAssessmentQuery(
    $id: Int,
    $topic: String!,
    $type: String!,
    $description: String!,
    $deadline: String!,
    $fileUrl: Upload!,
    $quizId: Int,
    $teacherId: Int!,
    $enforceAttendanceRule: Int,
    $enforceSubmission: Int,
    $overallMark: Int!,
    $subjectsIds: [Int]) {
        updateAssessment(
            id: $id,
            topic: $topic,
            type: $type,
            description: $description,
            enforceAttendanceRule: $enforceAttendanceRule,
            enforceSubmission: $enforceSubmission,
            fileUrl: $fileUrl,
            quizId: $quizId,
            deadline: $deadline,
            teacherId: $teacherId,
            overallMark: $overallMark,
            subjectsIds: $subjectsIds){
                topic,
                id,
                description,
                fileUrl,
                deadline,
                enforceAttendanceRule,
                enforceSubmission,
                type,
                overallMark,
                teacher { id, firstName, lastName },
                quiz { id, name, description },
                subjects { id, code },
                createdAt, updatedAt
        }
    }
  `,
  wipe: gql`mutation deleteAssessmentQuery($id: Int) {
    deleteAssessment(id: $id){
        id
    }
  }`,
  getAssessmentScores: gql`query getAssessmentScoresQuery($subjectId: Int, $assessmentId: Int) {
    getAssessmentScores(subjectId: $subjectId, assessmentId: $assessmentId){
        student { id }, score, fileUrl
    }
  }`,
  submitAssessmentScores: gql`mutation submitAssessmentScoresQuery($scores: [AssessmentScoreInput]) {
    submitAssessmentScores(scores: $scores){
        student { id }, score, fileUrl
    }
  }`,
  submitSubjectWorkPercentages: gql`mutation submitSubjectWorkPercentagesQueries($portions: [PortionInput]){
    submitSubjectWorkPercentages(portions: $portions) {
      id, type, percentage
    }
  }`,
  studentSubmitAssessment: gql`mutation submitAssessmentQuery(
    $subjectId: Int!,
    $studentId: Int!,
    $assessmentId: Int!,
    $fileUrl: Upload!){
      submitAssessment(
        subjectId: $subjectId,
        studentId: $studentId,
        assessmentId: $assessmentId,
        fileUrl: $fileUrl){
            text, status
        }
  }
`
};