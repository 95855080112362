import gql from 'graphql-tag';

export default {
  results: gql`query getTeacherResultQuery($teacherId: Int) {
    getTeacherResult(teacherId: $teacherId) {
      subjectId,
      code,
      highestScore,
      lowestScore,
      averageScore
    }
  }`,
  item: gql`query getTeacherQuery($id: Int) {
    getTeacher(id: $id){
      lastName,
      id,
      qualification,
      firstName, dob,
      gender, address,
      phoneNumber,
      regNumber,
      email,
      nationality,
      regNumber,
      classes{ id, name }
      subjects{ id, code },
      previousWorkPlace,
      role,
      status
    }
  }`,
  changePassword: gql`mutation updateTeacherPasswordQuery($regNumber: String, $password: String, $newPassword: String) {
    updateTeacherPassword(regNumber: $regNumber, password: $password, newPassword: $newPassword) {
      status
    }
  }`,
  list: gql`query {getTeachers{firstName, lastName, id}}`,
  create: gql`mutation createTeacherQuery(
    $firstName: String!,
    $lastName: String!,
    $qualification: String!,
    $email: String!,
    $phoneNumber: String!,
    $password: String!,
    $regNumber: String!,
    $dob: String!,
    $gender: String!,
    $address: String!,
    $nationality: String!,
    $previousWorkPlace: String!,
    $photoUrl: String!,
    $role: String!,
    $subjectsIds: [Int]) {
        createTeacher(
            lastName: $lastName,
            firstName: $firstName,
            qualification: $qualification,
            email: $email,
            phoneNumber: $phoneNumber,
            dob: $dob,
            gender: $gender,
            address: $address,
            nationality: $nationality,
            previousWorkPlace: $previousWorkPlace
            role: $role,
            photoUrl: $photoUrl,
            password: $password,
            regNumber: $regNumber,
            subjectsIds: $subjectsIds){
                lastName,
                id,
                qualification,
                firstName,
                dob, gender,
                address,
                previousWorkPlace,
                phoneNumber,
                email,
                nationality,
                subjects { id, code },
                role, status
        }
    }
  `,
  update: gql`mutation updateTeacherQuery(
    $id: Int,
    $firstName: String!,
    $lastName: String!,
    $qualification: String!,
    $email: String!,
    $role: String!,
    $phoneNumber: String!,
    $regNumber: String!,
    $dob: String!,
    $gender: String!,
    $previousWorkPlace: String!,
    $address: String!,
    $nationality: String!,
    $subjectsIds: [Int]) {
        updateTeacher(
            id: $id,
            lastName: $lastName,
            firstName: $firstName,
            qualification: $qualification,
            email: $email,
            role: $role,
            regNumber: $regNumber,
            phoneNumber: $phoneNumber,
            previousWorkPlace: $previousWorkPlace
            dob: $dob,
            gender: $gender,
            address: $address,
            nationality: $nationality,
            subjectsIds: $subjectsIds){
                lastName,
                id,
                qualification,
                firstName,
                regNumber,
                dob, gender,
                role,
                address,
                phoneNumber,
                previousWorkPlace,
                email,
                nationality,
                subjects { id, code }, status
        }
    }
  `,
  wipe: gql`mutation deleteTeacherQuery($id: Int) {
    deleteTeacher(id: $id){
        id
    }
  }`,
  teacherSubjects: gql`query getTeacherSubjectsQuery($userId: Int){
    getTeacherSubjects(userId: $userId){
        code, id, isMandatory, class { id, name, code }, subject{ id, name, code }
    }
  }`,
  ledSubjects: gql`query getSubjectsLedByTeacherQuery($teacherId: Int){
    getSubjectsLedByTeacher(teacherId: $teacherId){
        code, id
    }
  }`,
  teacherSubjectResults: gql`query getTeacherSubjectResultsQuery($teacherId: Int, $subjectId: Int) {
    getTeacherSubjectResults(teacherId: $teacherId, subjectId: $subjectId){
        name,
        studentId,
        regNumber,
        email,
        phoneNumber,
        results{
            code,
            subjectId,
            highestScore,
            lowestScore,
            creditUnit,
            assessments{
                score, topic, overallMark, percentage
            }
        }
    }
  }`
};