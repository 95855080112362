import { graphql } from 'react-apollo';
import { collegeQueries } from '../../queries';
import { fields, getRequestData, updateCollegeListAfterCreate } from './helper';

export default {
  queries: () => [
    graphql(collegeQueries.create, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
          update: (proxy, { data: { createCollege } }) => {
            updateCollegeListAfterCreate(proxy, createCollege);
          }
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  initialValues: () => ({ name: '', code: '', points: 1, topMargin: 100, bottomMargin: 0 }),
  success: (response, history) => { history.push(`/colleges/${response.data.createCollege.id}`); }
};

