import styled from 'styled-components';

export const SubjectsSearchAndAddDiv = styled.div`
  padding: 30px 0;
  input[name=searchValue]{
    min-width: 300px;
  }
  .addSubjectBtnWrapper{
    padding-top: 20px;
    width: auto;;
    button{
      height: 33px;
    }
  }
`;