import styled from 'styled-components';
import { LAPTOP_SCREEN } from '../../constants/screenSizes';

export const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (${LAPTOP_SCREEN}) {
    grid-template-columns: 1fr;
  }
  &.news-details, &.assessments-details, &.notes-details, &.questions-details, &.quizzes-details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .topic, .description, .subjects, .datePosted, .postedBy, .createdAt{
      label{ display: none; }
    }
    .subjects .empty{ display: none }
    .topic{ font-size: 24px; }
    .description, .content{
      background: #edf3f8;
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .subjects{
      ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        li{
          background-color: #edf3f8;
          padding: 4px 6px;
          margin: 0 8px 0 0;
        }
      }
    }
    .datePosted, .postedBy, .createdAt{
      text-align: right;
      padding-top: 8px;
    }
    .overallMark, .type, .deadline, .updatedAt, .enforceAttendanceRule{
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .form-buttons, .answers{
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

