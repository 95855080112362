import gql from 'graphql-tag';

const body = `
  id,
  type,
  content,
  name,
  teacher{ id, firstName, lastName },
  answers{
    id, correct, content
  }
  tag{ name, id }
`;

const declaration = `
  $name: String,
  $type: String,
  $content: String,
  $teacherId: Int,
  $answers: [AnswerInput],
  $tag: TagInput
`;

const initilisation = `
name: $name,
type: $type,
content: $content,
teacherId: $teacherId,
answers: $answers,
tag: $tag
`;

export default {
  list: gql`query {
    getQuestions{
      ${body}
    }
  }`,
  item: gql`query getQuestionQuery($id: Int) {
    getQuestion(id: $id){
      ${body}
    }
  }`,
  update: gql`mutation updateQuestionQuery(
    $id: Int,
    ${declaration}) {
        updateQuestion(
            id: $id,
            ${initilisation}){
                ${body}
        }
    }
  `,
  create: gql`mutation createQuestionQuery(
    ${declaration}) {
        createQuestion(
            ${initilisation}){
                ${body}
        }
    }
  `,
  wipe: gql`mutation deleteQuestionQuery($id: Int) {
    deleteQuestion(id: $id){
        id
    }
  }`
}
