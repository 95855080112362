import { collegeQueries } from '../../queries';
import { TEXT } from '../../constants/displayTypes';
import { textChange } from '../../functions/textChange';

export default {
  dataKey: 'getCollege',
  query: collegeQueries.item,
  options: id => ({ skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
  display: [
    { type: TEXT, label: 'Name', text: data => data.name, id: 'name' },
    {
      type: TEXT,
      label: `${textChange('Teacher')}`,
      text: ({ teacher }) => `${teacher ? `${teacher.firstName} ${teacher.lastName}` : 'No one'}`,
      id: 'teacher'
    },
  ]
};
