import { graphql } from 'react-apollo';
import { quizQueries } from '../../queries';
import { fields, getRequestData } from './helper';

export default {
  queries: id => [
    graphql(quizQueries.item, { skip: () => !id, options: () => ({ variables: { id: parseInt(id, 10) } }) }),
    graphql(quizQueries.update, {
      props: ({ mutate }) => ({
        sendRequest: variables => mutate({
          variables,
        })
      })
    })
  ],
  fields,
  fetches: [],
  getRequestData,
  dataKey: 'getQuiz',
  initialValues: ({ data: { getQuiz } }) => ({
    ...getQuiz,
    questions: getQuiz.questions.map(question => ({ ...question, actualId: question.id, text: question.name }))
  }),
  success: (response, history) => { history.push(`/quizzes/${response.data.updateQuiz.id}`); }
};

