import { minValue } from '../commonValidations';

export default ({ firstName, phoneNumber, address, email, regNumber, lastName, gender }) => ({
  firstName: firstName.length < 3 ? minValue(3) : null,
  lastName: lastName.length < 3 ? minValue(3) : null,
  email: email.length < 3 ? minValue(3) : null,
  address: address.length < 20 ? minValue(20) : null,
  phoneNumber: phoneNumber.length < 10 ? minValue(10) : null,
  regNumber: regNumber.length < 3 ? minValue(3) : null,
  gender: gender === '' ? 'please select gender' : null
});