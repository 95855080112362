import React from 'react';
import PropTypes from 'prop-types';

const style = {
  color: 'rgb(167, 164, 164)',
  fontSize: '12px',
  fontStyle: 'italic'
};


const Hint = ({ Tag, text, children, classname }) => <Tag style={style} className={classname}>
  {text}
  {children}
</Tag>

Hint.defaultProps = {
  Tag: 'div',
  text: '',
  classname: ''
};

Hint.propTypes = {
  Tag: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.any,
  classname: PropTypes.string.isRequired
};

export default Hint;
