import React, { PureComponent, Fragment } from 'react';
import { graphql } from 'react-apollo';
import flowRight from "lodash.flowright";
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal } from '../components-elements';
import { otherQueries, requestQueries } from '../queries'
import * as controllers from '../controllers';
import { dataFetchingComponent, withNotification, withUser } from '.';
import  { permissions, permissionLevels } from '../constants/permissions';
import { SUCCESS, CRITICAL } from '../constants/notificationTypes';
import { handleError, getCurrentUser } from '../functions';
import { MdDeleteForever } from 'react-icons/md';
import { LIGHT_GRAY } from '../constants/colors';
import { JitsuButton, JitsuCheckbox } from '../components-elements/jistu';

const DeleteDiv = styled.div`
  padding: 30px 10px;
  .header{
    font-size: 16px;
    display: flex;
    .header-icon{
      font-size: 24px;
      padding: 6px 6px 0;
      background-color: #ff00007a;
      border-radius: 21px;
    }
    .header-text{
      padding-top: 8px;
      margin-left: 16px;
    }
  }
  .warningText{
    font-size: 14px;
    padding: 20px 10px;
    color: ${LIGHT_GRAY};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  >div{
    width: auto;
    margin: 0 10px;
  }
`

const createDelete = (page, id, history) => {
  if (!permissions[page][getCurrentUser().userRole].includes(permissionLevels.DELETE)) return () => <div/>
  const { queries, success, dataKey, requestName, checkbox = {}, cantDelete } = controllers[page].del;
  const cancel = () => history.push(`/${page}/${id}`);
  class Delete extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isRequesting: false,
        [checkbox.name || 'checked']: checkbox.initialValue || 0,
      };
      this.sendRequest = this.sendRequest.bind(this);
      this.onChange = this.onChange.bind(this);
    }
    async sendRequest() {
      this.setState(() => ({ isRequesting: true }));
      const { sendRequest, addNotifications, requestToDelete, user, data } = this.props;
      try {
        let response = '';
        if (requestName) {
          response = await requestToDelete({ requestedBy: user.userId });
          if (user.settings[requestName]) {
            addNotifications({ text: 'Successfully requested, wait for an answer', type: SUCCESS });
            this.setState(() => ({ isRequesting: false }));
            success(response, history, id);
          } else {
            addNotifications({ text: 'Successfully deleted', type: SUCCESS });
            this.setState(() => ({ isRequesting: false }));
            success(response, history, '');
          }
        } else {
          response = await sendRequest({ id: parseInt(id, 10), [checkbox.name]: this.state[checkbox.name], fullItemData: data[dataKey] });
          addNotifications({ text: 'Successfully deleted', type: SUCCESS });
          this.setState(() => ({ isRequesting: false }));
          success(response, history, '');
        }
      } catch (e) {
        this.setState(() => ({ isRequesting: false }));
        handleError(e, () => addNotifications({ text: 'Error occurred trying to delete', type: CRITICAL }));
      }
    }
    onChange(value, name) {
      this.setState({ [name]: value });
    }
    render() {
      const { isRequesting } = this.state;
      const { data, existingRequests, user, user: { settings } } = this.props;
      if (cantDelete && cantDelete(data[dataKey], user)) return <div/>;

      return data && data[dataKey] ? <Modal closeModal={cancel} size="small">
        <DeleteDiv id="deleteDiv">
          <div className="header">
            <div className="header-icon"><MdDeleteForever/></div>
            <div className="header-text">
              {requestName && settings[requestName] ? 'Request to delete this item?' : 'Delete this item?' }
            </div>
          </div>
          {requestName && settings[requestName]
            ? <div className="warningText">
              This request will be made, if approved, this is not reversible. Do you want to continue?
            </div>
            : <div className="warningText">
              This would delete the selected item, this is not reversible. Do you want to continue?
            </div>
          }
          {checkbox.name && <JitsuCheckbox {...checkbox} onChange={this.onChange} value={this.state[checkbox.name]}/>}
          <ButtonWrapper>
            <JitsuButton onClick={cancel} name="cancel" value="Cancel"/>
            <Fragment>
              {
                requestName
                  ? existingRequests.getRequests.length === 0
                    ? <JitsuButton
                    value={settings[requestName] ? 'Request' : 'Delete'}
                    onClick={this.sendRequest}
                    name="yes"
                    loading={isRequesting}
                    className="danger"/>
                    : <div>A similar request has already been made, wait for an answer.</div>
                  : <JitsuButton
                    value="Delete Anyways"
                    className="danger"
                    onClick={this.sendRequest}
                    name="yes"
                    loading={isRequesting}
                  />
              }
            </Fragment>
          </ButtonWrapper>
        </DeleteDiv>
      </Modal> : <Fragment/>;
    }
  }
  Delete.propTypes = {
    sendRequest: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    addNotifications: PropTypes.func.isRequired,
    data: PropTypes.object
  };
  return withUser(flowRight(
    ...queries(id),
    graphql(requestQueries.create, {
      props: ({ mutate }) => ({
        requestToDelete: variables => mutate({
          refetchQueries: [{ query: otherQueries.menuData }],
          variables: {
            ...variables,
            tablename: page,
            relatedRowId: parseInt(id, 10),
            action: 'DELETE',
            vals: `${id}`,
            reason: ''
          }
        })
      })
    }),
    graphql(requestQueries.listRequestsByRelatedRowIdAndActionType, {
      options: () => ({
        variables: {
          relatedRowId: parseInt(id, 10),
          action: 'DELETE',
          tablename: page,
          vals: `${id}`,
          status: 'PENDING'
        },
      }),
      name: 'existingRequests'
    })
  )(dataFetchingComponent(withNotification(Delete), ['existingRequests'])));
};

export default createDelete;